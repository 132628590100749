import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Typography, Col, Button, Table, Space, Dropdown, Spin, Select } from "antd";
import { DeleteOutlined, LoadingOutlined, PlusOutlined, UndoOutlined } from "@ant-design/icons";
import type { TableProps } from "antd";
import type { MenuProps } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import AddCategory from "./AddCategory";
import {
  useQuery,
} from "@tanstack/react-query";
import HTTPSCalls from "../../../../Services/HTTPCalls";
import { ActiveInactiveOptions, MasterTypes, RecordStatusType } from "../../../../Constant/Enums";
import { useUpdateMasterRecordStatus } from "../../../../Utilities/tanStackQueries";

//#region And table
interface DataType {
  key: string;
  name: string;
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: <Typography.Link>Delete</Typography.Link>,
  },
];

//#endregion

interface I_Props {
  isAssessModalOpen: boolean;
  setAssessIsModalOpen: Function;
}

const DisplayCategory: React.FC<I_Props> = (props) => {
  const [categoryId, setCategoryId] = useState<any>();
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });

  const [start, setStart] = useState<any>(0);
  const [length, setLength] = useState<any>(15);
  const [selectAction,setSelectAction]=useState<String>("all");
  const updateStatusMution = useUpdateMasterRecordStatus();
  const {
    data: AssessmentCategory,
    isLoading: categoryLoading,
    refetch,
  } = useQuery({
    queryKey: ["Category", start, length,selectAction],
    queryFn: async () => {
      try {
        const res = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?",
          {
            masterType: MasterTypes.AssessmentCategory,
            start: start,
            length: length,
            status:selectAction
          }
        );
        if (/*res?.errors === null &&*/ res?.result?.items?.length > 0) {
          setPagingItems((p) => {
            p.totalRecords = res?.result?.totalRecords;
            return p;
          });
          console.log(res?.result?.items, "assessmentCategory");
          return res?.result?.items.map((item: any) => ({
            ...item,
            key: item.id,
          })); // Add key prop to each item
        } else {
          console.log("error");
          return []; // return an empty array when API call fails or no data
        }
      } catch (error) {
        console.error(error);
        return []; // return an empty array when API call fails
      }
    },
  });

  useEffect(() => {
    setStart((pagingItems?.currentPage - 1) * pagingItems?.pageSize);
    setLength(pagingItems?.pageSize);
  }, [pagingItems]);

  const columns: TableProps<DataType>["columns"] = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      width: "70%",
      render: (text:any, record:any) => (
        <Typography.Link
          color="#0D61FF"
          onClick={() => {
            props.setAssessIsModalOpen(!props.isAssessModalOpen)
            setCategoryId(record?.id)
          }}
        >
          {text}
        </Typography.Link>
      ),
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      render: (_, record:any) => (
        <Row justify="end">
        {record.recordStatus === RecordStatusType.Deleted ? (
          <UndoOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd", cursor: "pointer" }}
            onClick={() =>{
              updateStatusMution.mutate({id:record?.id, restore:"restore"},{
                onSuccess:()=>refetch()
              })}
              
            } 
          />
        ) : ( 
          <DeleteOutlined
            className="ca-edit-btn me-2"
            style={{ color: "#a5abdd", cursor: "pointer" }}
            onClick={() => updateStatusMution.mutate({id:record?.id, restore:"delete"},{
              onSuccess:()=>refetch()
            })}
          />
        )} 
      </Row>
      ),
    },
  ];

  return (
    <>
      <div>
        <Row>
          <Col span={19}>
            <Typography.Title level={5} style={{ fontWeight: 500 }}>
              Assessment Categories
            </Typography.Title>
          </Col>
          <Col span={3}>
          <Select
                style={{width:"100%"}}
                onSelect={(val) => {
                  setSelectAction(val)
                }}
                placeholder="Select Action"
                allowClear
                defaultValue={"all"}
                options={ActiveInactiveOptions}
              ></Select>
              </Col>
              <Col span={2}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{marginLeft:"20px", fontWeight: 500 }}
              onClick={() => {
                props.setAssessIsModalOpen(!props.isAssessModalOpen);
                setCategoryId(null);
              }}
            >
              Add
            </Button>
              </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={AssessmentCategory}
          style={{ marginTop: 14  }}
          loading={{
            spinning: categoryLoading||updateStatusMution.isPending,
            indicator: <Spin indicator={<LoadingOutlined />} />,
          }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            selectPrefixCls: "custom-table-select",
            current: pagingItems.currentPage,
            pageSize: pagingItems.pageSize,
            showSizeChanger: true,
            total: pagingItems.totalRecords,
            pageSizeOptions: ["15", "25", "50", "100"],
          }}
          onChange={(paging, filter, sort: any) => {
            paging &&
              setPagingItems({
                ...pagingItems,
                currentPage: paging?.current ?? 1,
                pageSize: paging?.pageSize ?? 15,
              });
          }}
        />
      </div>
      <AddCategory
        setAssessIsModalOpen={props.setAssessIsModalOpen}
        isAssessModalOpen={props.isAssessModalOpen}
        categoryId={categoryId}
        refetch={refetch}
        setCategoryId={setCategoryId}
      />
    </>
  );
};

export default DisplayCategory;

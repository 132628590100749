import { BellOutlined, PaperClipOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, message, Row, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { MasterTypes } from "../../../Constant/Enums";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { NoticeMaster } from "../../../ApiUrls/URLS";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
interface IDrawerPorps {
  open: boolean;
  setOpen: any;
  setTrigger: any;
  recordData: any;
  trigger: any;
}
const AddEditNotice = ({
  open,
  setOpen,
  setTrigger,
  trigger,
  recordData,
}: IDrawerPorps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const onsubmit = async () => {
    form.validateFields().then(async (values: any) => {
      setLoading(true);
      let noticeDetial = {
        masterType: MasterTypes.Notice,
        id: recordData?.id,
        name: values?.title,
        description: values?.description,
      };
      HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_MASTER,
        {},
        { ...noticeDetial }
      ).then((res: IApiResponse) => {
        if (res?.status) {
          // console.log(res,"postres=")
          setOpen(false);
          form.resetFields();
          setOpen(false);
          message.success(
            recordData?.id
              ? "Notice updated successfully"
              : "Notice added successfully"
          );
          setTrigger((prev: boolean) => !prev);
        } else {
          message.warning(res?.message);
        }
        setLoading(false);
      });
    });
  };
  useEffect(() => {
    if (open) {
      if (recordData?.id) {
        form.setFieldsValue({
          title: recordData?.name,
          description: recordData?.description,
        });
      }else if(open){
        form.resetFields()
      }
    }
  }, [open,recordData,form]);
  return (
    <>
      <Drawer
        open={open}
        onClose={() => !open}
        title={
          <>
            {recordData?.id ? "Edit Notice" : "Add Notice"} <BellOutlined />{" "}
          </>
        }
        footer={
          <Row gutter={12} justify={"end"}>
            <Col>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => onsubmit()}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form form={form} layout="vertical">
          <Row className="p-4">
            <Col span={24}>
              <Form.Item
                name={"title"}
                label="Title"
                rules={[{ required: true, message: "Please input title" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"description"}
                label="Description"
                rules={[
                  { required: true, message: "Please input description" },
                ]}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AddEditNotice;

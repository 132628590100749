import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import "./EnquiryLead.css";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeFilled,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  AttachmentsCategory,
  CandidateRequestType,
  MasterTypes,
  ProcessStepsEnum,
} from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";
import Upload, { RcFile } from "antd/es/upload";
import { EnquiryEndPoint } from "../../ApiUrls/URLS";
import { ApiCalls } from "../../ApisCaller/ApiCall";
import { adminContext } from "../../Common/PageRoute";
import { HttpMethods } from "../../Utilities/ApiUtility.axios";
import { IEnquiryModelType } from "../../Enquiry/EnquiryLead";
import PreviewForm from "../../Enquiry/PreviewForm";

interface EnquiryLead {
  outerStep: ProcessStepsEnum;
  open: boolean;
  id: string;
  type: IEnquiryModelType;
  onAdd: (
    rec: boolean,
    isAcknowledgement: boolean,
    isVerifyDocument: boolean
  ) => void;
  onCancel: React.Dispatch<{}>;
  apiData: any;
}
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");

const AcknowledgementAndVerify = ({
  open,
  type,
  onCancel,
  apiData,
  id,
  onAdd,
  outerStep,
}: EnquiryLead) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [masterLoading, setMasterLoading] = React.useState<boolean>(false);
  const [preview, setPreview] = React.useState<string>("");
  const [index, setIndex] = React.useState<number>(-1);
  const [masterData, setMasterData] = React.useState<{
    document: any;
  }>({
    document: [],
  });
  const [previewData, setPreviewData] = React.useState<any>();
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: "",
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: "",
    },
  ]);

  const getMaster = async () => {
    setMasterLoading(true);
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });
      setMasterLoading(false);
      let obj: {
        document: any;
      } = {
        document: transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Documents
        )?.[0]?.items,
      };
      setMasterData({ ...obj });
    } else {
      message.error(res?.message);
    }
  };

  //console.log("props", props);

  React.useEffect(() => {
    getMaster();
  }, []);

  // useEffect(()=>{

  // },[refresh])
  const onSubmit = () => {
    form
      .validateFields()
      .then(async (val: any) => {
      
        let formVal =
          val?.attachments?.length > 0
            ? val?.attachments?.map((item: any, index: number) => {
                return {
                  ...item,
                  fileType: {
                    id: item?.fileType?.value,
                    name: item?.fileType?.label,
                  },
                  fileName: {
                    id: item?.fileName?.id ? item?.fileName?.id : "",
                    name: item?.fileName?.file?.name
                      ? item?.fileName?.file?.name
                      : item?.fileName?.name
                      ? item?.fileName?.name
                      : "",
                    contentType: item?.fileName?.file?.type
                      ? item?.fileName?.file?.type
                      : item?.fileName?.contentType
                      ? item?.fileName?.contentType
                      : "",
                    length: item?.fileName?.file?.size
                      ? item?.fileName?.file?.size
                      : item?.fileName?.length,
                    path: attachmentList[index]?.fileName?.path
                      ? attachmentList[index]?.fileName?.path
                      : item?.fileName?.path,
                  },
                  description: item?.description,
                  category: AttachmentsCategory.StudentDocuments,
                };
              })
            : [];
        setLoading(true);
        setModelLoading(true);
        let response = await HTTPSCalls.POST(
          HTTPSCalls.ENDPOINTS.ENQUIRY_UPDATE_LIST + "?",
          {
            id: apiData?.id,
            fieldName: "Attachments",
            valueType: "",
          },
          formVal
        );
        setLoading(false);
        if (response?.status) {
          message.success("Attachment Submitted");
          onAdd(
            true,
            type === IEnquiryModelType.acknowledgement,
            type === IEnquiryModelType.verifyDocument
          );
        } else {
          message.error(response?.message);
        }
      })
      .catch((error: any) => {
        // console.log(error, "error");
      });
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, index: number) => {
    let prevAtt = [...attachmentList];

    let currentFileBase64 = await getBase64(file as RcFile);

    let fileInfo = {
      id: "",
      name: file?.name,
      contentType: file?.type,
      length: file?.size,
      path: currentFileBase64,
    };

    prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

    setAttachmentList(prevAtt);
  };

  const getDocument =async ()=>{
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE+"?",{
      masterType:MasterTypes.Documents
    })
    return res?.result?.items;
  }

  React.useEffect(() => {
    form.resetFields();
    const getDocumentResult = async () => {
      const result:[] = await getDocument();
      if (apiData) {
        let documentApi = result?.flatMap((val:any)=>{
          return apiData?.attachments?.filter((item:any)=>
            val?.id === item?.fileType?.id
        )
        })

        form.setFieldValue(
          "attachments",
          documentApi?.map((itm: any, indx: any) => {
            return {
              key: indx,
              ...itm,
              fileType: {
                label: itm?.fileType?.name,
                value: itm?.fileType?.id,
              },
              fileName: {
                id: itm?.fileName?.id,
                name: itm?.fileName?.name,
                contentType: itm?.fileName?.contentType,
                length: itm?.fileName?.length,
                path: itm?.fileName?.path,
              },
              description: itm?.description,
            };
          })
          // apiData?.attachments?.map((itm: any, indx: any) => {
          //   return {
          //     key: indx,
          //     ...itm,
          //     fileType: {
          //       label: itm?.fileType?.name,
          //       value: itm?.fileType?.id,
          //     },
          //     fileName: {
          //       id: itm?.fileName?.id,
          //       name: itm?.fileName?.name,
          //       contentType: itm?.fileName?.contentType,
          //       length: itm?.fileName?.length,
          //       path: itm?.fileName?.path,
          //     },
          //     description: itm?.description,
          //   };
          // })
        );
        setModelLoading(false);
      }
      console.log("apiData",apiData);
    };
    getDocumentResult()
    
  }, [apiData]);

  return (
    <>
      <Modal
        title={
          type === IEnquiryModelType.verifyDocument
            ? "Verify Document"
            : "Add Acknowledgement"
        }
        open={open}
        maskClosable={false}
        onCancel={onCancel}
        width={850}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button onClick={onCancel}>Cancel</Button>
            <Button onClick={onSubmit} type="primary" loading={loading}>
              Save
            </Button>
          </Space>
        }
      >
        <Divider className="marginDivider" />

        <Spin
          spinning={modelLoading || masterLoading}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <div style={{ maxHeight: "68vh", overflowY: "auto", padding: "7px" }}>
            <Form form={form} requiredMark={false}>
              <Form.Item>
                <Form.List
                  name={"attachments"}
                  //   initialValue={[{}]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(
                        ({ key, name, ...restField }, index, attachments) => {
                          return (
                            <div key={index}>
                              <Row gutter={[5, 4]} key={index}>
                                <Col lg={8} xl={8}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "description"]}
                                    label={index === 0 ? "" : ""}
                                  >
                                    <Input.TextArea
                                      placeholder="Description"
                                      style={{ width: "100%" }}
                                      autoSize={{
                                        minRows: 1,
                                        maxRows: 6,
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={5} xl={5}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "fileType"]}
                                    label={index === 0 ? "" : ""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Select Doc Type",
                                      },
                                    ]}
                                  >
                                    <Select
                                      // options={document}
                                      placeholder="Select Doc Type"
                                      options={masterData?.document}
                                      labelInValue
                                      allowClear
                                      popupMatchSelectWidth={true}
                                      filterOption={(input, option) =>
                                        (option?.label?.toString() ?? "")
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      // dropdownRender={(menu) => (
                                      //   <>
                                      //     {menu}
                                      //     <AddInOption
                                      //       funcType={"document"}
                                      //       setRefresh={setRefresh}
                                      //     />
                                      //   </>
                                      // )}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col lg={4} xl={4}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "fileName"]}
                                    label={index === 0 ? "" : ""}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Choose Doc",
                                      },
                                    ]}
                                  >
                                    <Upload
                                      // defaultFileList={[]}
                                      beforeUpload={(file: any) => {
                                        handelFile(file, index);
                                        return false;
                                      }}
                                      maxCount={1}

                                      //   beforeUpload={(
                                      //     file: any
                                      //   ) => {
                                      //     return true;
                                      //   }}
                                    >
                                      <Tooltip
                                        title={
                                          form.getFieldsValue(["attachments"])
                                            ?.attachments[name]?.fileName?.name
                                        }
                                      >
                                        <Button icon={<UploadOutlined />}>
                                          {form.getFieldsValue(["attachments"])
                                            ?.attachments[name]?.fileName?.id
                                            ? form.getFieldsValue([
                                                "attachments",
                                              ])?.attachments[name]?.fileName
                                                ?.name?.length > 10
                                              ? form
                                                  .getFieldsValue([
                                                    "attachments",
                                                  ])
                                                  ?.attachments[
                                                    name
                                                  ]?.fileName?.name?.substr(
                                                    0,
                                                    5
                                                  ) + "..."
                                              : form.getFieldsValue([
                                                  "attachments",
                                                ])?.attachments[name]?.fileName
                                                  ?.name
                                            : "Upload"}
                                        </Button>
                                      </Tooltip>
                                    </Upload>
                                  </Form.Item>
                                </Col>
                                {type === IEnquiryModelType.verifyDocument && (
                                  <>
                                    <Col lg={1} xl={1}>
                                      <Form.Item
                                        valuePropName="checked"
                                        name={[name, "documentIsVerified"]}
                                      >
                                        {form.getFieldValue([
                                          "attachments",
                                          name,
                                          "documentIsVerified",
                                        ]) ? (
                                          <CheckOutlined />
                                        ) : (
                                          <CloseOutlined />
                                        )}
                                      </Form.Item>
                                    </Col>
                                  </>
                                )}
                                {/* {index !== 0 ? ( */}
                                <Col lg={1} xl={1}>
                                  <Button
                                    style={
                                      index === 0
                                        ? {
                                            borderRadius: "5px",
                                            // marginTop: "25px",
                                          }
                                        : {
                                            borderRadius: "5px",
                                            // marginTop: "2px",
                                          }
                                    }
                                    onClick={() => {
                                      remove(name);
                                    }}
                                    icon={<DeleteOutlined />}
                                  />
                                </Col>
                                {form.getFieldsValue(["attachments"])
                                  ?.attachments[name]?.fileName?.id ? (
                                  <Col lg={3} xl={3}>
                                    <Form.Item label="">
                                      <Button
                                        type="link"
                                        onClick={() => {
                                          if (
                                            type ===
                                            IEnquiryModelType.acknowledgement
                                          ) {
                                            window.open(
                                              `${
                                                form.getFieldsValue([
                                                  "attachments",
                                                ])?.attachments[name]?.fileName
                                                  ?.path
                                              }`,
                                              "_blank"
                                            );
                                          } else {
                                            setIndex(name);
                                            setPreviewData(
                                              form.getFieldsValue([
                                                "attachments",
                                              ])?.attachments[name]?.fileName
                                                ?.path
                                            );
                                            setPreview("setPreview");
                                          }
                                        }}
                                        icon={<EyeFilled />}
                                      >
                                        {type ===
                                        IEnquiryModelType.acknowledgement
                                          ? "Preview"
                                          : "Verify Document"}
                                      </Button>
                                    </Form.Item>
                                  </Col>
                                ) : null}

                                {/* ): null} */}
                              </Row>
                            </div>
                          );
                        }
                      )}
                      <Form.Item className="m-0 pb-4">
                        <Button
                          type="primary"
                          ghost
                          icon={<PlusOutlined />}
                          onClick={() => add()}
                        >
                          Attachment
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Form>
          </div>
        </Spin>
      </Modal>
      {preview === "setPreview" && (
        <PreviewForm
          isVerify={form.getFieldValue([
            "attachments",
            index,
            "documentIsVerified",
          ])}
          onDismiss={() => setPreview("")}
          previewData={previewData}
          onVerify={(isVerify: boolean) => {
            if (isVerify === true) {
              form.setFieldValue(
                ["attachments", index, "documentIsVerified"],
                true
              );
            } else if (isVerify === false) {
              form.setFieldValue(
                ["attachments", index, "documentIsVerified"],
                false
              );
            }
            console.log("isVerify = ", isVerify);
          }}
        />
      )}
    </>
  );
};

export default AcknowledgementAndVerify;

import { Card, Col, Empty, Image, Row, Space, Tooltip, Typography } from "antd";
import dayjs from "dayjs";
import {
  CenterVisited,
  IndianState,
  languageProficiency,
  outcomeType,
  StudyModeObj,
} from "../../Constant/Enums";
import Meta from "antd/es/card/Meta";
import { R } from "@tanstack/react-query-devtools/build/legacy/devtools-PtxSnd7z";
import {
  EnvironmentTwoTone,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
const { Text, Link } = Typography;
interface IViewStudentDetails {
  apiData: any;
}

const ViewStudentDetails = (props: IViewStudentDetails) => {
  const { apiData } = props;
  console.log(apiData, "78");
  const currentDate = dayjs();
  const enrolledDate = dayjs(apiData?.enrolledDate);

  const yearsDiff = currentDate.diff(enrolledDate, "year");
  const monthsDiff = currentDate.diff(
    enrolledDate.add(yearsDiff, "year"),
    "month"
  );
  const daysDiff = currentDate.diff(
    enrolledDate.add(yearsDiff, "year").add(monthsDiff, "month"),
    "day"
  );

  return (
    <>
      <div style={{ maxHeight: `calc(100vh - 220px)`, overflowY: "auto" }}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card
              bordered
              size="small"
              style={{ height: "100vh", overflowY: "auto" }}
              title={
                <>
                  <Meta
                    style={{ fontSize: 12 }}
                    title={
                      <Space
                        size={[8, 16]}
                        direction="vertical"
                        style={{
                          height: 300,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Row justify="center">
                          <Image
                            style={{
                              width: "125px",
                              height: "125px",
                              borderRadius: "50%",
                              border: "4px solid rgba(9, 88, 217, 0.57)",
                            }}
                            src={apiData.candidateImage.path}
                          />
                        </Row>
                        <Row justify="center">
                          <span
                            style={{
                              color: "#377dff",
                              textAlign: "center",
                              fontSize: 15,
                              marginTop: 10,
                            }}
                          >                    
                            {apiData.name} {apiData.middleName}{" "}
                            {apiData.lastName}
                          </span>
                        </Row>
                        <Row justify="center">
                          <span
                            style={{
                              color: "gray",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 15,
                            }}
                          >
                            Student
                          </span>
                        </Row>
                      </Space>
                    }
                  />
                </>
              }
            >
              <Row justify="center">
                <span
                  style={{
                    color: "gray",
                    textAlign: "center",
                    fontWeight: "bold",
                    paddingRight: "5px",
                  }}
                >
                  Student Id:
                </span>
                <span style={{color: "black"}}>{apiData.studentId}</span>
              </Row>
              <Card
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  marginRight: 10,
                  backgroundColor: "#f8f9ff",
                }}
              >
                <Row
                  justify="center"
                  style={{
                    textAlign: "center",
                    marginBottom: 5,
                    color: "black"
                  }}
                >
                  Enrolled Since
                </Row>
                <Row
                  justify="center"
                  style={{ color: "#377dff", marginBottom: 5 }}
                >
                  {dayjs(apiData?.enrolledDate)?.format("DD MMM YYYY")}
                </Row>
                {yearsDiff > 0 ? (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {yearsDiff} years {monthsDiff} months {daysDiff} days )
                  </Row>
                ) : monthsDiff > 0 ? (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {monthsDiff} months {daysDiff} days )
                  </Row>
                ) : (
                  <Row justify="center" style={{ color: "gray" }}>
                    ( {daysDiff} days )
                  </Row>
                )}
              </Card>
              <Card
                style={{
                  marginTop: 15,
                  marginLeft: 10,
                  marginRight: 10,
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <Row
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <span>
                    <PhoneOutlined />
                  </span>
                  <span style={{ paddingLeft: 10, color: "black" }}>
                    {apiData?.contactDetail?.mobileNumber}
                  </span>
                </Row>
                <Row
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    marginBottom: 10,
                    color: "black"
                  }}
                >
                  <span>
                    <MailOutlined />
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip placement="right" title={apiData.contactDetail.email}>
                      <span>
                        {apiData.contactDetail.email.length > 25
                          ? apiData.contactDetail.email.slice(0, 25) + "..."
                          : apiData.contactDetail.email}
                      </span>
                    </Tooltip>
                  </span>
                </Row>
                <Row
                  style={{
                    textAlign: "left",
                    marginTop: 10,
                    marginBottom: 10,
                    color: "black"
                  }}
                >
                  <span>
                    <EnvironmentTwoTone />
                  </span>
                  <span style={{ paddingLeft: 10 }}>
                    <Tooltip placement="right" title={apiData.contactDetail.currentAddress}>
                      <span>
                        {apiData.contactDetail.currentAddress.length > 25
                          ? apiData.contactDetail.currentAddress.slice(0, 20) +
                            "..."
                          : apiData.contactDetail.currentAddress}
                      </span>
                    </Tooltip>
                  </span>
                </Row>
              </Card>
            </Card>
          </Col>
          <Col span={18}>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Card
                  bordered
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                              General Information
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row>
                    <Col lg={3} style={{ color: "gray" }}>
                      Enquiry Date
                    </Col>
                    <Col lg={21}>
                      {dayjs(apiData?.enquiryDate)?.format("DD MMM YYYY")}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      First Name
                    </Col>
                    <Col lg={3}>{apiData?.name}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Middle Name:
                    </Col>
                    <Col lg={3}>{apiData?.middleName}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Last Name:
                    </Col>
                    <Col lg={3}>{apiData?.lastName}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Date of Birth:
                    </Col>
                    <Col lg={3}>
                      {dayjs(apiData?.dateOfBirth)?.format("DD MMM YYYY")}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Gender
                    </Col>
                    <Col lg={3}>{apiData?.gender?.name}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Nationality
                    </Col>
                    <Col lg={3}>{apiData?.nationality}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      English Proficiency
                    </Col>
                    <Col lg={3}>
                      {" "}
                      {languageProficiency[apiData?.englishProficiency]}
                    </Col>
                    <Col lg={3} style={{ color: "gray" }}>
                    MentorName
                    </Col>
                    <Col lg={3}>
                      {" "}
                      {apiData?.mentorName}
                    </Col>
                    {/* <Col lg={3} style={{ color: 'gray' }}>Counselor's</Col>
                                <Col lg={3}>{apiData?.counselors}</Col> */}
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Sources
                    </Col>
                    <Col lg={3}>{apiData?.sources?.name}</Col>
                    {/* <Col lg={3} style={{ color: 'gray' }}>Assign To</Col>
                                <Col lg={3}>--</Col> */}
                    <Col lg={3} style={{ color: "gray" }}>
                      Center Visited
                    </Col>
                    <Col lg={3}>{CenterVisited[apiData?.centerVisited]} </Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Status
                    </Col>
                    <Col lg={3}>{outcomeType[apiData?.response]}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 16]} style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                              Address & Contact
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row>
                    <Col lg={3} style={{ color: "gray" }}>
                      Contact Number
                    </Col>
                    <Col lg={3}> {apiData?.contactDetail?.mobileNumber}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      City
                    </Col>
                    <Col lg={3}>{apiData?.city}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      State
                    </Col>
                    <Col lg={3}>
                      {
                        IndianState.find((x: any) => x.value === apiData?.state)
                          ?.label
                      }
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Email
                    </Col>
                    <Col lg={6}>
                      <Text
                        copyable
                        title={apiData?.contactDetail?.email}
                        ellipsis
                      >
                        {apiData?.contactDetail?.email}
                      </Text>
                    </Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Official Email
                    </Col>
                    <Col lg={8}>
                      <Text
                        copyable
                        title={apiData?.officialEmail}
                        ellipsis
                      >
                        {apiData?.officialEmail}
                      </Text>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Current Address
                    </Col>
                    <Col lg={9}>{apiData?.contactDetail?.currentAddress}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Permanent Address
                    </Col>
                    <Col lg={9}>{apiData?.contactDetail?.permanentAddress}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                              Parents and Guardian
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row>
                    <Col
                      lg={24}
                      style={{
                        color: "#4c4c4c",
                        marginBottom: "10px",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    ></Col>
                  </Row>
                  <Row>
                    <Col lg={3} style={{ color: "gray" }}>
                      Father's Name
                    </Col>
                    <Col lg={3}>{apiData?.fatherName}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Father's Occupation
                    </Col>
                    <Col lg={3}>{apiData?.fatherOccupation}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Mother's Name
                    </Col>
                    <Col lg={3}>{apiData?.motherName}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Mother's Occupation
                    </Col>
                    <Col lg={3}>{apiData?.motherOccupation}</Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian's Name
                    </Col>
                    <Col lg={3}>{apiData?.guardianName}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian Relation
                    </Col>
                    <Col lg={3}>{apiData?.guardianRelation}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian's Phone No
                    </Col>
                    <Col lg={3}>{apiData?.guardianPhone}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian's Occupation
                    </Col>
                    <Col lg={3}>{apiData?.guardianOccupation}</Col>
                  </Row>
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian's Address
                    </Col>
                    <Col lg={3}>{apiData?.guardianAddress}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Zip Code
                    </Col>
                    <Col lg={3}>{apiData?.guardianZipCode}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian's City
                    </Col>
                    <Col lg={3}>{apiData?.guardianCity}</Col>
                    <Col lg={3} style={{ color: "gray" }}>
                      Guardian's State
                    </Col>
                    <Col lg={3}>
                      {" "}
                      {
                        IndianState.find(
                          (state: any) => state.value === apiData?.state
                        )?.label
                      }
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={[24, 16]} style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <Space size={10}>
                            <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                              Education
                            </span>
                          </Space>
                        }
                      />
                    </>
                  }
                >
                  <Row gutter={[24, 16]}>
                    <Col span={4} style={{ color: "gray" }}>
                      School/Collage Name
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Grade/Graduate
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Year
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Percent
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Study Mode
                    </Col>
                    <Col span={4} style={{ color: "gray" }}>
                      Curriculum
                    </Col>
                    <Col span={3} style={{ color: "gray" }}>
                      Stream
                    </Col>
                  </Row>

                  {apiData?.academicRecord?.map((itm: any, indx: number) => (
                    <Row key={indx} gutter={[24, 16]}>
                      <Col span={4}>{itm?.schoolName}</Col>
                      <Col span={3}>{itm?.class?.name}</Col>
                      <Col span={3}>{itm?.year}</Col>
                      <Col span={3}>{itm?.percentage}</Col>
                      <Col span={3}>{StudyModeObj[itm?.studyMode]}</Col>
                      <Col span={4}>{itm?.curriculum?.name}</Col>
                      <Col span={3}>{itm?.stream?.name}</Col>
                    </Row>
                  ))}
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <>
                            {" "}
                            <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                              Upload Documents
                            </span>
                          </>
                        }
                      />
                    </>
                  }
                >
                  <Row style={{ marginTop: 18 }} gutter={[24, 16]}>
                    <Col lg={6}>
                      <Card size="small" title={"Photo"} bordered={false}>
                        <Space align="center" direction={"horizontal"}>
                          {apiData?.candidateImage?.path ? (
                            <img
                              width={184}
                              src={apiData?.candidateImage?.path}
                            />
                          ) : (
                            <>
                              <Empty />
                            </>
                          )}
                        </Space>
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card size="small" title={"Signature"} bordered={false}>
                        {apiData?.signature?.candidate?.path ? (
                          <img
                            width={184}
                            src={apiData?.signature?.candidate?.path}
                          />
                        ) : (
                          <>
                            <Empty />
                          </>
                        )}
                      </Card>
                    </Col>
                    <Col lg={6}>
                      <Card
                        size="small"
                        title={"Counselor Signature"}
                        bordered={false}
                      >
                        {apiData?.signature?.counselor?.path ? (
                          <img
                            width={184}
                            src={apiData?.signature?.counselor?.path}
                          />
                        ) : (
                          <>
                            <Empty />
                          </>
                        )}
                      </Card>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                            Required Information
                          </span>
                        }
                      ></Meta>
                    </>
                  }
                >
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={2} style={{ color: "gray" }}>
                      Total Fees
                    </Col>
                    <Col lg={2}>{apiData?.amount}</Col>
                    <Col lg={2} style={{ color: "gray" }}>
                      Student ID
                    </Col>
                    <Col lg={2}>{apiData?.studentId}</Col>
                    <Col lg={2} style={{ color: "gray" }}>
                      Card No.
                    </Col>
                    <Col lg={2}>{apiData?.cardNo}</Col>
                    <Col lg={2} style={{ color: "gray" }}>
                      Biometric Code
                    </Col>
                    <Col lg={2}>{apiData?.biometricCode}</Col>
                    <Col lg={2} style={{ color: "gray" }}>
                      Enrolled Date
                    </Col>
                    <Col lg={2}>
                      {dayjs(apiData?.enrolledDate)?.format("DD-MMM-YYYY")}
                    </Col>
                    <Col lg={2} style={{ color: "gray" }}>
                      Batch
                    </Col>
                    <Col lg={2}>{apiData?.batch?.name}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginTop: 18 }}>
              <Col span={24}>
                <Card
                  size="small"
                  title={
                    <>
                      <Meta
                        style={{ fontSize: 12 }}
                        title={
                          <>
                            {" "}
                            <span style={{ fontSize: 14, color: "#4c4c4c" }}>
                              Other Information
                            </span>
                          </>
                        }
                      />
                    </>
                  }
                >
                  <Row style={{ marginTop: 18 }}>
                    <Col lg={3} style={{ color: "gray" }}>
                      Other Information
                    </Col>
                    <Col lg={8}>{apiData?.otherInformation ?? "---"}</Col>
                    <Col lg={6} style={{ color: "gray" }}>
                      Referral Source / Where did you hear about us?
                    </Col>
                    <Col lg={7}>{apiData?.referralSource ?? "---"}</Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ViewStudentDetails;

import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { Col, Row, DatePicker, Button, Table, Spin, Popover, Space, Card } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import "../../../Common/MainLayout.css";
import type { ColumnsType } from "antd/es/table";

import MarkAttendanceBulk from "./MarkAttendanceBulk";
import { adminContext } from "../../../Common/PageRoute";
import dayjs, { Dayjs } from "dayjs";
import AttendanceService from "../../../Services/AttendanceService";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../../Store/Slice/batchSlice";
import { ApiUtility } from "../../../Utilities/ApiUtility.axios";


interface DataType {
    key: React.Key;
    sno: number;
    name: string;
    fullName: string;
}
const AttSheet: React.FC = () => {
    const { openNotification } = useContext(adminContext);
    const [pagingItems, setPagingItems] = useState<{ totalRecords: number, currentPage: number, pageSize: number }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
    const [trigger, setTrigger] = useState(false);
    const [pageLoader, setPageLoader] = useState(false);
    const [batchLoader, setBatchLoader] = React.useState<boolean>(false);
    const [tableLoader, setIsTableLoader] = React.useState<boolean>(false);
    const [isCSVDownloading, setISCSVDownloading] = useState<boolean>(false);
    const [dataSource, setDataSource] = React.useState<DataType[]>([]);
    const [monthDates, setMonthDates] = useState<any>([]);
    const [monthDays, setMonthDays] = useState<any>([]);
    const [holidays, setHolidays] = useState<any>([]);



    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    // const [selectedBatch, setSelectedBatch] = useState<string>("");
    const [openDrawer, setOpenDrawer] = useState(false);

    // const [masterData, setMasterData] = useState<{
    //     batch: any;
    // }>({
    //     batch: [],

    // });
    // const [listParams, setListParams] = useState({
    //     month: 0,
    //     year: 0,
    //     batchId: "",
    //     start: 0,
    //     length: 15,
    //   });
    //   const changeListParams = (key: string, value: any) => {
    //     setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    //     setListParams((prevState) => ({
    //       ...prevState,
    //       [key]: value,
    //     }));
    //   };
    // const getMaster = async () => {
    //     let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?", {});
    //     if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
    //         const result = res?.result?.items;
    //         const transformedItems = result.map((item: any) => {
    //             return {
    //                 masterType: item.masterType,
    //                 items: item.items.map((innerItem: any) => {
    //                     return {
    //                         value: innerItem.id,
    //                         label: innerItem.name
    //                     };
    //                 })
    //             };
    //         });

    //         let obj: {
    //             batch: any;
    //         } = {
    //             batch: transformedItems?.filter((x: any) => x?.masterType === MasterTypes.Batch)?.[0]?.items,
    //         }
    //         setMasterData({ ...obj });
    //     } else {
    //         message.error(res?.message)
    //     }
    // };
    const batchState = useSelector(selectBatchState)
    useEffect(() => {
        // getMaster();
        // getHolidays();
        calDate(dayjs().startOf("month"), dayjs().endOf("month"));
    }, [])
    useEffect(() => {
        setPageLoader(true);
        getAttendanceSheetData((pagingItems.currentPage - 1) * pagingItems.pageSize, pagingItems.pageSize);
        setPageLoader(false);
      }, [selectedMonth, pagingItems, batchState]);


    useEffect(() => {
        getAttendanceSheetData(((pagingItems?.currentPage - 1) * pagingItems?.pageSize), pagingItems?.pageSize);
    }, [trigger, selectedMonth, pagingItems, batchState])

    useEffect(() => {
        if (!tableLoader && !batchLoader) {
            setPageLoader(false)
        }
    }, [batchLoader, tableLoader])
    console.log("name", dataSource);

    //------------- Get Students Record Endpoint ----------//
    const getAttendanceSheet = async (start: any, length: any, month: any) => {
        setIsTableLoader(true);
        let params = {
            // batchId: batch ?? "",
            batchId: batchState,
            month: month.format("MM"),
            year: month.format("YYYY"),
            start: start,
            length: length
        }
        let res = await AttendanceService.get_att_in_sheet(params);
        if (res) {
            console.log("result", res);

            setPagingItems((p) => { p.totalRecords = res?.totalRecords; return p });
            setDataSource(
                res?.items[0].students?.map((item: any, index: number) => (
                    {
                        key: index,
                        sno: start + index + 1,
                        name: item?.name,
                        fullName: item?.fullName,
                        enrDate: item?.enrolledDate,
                        records: item?.records,
                        ...(item?.leaveArr&& {leave:item?.leaveArr})
                    })) || []
            );
            const newAttendanceCount: Record<string, number> = {};
            res?.items[0].students?.forEach((student: any) => {
                student.records.forEach((record: any) => {
                    if (record.attendance === 'P') {
                        const dateKey = record.day; // Or use your date format
                        if (!newAttendanceCount[dateKey]) {
                            newAttendanceCount[dateKey] = 0;
                        }
                        newAttendanceCount[dateKey]++;
                    }
                });
            });
            setAttendanceCount(newAttendanceCount);
            let holidays = res?.items[0].holidaysArr;
            res.holidays = holidays
            setHolidays(holidays)
        }
        setIsTableLoader(false);
    };

    const getAttendanceSheetData = (start: any, length: any) => {
        if (selectedMonth) {
            getAttendanceSheet(start, length,selectedMonth)
        }
        else {
            if (!openDrawer) {
                openNotification("warning", "Please select valid month");
            }
        }
    }

    const calDate = (startDate: any, endDate: any) => {
        let dates = [];
        let days = [];
        for (let date = startDate; date.isSameOrBefore(endDate); date = date.add(1, "day")) {
            dates.push(dayjs(date));
            days.push(dayjs(date).format("ddd"));
        }
        setMonthDates(dates);
        setMonthDays(days);
    };

    const downloadSheet = async (start: number, length: number, batch: string, month: Dayjs) => {
        setISCSVDownloading(true);
        await ApiUtility.downloadFile(HTTPSCalls.ENDPOINTS.DOWNLOAD_SHEET, {
          batchId: batchState,
          month: month.format("MM"),
          year: month.format("YYYY"),
          start: start,
          length: length
        });
        setISCSVDownloading(false);
      };
    
    const [attendanceCount, setAttendanceCount] = useState<any>({});

    const columns2: ColumnsType<DataType> = [
        {
            title: "S.No.",
            dataIndex: "sno",
            key: "sno",
            width: 60,
            align: "start",
            fixed: "left",
            render: (text: any, record: any) => {
                return <p style={{ padding: "8px 6px" }}>{record?.sno}</p>;
            },
        },
        {
            title: "Name",
            dataIndex: "fullName",
            key: "fullName",
            width: 280,
            fixed: "left",
            ellipsis: true,
            render: (text: any, record: any) => (
                <p style={{ padding: "8px 6px" }} title={record?.fullName}>
                    {record?.fullName}
                </p>
            ),
        },
        ...monthDates.map((date: any, dateIndex: any) => {
            // Calculate the total present and absent for this date
            const totalPresent = attendanceCount[date.format("D")] || 0;
            const totalAbsent = pagingItems.totalRecords - totalPresent;

            return {
                title: (
                    <Popover
                        content={
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total Present:</span>
                                    <span style={{ color: "green" }}>{totalPresent}</span>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total Absent:</span>
                                    <span style={{ color: "red" }}>{totalAbsent}</span>
                                </div>
                                <hr />
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Total:</span>
                                    <span>{pagingItems.totalRecords}</span>
                                </div>
                            </div>
                        }
                        title="Attendance Count"
                    >
                        <div style={{ padding: '8px', textAlign: 'center' }}>
                            {date.format("D")}
                        </div>
                    </Popover>
                ),
                dataIndex: "",
                children: [
                    {
                        title: monthDays[dateIndex],
                        render: (text: any, record: any) => renderItem(date, record),
                    },
                ],
            };
        }),
    ];


    const drawerClose = () => {
        setOpenDrawer(false);
    }

    const getColor = (attStatus: string, isLeave: boolean, isHoliday: boolean,day:any) => {
        if (attStatus === "P") {
            return "green";
        }  else if (isHoliday||day === "Sun" || day === "Sat") {
            return "red";
        } else if (isLeave) {
            return "#b029bc";
        }else {
            return "red";
        }
    };

    const renderItem = (date1: Dayjs, record: any) => {
        const date = date1.format("D");
        const day = date1.format("ddd");
        // console.log(date,"dateDay",day)
    
        let attStatus = record?.records?.find((x: any) => x?.day === date)?.attendance || "A";
        const cellDateFormatted = dayjs(dayjs(`${dayjs(selectedMonth).year()}-${dayjs(selectedMonth).month() + 1}-${Number(date)}`).format("DD/MM/YYYY"), 'DD/MM/YYYY').format('YYYY-MM-DD');
        const enrollDateFormatted = dayjs(dayjs(record?.enrDate).format("DD/MM/YYYY"), 'DD/MM/YYYY').format('YYYY-MM-DD');
    
        let isEnrolled = dayjs(cellDateFormatted).isSameOrAfter(enrollDateFormatted);
        let isFuture = dayjs(cellDateFormatted).isSameOrAfter(dayjs());
        let isHoliday = holidays?.some((h: any) => dayjs(h.date).isSame(date1, 'day')) ?? false;

        const isLeave = record?.leave?.some((leave: any) => {
            const leaveFromDate = dayjs(leave.date.from).format('YYYY-MM-DD');
            const leaveToDate = dayjs(leave.date.to).format('YYYY-MM-DD');
            
            return (dayjs(leaveFromDate).isSameOrBefore(cellDateFormatted) && dayjs(leaveToDate).isSameOrAfter(cellDateFormatted)) ||
                   (dayjs(leaveFromDate).isSameOrAfter(dayjs().format('YYYY-MM-DD')) && dayjs(cellDateFormatted).isSameOrAfter(leaveFromDate));
        });
        return (
            <p style={{ textAlign: "center" }}>
                {
                    (!isFuture && isEnrolled) ? (
                        <span style={{ fontWeight: day === "Sun" || day === "Sat" ? "bolder" : "", padding: "8px 6px", color: getColor(attStatus,isLeave,isHoliday,day) }}>
                            {
                                attStatus === "P" ? "P"
                                    : day === "Sun" || day === "Sat" || isHoliday ? "H"
                                        : isLeave ? "FL"
                                            : attStatus === "A" ? "A"
                                                : "-"
                            }
                        </span>
                    ) :isLeave||day === "Sun" || day === "Sat"
                    ?<span style={{ fontWeight: day === "Sun" || day === "Sat" ? "bolder" : "",padding: "8px 6px", color: getColor(attStatus,isLeave,isHoliday,day) }}>
                    {
                        attStatus === "P" ? "P"
                            : day === "Sun" || day === "Sat" || isHoliday ? "H"
                                : isLeave ? "FL"
                                    : attStatus === "A" ? "A"
                                        : "-"
                    }
                </span>: "-"
                }
            </p>
        );
    }

    // const renderItem = (date1: any, record: any) => {
    //     const date = date1.format("D");
    //     const day = date1.format("ddd");

    //     let attStatus = record?.records?.find((x: any) => x?.day === date)?.attendance;
    //     const cellDateFormatted = dayjs(dayjs(`${dayjs(selectedMonth).year()}-${dayjs(selectedMonth).month() + 1}-${Number(date)}`).format("DD/MM/YYYY"), 'DD/MM/YYYY').format('YYYY-MM-DD');
    //     const enrollDateFormatted = dayjs(dayjs(record?.enrDate).format("DD/MM/YYYY"), 'DD/MM/YYYY').format('YYYY-MM-DD');

    //     let isEnrolled = dayjs(cellDateFormatted).isSameOrAfter(enrollDateFormatted);
    //     let isFuture = dayjs(cellDateFormatted).isSameOrAfter(dayjs());
    //     console.log("date1 = ", date1)
    //     console.log("attStatus = ", attStatus)
    //     console.log("cellDateFormatted = ", cellDateFormatted)
    //     console.log("enrollDateFormatted = ", enrollDateFormatted)
    //     console.log("isEnrolled = ", isEnrolled)
    //     console.log("isFuture = ", isFuture)


    //     return (
    //         <p style={{ textAlign: "center" }}>
    //             {
    //                 (isEnrolled) ?
    //                     <span style={{ padding: "8px 6px", color: attStatus ? attStatus === "P" ? "green" : attStatus === "A" ? "red" : "red" : day === "Sun" || day === "Sat" ? "black" : "black" }}>
    //                         {
    //                             attStatus === "P" ? "P" : attStatus === "A" ? "A" : day === "Sun" || day === "Sat" ? "H" : "-"
    //                         }
    //                     </span> : "-"
    //             }
    //         </p>
    //     )
    // }
    return (
        <>
      {    openDrawer&&  <MarkAttendanceBulk openDrawer={openDrawer} drawerClose={drawerClose} setTrigger={setTrigger} />}


            <Card>
                <Spin
                    spinning={pageLoader}
                    indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                >
                    <Row justify="space-between">
                        <Col>
                            <Space.Compact>
                                 <Button
                                    className="cit-add-btn mb-1"
                                    onClick={() => setOpenDrawer(true)}
                                    style={{ marginBottom: "20px" }}
                                    type="primary"
                                >
                                    <PlusOutlined /> Attendance
                                </Button>
                                {/* <Button
                                    type="primary"
                                    ghost
                                    className="cit-add-btn"
                                    onClick={() => downloadSheet((pagingItems.currentPage - 1) * pagingItems.pageSize, pagingItems.pageSize, batchState, selectedMonth)}
                                    loading={isCSVDownloading}
                                    icon={<DownloadOutlined />}
                                >
                                    CSV
                                </Button> */}
                            </Space.Compact>
                        </Col>
                        <Col>
                            {/* <Select
                                popupClassName="cit-select-box"
                                className="me-2"
                                placeholder={"Select Batch"}
                                allowClear
                                showSearch
                                onClear={()=>setSelectedBatch("")}
                                options={masterData?.batch}
                                style={{ width: 160 }}
                                onSelect={(val: any) => {
                                    setSelectedBatch(val)
                                }}
                                
                                filterOption={(input, option:any) =>
                                    (option?.label ?? "")
                                    ?.toLowerCase()
                                    .includes(input?.trim().toLowerCase())
                                }
                                /> */}

                            <DatePicker
                                allowClear={false}
                                className="me-2"
                                value={selectedMonth}
                                picker="month"
                                format={"MMM-YYYY"}
                                disabledDate={(current: any) => {
                                    return current && current > dayjs().endOf("month");
                                }}
                                onChange={(val: any) => {
                                    calDate(
                                        dayjs(val).startOf("month"),
                                        dayjs(val).endOf("month")
                                    );
                                    setSelectedMonth(val)
                                }}
                            />


                        </Col>
                    </Row>
                    <Row>
                        <Table
                            className="cit-att-sheet"
                            loading={{ spinning: tableLoader, indicator: <Spin indicator={<LoadingOutlined />} /> }}
                            columns={columns2}
                            dataSource={dataSource}
                            bordered
                            size="small"
                            scroll={{ x: 1800, y: 550 }}
                            style={{ minHeight: 700 }}
                            pagination={{
                                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, selectPrefixCls: "custom-table-select",
                                current: pagingItems.currentPage,
                                pageSize: pagingItems.pageSize,
                                showSizeChanger: true,
                                total: pagingItems.totalRecords,
                                pageSizeOptions: [15, 30, 50, 100],
                                position: ["bottomRight"],
                            }}
                            onChange={(paging) => {
                                paging && setPagingItems({ ...pagingItems, currentPage: paging?.current ?? 1, pageSize: paging?.pageSize ?? 15 })
                            }}
                        />
                    </Row>
                </Spin>
            </Card>

        </>



    );
};

export default AttSheet;

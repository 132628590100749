import React, { useEffect, useState } from "react";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { Button, Col, message, Row, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import Search from "antd/es/input/Search";
import { DeleteOutlined, FormOutlined, PlusOutlined, UndoOutlined } from "@ant-design/icons";
import AddEditNotice from "./AddEditNotice";
import {
  ActiveInactiveOptions,
  MasterTypes,
  RecordStatusType,
  StatusOptions,
} from "../../../Constant/Enums";

const NoticeList: React.FC = () => {
  const [dataSource, setDataSource] = useState<any>([{}]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [listParams, setListParams] = useState({ search: "" });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [pagingItems, setPagingItems] = useState<{
    totalRecords: number;
    currentPage: number;
    pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [recordData, setRecordData] = useState([]);
  const [changedStatus, setChangedStatus] = useState<any>("active");
  const columns: ColumnsType<any> = [
    {
      title:"S.No",
      dataIndex:"sno",
      key:"sno",
      width:"2%",
      render:(index:any)=><p> {index}</p>
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "title",
      width: "5%",
      render: (text, record) => <p>{record?.name}</p>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "30%",
      ellipsis: true,
      showSorterTooltip: true,
      render: (text, record) => <p className="cursor">{record?.description}</p>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "3%",
      ellipsis: true,
      align: "center",
      render: (text, record) => (
        <Space size={20}>
          <FormOutlined
            className="cursor"
            onClick={() => {
              setOpen(true);
              setRecordData(record);
            }}
          />
          {record?.recordStatus === RecordStatusType?.Deleted ? (
            <UndoOutlined
              className="cursor"
              onClick={() => {
                UpdateRecordStatus(record?.id, "restore");
              }}
            />
          ) : (
            <DeleteOutlined
              className="cursor"
              onClick={() => { UpdateRecordStatus(record?.id, "delete"); }}
            />
          )}
        </Space>
      ),
    },
  ];
  const getNoticeList = async (start: number, length: number) => {
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.POST_MASTER + "?", {
        masterType: MasterTypes.Notice,
        start: start,
        length: length,
        search: listParams?.search,
        status: changedStatus,
        sortCol:"createdBy.Date",
      });
      if (res?.status) {
        // console.log(listParams.search, "gety");
        setDataSource(res?.result?.items);
        setPagingItems((p) => {
          p.totalRecords = res?.result?.totalRecords;
          return p;
        });
      } else {
        message.warning(res?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getNoticeList(
      (pagingItems?.currentPage - 1) * pagingItems?.pageSize,
      pagingItems?.pageSize
    );
  }, [trigger, listParams, pagingItems, refresh, changedStatus]);

  const [open, setOpen] = useState(false);
  const UpdateRecordStatus = async (
    noticeId?: string,
    action: string = "delete"
  ) => {
    try {
      if (!noticeId) {
        console.error("Notice ID is required to update record status.");
        message.error("Notice ID is missing. Unable to update record status.");
        return;
      }
      setLoading(true);
      await HTTPSCalls.POST(
        `${HTTPSCalls.ENDPOINTS.UPDATE_RECORD_STATUS}` + "?",
        {
          objId: noticeId,
          restore: action
        },
        {}
      );
      setRefresh((prev) => !prev);
    } catch (error) {
      message.error("An unexpected error occured");
      console.log("Error during API call:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={14}>
            <Col lg={18}>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(true);
                  setRecordData([]);
                }}
              >
                <PlusOutlined />
                Notice
              </Button>
            </Col>
            <Col lg={3}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Search status"
                optionFilterProp="label"
                onChange={(val) => { setChangedStatus(val) }}
                defaultValue={"active"}
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={ActiveInactiveOptions}
              />
            </Col>
            <Col lg={3}>
              <Search
                size="middle"
                placeholder="Search..."
                allowClear
                className="att-search-input mb-1"
                onSearch={(val: string) => setListParams({ search: val })}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Col span={24}>
            <Table
              rowKey={(record: any) => record?.id}
              className="pt-2"
              columns={columns}
              loading={loading}
              dataSource={dataSource}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: ["15", "25", "50", "100"],
              }}
              onChange={(paging) => {
                setPagingItems({
                  ...pagingItems,
                  currentPage: paging?.current ?? 1,
                  pageSize: paging?.pageSize ?? 15,
                });
              }}
            ></Table>
          </Col>
        </Col>
        <AddEditNotice
          open={open}
          setOpen={setOpen}
          setTrigger={setTrigger}
          trigger={trigger}
          recordData={recordData}
        />
      </Row>
    </>
  );
};

export default NoticeList;

import React, { useEffect, useState } from "react";
import { CloseOutlined, DeleteTwoTone, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row, Space, Form, Select, message } from "antd";
import HTTPSCalls from "../../../Services/HTTPCalls";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    lg: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    lg: { span: 24 },
  },
};

const SubjectAllocationAdd: React.FC<{
  openDrawer: boolean;
  setOpenDrawer: Function;
  sendRecord: any;
  setTrigger: any;
}> = (props) => {
  const [form] = Form.useForm();
  const [departMentId, setDepartMentId] = useState<string>("");
  const [courseId, setCourseId] = useState<string>("");
  const [batchId, setBatchId] = useState<string>("");
  const [semesterId, setSemesterId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [departMentData, setDepartMentData] = useState<any[]>([]);
  const [courseData, setCourseData] = useState<any[]>([]);
  const [batchData, setBatchData] = useState<any[]>([]);
  const [sectionData, setSectionData] = useState<any[]>([]);
  const [semesterData, setSemesterData] = useState<any[]>([]);
  const [teacherData, setTeacherData] = useState<any[]>([]);
  const [subjectData, setSubjectData] = useState<any[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getDetails = async () => {
    setLoading(true);
    const res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_SUBJECT_ALLOCATION_LIST,
      {},
      {
        departMentId,
        courseId,
        batchId,
        semesterId,
      }
    );
    if (res?.result) {
      const mapData = (data: any[]) =>
        data?.map((item: any) => ({
          label: item?.name,
          value: item?.id,
        }));

      setDepartMentData(mapData(res?.result?.departments));
      setCourseData(mapData(res?.result?.courses));
      setBatchData(mapData(res?.result?.batches));
      setSectionData(mapData(res?.result?.sections));
      setTeacherData(
        res?.result?.teachers?.map((itm: any) => ({
          value: itm?.teacher?.id,
          label: itm?.teacher?.name,
        }))
      );
      setSemesterData(mapData(res?.result?.semesters));
      let subjectData = res?.result?.subjects?.map((itm: any) => {
        return {
          value: itm?.id,
          label: itm?.name
        }

      })
      setSubjectData(subjectData);
    } else {
      console.error(res.errors);
    }
    setLoading(false);
  };

  useEffect(() => {
    getDetails();
  }, [departMentId, courseId, batchId, semesterId]);

  useEffect(() => {
    if (props?.sendRecord?.id) {
      console.log("props?.sendRecord?.details",props?.sendRecord?.details);
      
      form.setFieldsValue({
        department: {
          label: props?.sendRecord?.department?.name,
          value: props?.sendRecord?.department?.id,
        },
        course: {
          label: props?.sendRecord?.course?.name,
          value: props?.sendRecord?.course?.id,
        },
        batch: {
          label: props?.sendRecord?.batch?.name,
          value: props?.sendRecord?.batch?.id,
        },
        teacher: {
          label: props?.sendRecord?.teacher?.name,
          value: props?.sendRecord?.teacher?.id,
        },
        details:props?.sendRecord?.details?.map((detail:any)=> {
          return {
            subjects: {
              label: detail?.subjects?.name,
              value: detail?.subjects?.id,
            },
            semester: {
              label: detail?.semester?.name,
              value: detail?.semester?.id,
            },
            section: {
              label: detail?.section?.name,
              value: detail?.section?.id,
            },
          }}  
        )||[]
      });
      setDepartMentId(props?.sendRecord?.department?.id);
      setCourseId(props?.sendRecord?.course?.id);
      setBatchId(props?.sendRecord?.batch?.id);
      setSemesterId(props?.sendRecord?.details[0]?.semester?.id);
    }
  }, [props?.sendRecord]);

  const onHandleSubmit = async () => {
    try {
      setButtonLoading(true);
      const values = await form.validateFields();
      const formVal = {
        ...values,
        department: mapOption(values?.department),
        course: mapOption(values?.course),
        batch: mapOption(values?.batch),
        teacher: mapOption(values?.teacher),
        details: values?.details?.map(mapDetail) || [],
      };

      const allocationId = props?.sendRecord?.id
        ? { allocationId: props.sendRecord.id }
        : {};
      const endpoint = props?.sendRecord?.id
        ? `${HTTPSCalls.ENDPOINTS.UPDATE_SUBJECTALLOCATION}`
        : `${HTTPSCalls.ENDPOINTS.ADD_SUBJECTALLOCATION}`;

      const res = await HTTPSCalls.POST(endpoint+"?", allocationId, formVal);
      if (res.status) {
        form.resetFields();
        message.success("Subject allocated successfully");
        props.setOpenDrawer(false);
        props?.setTrigger((prev: boolean) => !prev);
      } else {
        message.error(res.message);
      }
    } catch (err) {
      console.error("Validation error:", err);
    } finally {
      setButtonLoading(false);
    }
  };

  const mapOption = (option: any) =>
    option ? { id: option.value, name: option.label } : undefined;

  const mapDetail = (item: any) => ({
    section: mapOption(item?.section),
    semester: mapOption(item?.semester),
    subjects: mapOption(item?.subjects),
  });

  return (
    <Drawer
      title="Subject Allocation"
      onClose={() => props.setOpenDrawer(false)}
      open={props.openDrawer}
      width={600}
      maskClosable={false}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          className="cursor"
          onClick={() => props.setOpenDrawer(false)}
        />
      }
      footer={
        <Row justify="end">
          <Space size={10}>
            <Button onClick={() => props.setOpenDrawer(false)}>Cancel</Button>
            <Button
              type="primary"
              className="btn-m-sm"
              onClick={onHandleSubmit}
              loading={buttonLoading}
            >
              Save
            </Button>
          </Space>
        </Row>
      }
    >
      <Form
        form={form}
        name="batch-form"
        colon={false}
        autoComplete="off"
        style={{ padding: 16 }}
        {...formItemLayout}
        labelAlign="left"
        requiredMark={false}
        initialValues={{ details: [{}] }}
      >
        <Form.Item
          label="Department"
          name="department"
          rules={[{ required: true, message: "Please select Department!" }]}
        >
          <Select
            placeholder="Choose Department"
            labelInValue
            loading={loading}
            options={departMentData}
            onSelect={(e: any) => setDepartMentId(e.value)}
          />
        </Form.Item>
        <Form.Item
          label="Course"
          name="course"
          rules={[{ required: true, message: "Please select Course!" }]}
        >
          <Select
            placeholder="Choose Course"
            labelInValue
            loading={loading}
            options={courseData}
            onSelect={(e: any) => setCourseId(e.value)}
          />
        </Form.Item>
        <Form.Item
          label="Batch"
          name="batch"
          rules={[{ required: true, message: "Please select Batch!" }]}
        >
          <Select
            placeholder="Choose Batch"
            labelInValue
            loading={loading}
            options={batchData}
            onSelect={(e: any) => setBatchId(e.value)}
          />
        </Form.Item>
        <Form.Item
          label="Teacher"
          name="teacher"
          rules={[{ required: true, message: "Please select Teacher!" }]}
        >
          <Select
            placeholder="Select Teacher"
            labelInValue
            loading={loading}
            options={teacherData}
          />
        </Form.Item>

        <Form.List name="details" >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row gutter={[10, 10]} key={key}>
                  <Col lg={7}>
                    <Form.Item
                      {...restField}
                      name={[name, "semester"]}
                      rules={[{ required: true, message: "Missing Semester" }]}
                    >
                      <Select
                        placeholder="Select Semester"
                        labelInValue
                        loading={loading}
                        options={semesterData}
                        onSelect={(e: any) => {
                          setSemesterId(e.value);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={7}>
                    <Form.Item
                      {...restField}
                      name={[name, "section"]}
                      rules={[{ required: true, message: "Missing Section" }]}
                    >
                      <Select
                        placeholder="Select Section"
                        labelInValue
                        loading={loading}
                        options={sectionData}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={8}>
                    <Form.Item
                      {...restField}
                      name={[name, "subjects"]}
                      rules={[{ required: true, message: "Missing Subject" }]}
                    >
                      <Select
                        placeholder="Select Subject"
                        labelInValue
                        loading={loading}
                        options={subjectData}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={2}>
                 { name>0 &&
                    <DeleteTwoTone
                      style={{
                        fontSize: 20,
                        cursor: "pointer",
                        display: "block",
                        marginTop: 6,
                      }}
                      onClick={() => remove(name)}
                    />
                  }
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                className="btn-m-sm"
                icon={<PlusOutlined />}
                onClick={() => add()}
              >
                Add Section
              </Button>
            </>
          )}
        </Form.List>
      </Form>
    </Drawer>
  );
};

export default SubjectAllocationAdd;

import { Table } from "antd";
import dayjs from "dayjs";

const HolidaysTable = (props: any) => {
  // Ensure only top 7 holidays are shown
  const topHolidays = props?.data?.slice(0, 7);
  const columns = [
    {
      title: "S.No.",
      dataIndex: "sNo",
      text: "center",
      key: "sNo",
      width: "9%",
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: "Holidays",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "From",
      dataIndex: "fromTo",
      key: "From",
      width: "25%",
      render: (text: any) => (
        <p>{dayjs.utc(text?.from).format("DD MMM YYYY")}</p>
      ),
    },
    {
      title: "To",
      dataIndex: "fromTo",
      key: "To",
      width: "25%",
      render: (text: any) => <p>{dayjs.utc(text?.to).format("DD MMM YYYY")}</p>,
    },
  ];

  return (
    <>
      <Table
        className="mt-2 dashBoardTable-Font"
        rowKey={(record) => record?.id}
        style={{
          width: "100vw",
          borderBottom: "none",
          maxHeight: 730,
          fontSize: "13px",
          overflow: "auto",
          minHeight: 280,
        }}
        size="small"
        pagination={false}
        dataSource={topHolidays}
        columns={columns}
        // loading={pageLoader}
      />
    </>
  );
};

export default HolidaysTable;

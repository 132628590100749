import { ArrowLeftOutlined } from "@ant-design/icons";
import { Breadcrumb, Row, Tabs, TabsProps } from "antd";
import { useLocation, useNavigate } from "react-router";
import AssignDeviceEmployees, { AssignDeviceStudents } from "./AssignDeviceStudents";
import  ManageAssignedStudents  from "./ManageAssignedStudents";

export const BioMetricDeviceEmployeeList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathSegements=location.pathname.split("/");
  const matchineCode=pathSegements[pathSegements.length-1];
  const deviceRecord = location.state;
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Assign Device to students",
      children: <AssignDeviceStudents machineCode={matchineCode} deviceRecord={deviceRecord} />,
    },
    {
      key: "2",
      label: "Manage Assigned students",
      children: <ManageAssignedStudents machineCode={matchineCode} deviceRecord={deviceRecord} />,
    },
  ];

  return (
    <>
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate(-1);
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Biometric Devices",
            },
          ]}
        ></Breadcrumb>
      </Row>

      <Row
        className="roundedCornerSmall bg-white"
        style={{ padding: "0px 16px 16px 16px" }}
      >
        <Tabs destroyInactiveTabPane defaultActiveKey="1" items={items} />
      </Row>
    </>
  );
};

export default BioMetricDeviceEmployeeList;

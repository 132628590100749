import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Row,
  Drawer,
  Button,
  Form,
  Input,
  Spin,
  TimePicker,
  Checkbox,
  Col,
  message,
  Select,
} from "antd";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { adminContext } from "../../../Common/PageRoute";
import MasterService from "../../../Services/MasterService";
import Table, { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { ApiCalls } from "../../../ApisCaller/ApiCall";
import { MasterTypes } from "../../../Constant/Enums";
import { BatchMaster } from "../../../ApiUrls/URLS";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { useQuery,useMutation } from "@tanstack/react-query";
import { transpileModule } from "typescript";
import Item from "antd/es/list/Item";
import Course from "../Course/Course";
import Department from "../Department/Department";
let timeout: any;
const api = new ApiCalls(BatchMaster.endPoints, BatchMaster.prefix);
interface IDay {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
}

interface IBatchType {
  key: string;
  checkbox: boolean;
}
const AddEditBatch: React.FC<{
  setTrigger: Function;
  batchId: string | undefined;
  SetDrawerClose: Function;
  openDrawer: boolean;
  setBatchId:Function;
}> = (props) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  const batchDaysData: IBatchType[] = [
    {
      key: "1",
      checkbox: false,
    },
  ];
  const columnsNonBatch: ColumnsType<IBatchType> = [
    {
      title: "Mon",
      dataIndex: "mon",
      key: "1",

      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.mon}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, mon: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Tue",
      dataIndex: "tue",
      key: "2",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.tue}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, tue: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Wed",
      dataIndex: "wed",
      key: "3",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.wed}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, wed: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Thu",
      dataIndex: "thu",
      key: "4",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.thu}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, thu: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Fri",
      dataIndex: "fri",
      key: "5",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.fri}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, fri: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sat",
      dataIndex: "sat",
      key: "6",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sat}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sat: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
    {
      title: "Sun",
      dataIndex: "sun",
      key: "7",
      render: (_, record: any) => (
        <Checkbox
          checked={batchDays.sun}
          onChange={(e: any) =>
            setBatchDays({ ...batchDays, sun: e.target.checked })
          }
        ></Checkbox>
      ),
      align: "center",
    },
  ];
  const [departmentData, setDepartmentData]: any = useState([]);
  const [currentSchema, setCurrentSchema]: any = useState();
  const [form] = Form.useForm();
  const { openNotification } = React.useContext(adminContext);
  const { setTrigger, batchId, SetDrawerClose, openDrawer,setBatchId } = props;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [batchDays, setBatchDays] = useState<IDay>({
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
  });
  const [courseData, setCourseData] = useState<any[]>([]);
  const [sessionData, setSessionData] = useState<any[]>([]);
  const [semesterData, setSemesterData] = useState<any[]>([]);
  
  // const [details, setDetails] = useState<{
  //   departments: any;
  //   courses: any;
  //   schemas: any;
  //   semesters: any;
  //   subjects: any;
  // }>({
  //   courses: [],
  //   departments: [],
  //   schemas: [],
  //   semesters: [],
  //   subjects: [],
  // });
  const [departmentId, setDepartmentId]: any = useState("string");
  const [courseId, setCourseId] = useState<string>("string");
  // const [sessionId, setSessionId] = useState<string>("string");
  const [schemaId, setSchemaId] = useState<string>("string");
  // const [semesterId, setSemesterId] = useState<string>("string");
  // const [subjectId, setSubjectId] = useState<string>("string");

  // ------- Get Course Master ----------//

  const { data: masterDetail, isLoading:masterLoading, refetch } = useQuery(
    {
        queryKey: ['MasterDetail',departmentId,courseId,schemaId],            
        queryFn: (async () => {
          let res = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.GET_COURSE_DETAILS,
            {},
            {
              departmentId,
              courseId,
              schemaId
            })
            setCurrentSchema(res?.result?.schemas?.map((itm: any) => ({
              value: itm?.id,
              label: itm?.name,
            })))
            // sem);
              return {
                departments: res?.result?.departments?.map((itm: any) => ({
                  value: itm?.id,
                  label: itm?.name,
                  MasterTypes:itm.masterType,
                  name: itm?.name,
                })),
                courses: res?.result?.courses?.map((itm: any) => ({
                  value: itm?.id,
                  label: itm?.name,
                  MasterTypes:itm.masterType,
                  name: itm?.name,
                })),
                schemas: res?.result?.schemas?.map((itm: any) => ({

                  value: itm?.id,
                  label: itm?.name,
                  MasterTypes:itm.masterType,
                  name: itm?.name,
                })),
                // semesters: res?.result?.semesters?.map((itm: any) => ({
                //   value: itm?.id,
                //   label: itm?.name,
                //   MasterTypes:itm.masterType,
                //   name: itm?.name,
                // })),
                // subjects: res?.result?.subjects?.map((itm: any) => ({
                //   value: itm?.id,
                //   label: itm?.name,
                //   MasterTypes:itm.masterType,
                //   name: itm?.name,
                // })),
              }
              
              

            // }
            // return transformedItems;
      })
    },

)


  // const getMaster = async () => {
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
  //     {}
  //   );
  //   if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
  //     const result = res?.result?.items;
  //     const transformedItems = result.map((item: any) => {
  //       return {
  //         masterType: item.masterType,
  //         items: item.items.map((innerItem: any) => {
  //           return {
  //             value: innerItem.id,
  //             label: innerItem.name,
  //           };
  //         }),
  //       };
  //     });

  //     setCourseData(
  //       transformedItems?.filter(
  //         (x: any) => x?.masterType === MasterTypes.Course
  //       )?.[0]?.items
  //     );
  //     // setSessionData(
  //     //   transformedItems?.filter(
  //     //     (x: any) => x?.masterType === MasterTypes.Session
  //     //   )?.[0]?.items
  //     // );
  //     // setSemesterData(
  //     //   transformedItems?.filter(
  //     //     (x: any) => x?.masterType === MasterTypes.Semester
  //     //   )?.[0]?.items
  //     // );
  //     //console.log(transformedItems, "test");
  //   } else {
  //     message.error(res?.message);
  //   }
  // };

  // useEffect(() => {
  //   if (openDrawer) {
  //     if (props?.batchId) {
  //       getBatchById(props?.batchId);
  //     } else {
  //       // setBatchDays({
  //       //   sun: false,
  //       //   mon: true,
  //       //   tue: true,
  //       //   wed: true,
  //       //   thu: true,
  //       //   fri: true,
  //       //   sat: false,
  //       // });
  //     }
  //     getMaster();
  //     // getDepartments();
  //   }
  // }, [openDrawer]);

  const { data: batchIdDetail, isLoading:batchIdLoading } = useQuery(
    {
        queryKey: ['batchIdDetail',batchId],            
        queryFn: (async () => {
          let data:IApiResponse = await api.GET(BatchMaster.endPoints.getById + "/" + batchId + "?", {
          masterType: MasterTypes.Batch,
          })
          if (data?.result) {
             setBatchDays({
                       sun: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 1 && d?.isChecked;
                       }),
                       mon: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 2 && d?.isChecked;
                       }),
                       tue: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 3 && d?.isChecked;
                       }),
                       wed: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 4 && d?.isChecked;
                       }),
                       thu: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 5 && d?.isChecked;
                       }),
                       fri: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 6 && d?.isChecked;
                       }),
                       sat: data?.result?.batchDays?.some((d: any) => {
                         return d?.day === 7 && d?.isChecked;
                       }),
                     });
    
                    form.setFieldsValue({
                      name: data?.result?.name,
                      code: data?.result?.code,
                      course: {
                        label: data?.result?.course?.name,
                        value: data?.result?.course?.id,
                      },
                      department: {
                        label: data?.result?.department?.name,
                        value: data?.result?.department?.id,
                      },
                      schema: {
                        label: data?.result?.schema?.name,
                        value: data?.result?.schama?.id,
                      },
                      inTime:dayjs(data?.result?.inTime, "hh:mm A")||null,
                      outTime:dayjs(data?.result?.outTime, "hh:mm A")||null,
                  })} else {
                    message.error(data?.message);
                  }
                  return data?.result
        }),
        enabled: batchId!== undefined && batchId!== '' && batchId!== null
    },
)
  // const getBatchById = async (id: any) => {
  //   setLoading(true);
  //   await api
  //     .GET(BatchMaster.endPoints.getById + "/" + id + "?", {
  //       masterType: MasterTypes.Batch,
  //     })
  //     .then((data: IApiResponse) => {
  //       if (data?.errors === null && data?.result) {
  //         let res = data?.result;
  //         // getCourses(res?.department?.id);
  //         setCurrentSchema(res?.currentSchema);
  //         form.setFieldsValue({
  //           name: res?.name,
  //           batchCode: res?.batchCode,
  //           sessionCourse: {
  //             label: res?.sessionCourse?.name,
  //             value: res?.sessionCourse?.id,
  //           },
  //           session: {
  //             label: res?.session?.name,
  //             value: res?.session?.id,
  //           },
  //           semester: {
  //             label: res?.semester?.name,
  //             value: res?.semester?.id,
  //           },
  //           // inTime: res?.inTime ? dayjs(res?.inTime, "hh:mm A") : undefined,
  //           // outTime: res?.outTime ? dayjs(res?.outTime, "hh:mm A") : undefined,
  //           // department: {
  //           //   value: res?.department?.id,
  //           //   label: res?.department?.name,
  //           // },
  //           // course: {
  //           //   value: res?.course?.id,
  //           //   label: res?.course?.name,
  //           // },
  //           // currentSchema: res?.currentSchema?.name,
  //         });
  //         // setBatchDays({
  //         //   sun: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 1 && d?.isChecked;
  //         //   }),
  //         //   mon: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 2 && d?.isChecked;
  //         //   }),
  //         //   tue: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 3 && d?.isChecked;
  //         //   }),
  //         //   wed: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 4 && d?.isChecked;
  //         //   }),
  //         //   thu: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 5 && d?.isChecked;
  //         //   }),
  //         //   fri: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 6 && d?.isChecked;
  //         //   }),
  //         //   sat: res?.batchDays?.some((d: any) => {
  //         //     return d?.day === 7 && d?.isChecked;
  //         //   }),
  //         // });
  //         setLoading(false);
  //       } else {
  //         message.error(data?.message);
  //         setLoading(false);
  //       }
  //     });
  // };
  // const getDepartments = async (val: string = "") => {
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_MASTER_SEARCH + "?",
  //     {
  //       type: MasterTypes.Department,
  //       start: 0,
  //       length: 15,
  //       search: val,
  //     }
  //   );
  //   if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
  //     const result = res?.result?.items;
  //     // console.log("result", result);
  //     setDepartmentData(
  //       result?.map((x: any) => ({ label: x?.name, value: x?.id }))
  //     );
  //   } else {
  //     message.error(res?.message);
  //   }
  // };
  // const getCourses = async (departmentId: any) => {
  //   setDepartmentId(departmentId);
  //   setCourseData([]);
  //   setCurrentSchema(null);
  //   form.resetFields(["course"]);
  //   form.resetFields(["currentSchema"]);
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_COURSE_BY_DEPARTMENT + "?",
  //     {
  //       departmentId,
  //     }
  //   );
  //   if (/*res?.errors === null &&*/ res?.result?.length >= 0) {
  //     const result = res?.result;
  //     setCourseData(
  //       result?.map((x: any) => ({ label: x?.name, value: x?.id }))
  //     );
  //   } else {
  //     message.error(res?.message);
  //   }
  // };
  // const getCourseSchema = async (departmentId: any, courseId: any) => {
  //   form.resetFields(["currentSchema"]);
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_CURRENT_SCHEMA_BY_COURSE + "?",
  //     {
  //       departmentId,
  //       courseId,
  //     }
  //   );
  //   if (/*res?.errors === null &&*/ res?.result) {
  //     setCurrentSchema(res?.result?.schema);
  //     form.setFieldValue("currentSchema", res?.result?.schema?.name);
  //   } else {
  //     message.error(res?.message);
  //   }
  // };
  const onClose = () => {
    // setDepartmentId(departmentId);
    // setCourseData([]);
    // setCurrentSchema(null);
    SetDrawerClose(false);
    setBatchId(null)
    form.resetFields();
  };

//    const Mutation = useMutation({
//     mutationFn: async () => {
//       form.validateFields().then(async (val) => {
//         console.log(val)
//         let batchDetail = {
//           masterType : MasterTypes.Batch,
//           ...val,
//           department: {
//             id: val?.department?.value,
//             name: val?.department?.label,
//           },
//           course: {
//             id: val?.course?.value,
//             name: val?.course?.label,
//           },
//           schema: {
//             id: val?.schema?.value,
//             name: val?.schema?.label,
//           },
//         }
//         console.log(batchDetail)
//         try {
//           // HTTPSCalls.POST(
//           //   HTTPSCalls.ENDPOINTS.POST_MASTER,
//           //   {},
//           //   {
//           //     ...batchDetail,
//           //   }
//           // )
//           console.log(batchDetail,'BatchValues')
//         } catch (error:any) {
//           console.log('error',error);
//         }
//       });
//     },
//    onSuccess(res, variables, context) {
//     onClose();
//     openNotification(
//       "success",
//       batchId ? "Batch updated successfully" : "Batch saved successfully"
//     );
//     setTrigger((x: boolean) => !x);
//    },
//    onError(error, variables, context) {
//     openNotification("error", error);
//    },
// })

  const saveUpdateBatchDetail = () => {
    let weekdays = [
      { day: 1, isChecked: batchDays.sun },
      { day: 2, isChecked: batchDays.mon },
      { day: 3, isChecked: batchDays.tue },
      { day: 4, isChecked: batchDays.wed },
      { day: 5, isChecked: batchDays.thu },
      { day: 6, isChecked: batchDays.fri },
      { day: 7, isChecked: batchDays.sat },
    ];
    let checkDays = weekdays.some((item: any) => item.isChecked === true);
    if (!checkDays) {
      openNotification("error", "Please select batch days");
      form.validateFields();
    } else {
    form.validateFields().then(async (val) => {
      setBtnLoading(true);
      let batchDetail = {
        masterType : MasterTypes.Batch,
        ...(batchId && { id: batchId }),
        ...val,
        inTime: val?.inTime?.format("hh:mm A"),
        outTime: val?.outTime?.format("hh:mm A"),
        batchDays:weekdays,
        department: {
          id: val?.department?.value,
          name: val?.department?.label,
        },
        course: {
          id: val?.course?.value,
          name: val?.course?.label,
        },
        schema: {
          id: val?.schema?.value,
          name: val?.schema?.label,
        },
      }

      console.log(batchDetail)
      
      HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.POST_MASTER,
        {},
        {
          ...batchDetail,
        }
      ).then((res: IApiResponse) => {
        console.log("Post res = ", res);
        if (res?.status ) {
          onClose();
          openNotification(
            "success",
            batchId ? "Batch updated successfully" : "Batch saved successfully"
          );
          setTrigger((x: boolean) => !x);
        } else {
          openNotification("error", res.message);
        }
        setBtnLoading(false);
      });
      console.log(batchDetail,'BatchValues')
    })};
  };

  // useEffect(() => {
  //   if (courseId) {
  //     getSessionList(courseId);
  //   }
  // }, [courseId]);

  // const getSessionList = async (courseId: any) => {
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_SESSION_LIST + "?",
  //     { courseId }
  //   );
  //   //console.log(res, "SemesterList");
  //   let itemsSession = res?.result?.map((itm: any) => {
  //     return {
  //       value: itm?.id,
  //       label: itm?.name,
  //     };
  //   });
  //   //console.log(itemsSession, "testSessionData");
  //   setSessionData(itemsSession);
  // };

  // useEffect(() => {
  //   if (sessionId) {
  //     getSemesterList(sessionId);
  //   }
  // }, [sessionId]);

  // const getSemesterList = async (sessionId: any) => {
  //   let res = await HTTPSCalls.GET(
  //     HTTPSCalls.ENDPOINTS.GET_SEMESTER_LIST + "?",
  //     { sessionId } 

  //   );
  //   console.log(res, "SemesterList");
  //   let itemsSemester = res?.result?.map((itm: any) => {
  //     return ({
  //       value: itm?.id,
  //       label: itm?.name,
  //     });
  //   });
  //   //console.log(itemsSession, "testSessionData");
  //   setSemesterData(itemsSemester);
  // };

  useEffect(() => {
    if (currentSchema !== undefined && currentSchema?.length !== 0) {
      form.setFieldsValue({
        schema: currentSchema[0],
      });
    }
  }, [currentSchema]); 

  useEffect(() => {
    if (batchId && batchIdDetail?.department?.id !== null) {
      setDepartmentId(batchIdDetail?.department?.id);
    }
  }, [batchId, batchIdDetail]);


  //  useEffect(() => {
  //   if (currentSchema!==undefined) {
  //     form.setFieldsValue({
  //       schema: currentSchema[0],
  //     })
  //   }
  // }, [currentSchema])
  
  return (
    <Drawer
      title={props?.batchId ? "Edit Batch" : "Add Batch"}
      placement="right"
      width={600}
      maskClosable={false}
      onClose={onClose}
      closeIcon={false}
      open={openDrawer}
      destroyOnClose={true}
      extra={
        <CloseOutlined
          style={{ fontSize: "16px" }}
          className="cusror"
          onClick={onClose}
        />
      }
      footer={
        <Row justify="end">
          <Button
            onClick={onClose}
            disabled={btnLoading}
            className="ttp-btn-light btn-m-sm"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="btn-m-sm"
            loading={btnLoading}
            onClick={saveUpdateBatchDetail}
            // onClick={()=>Mutation.mutate()}
          >
            {props?.batchId ? "Update" : "Save"}
          </Button>
        </Row>
      }
    >
      <Spin
        spinning={masterLoading || batchIdLoading}
        indicator={<LoadingOutlined />}
        style={{ height: "100%" }}
      >
        <Form
          form={form}
          name="batch-form"
          className="DrawerPadding"
          colon={false}
          autoComplete="off"
          style={{ padding: 16 }}
          {...formItemLayout}
          labelAlign="left"
          requiredMark={false}
          // initialValues={{ schema: currentSchema }}
        >
          <Form.Item
            label="Department"
            name="department"
            rules={[{ required: true, message: "Please select Department!" }]}
          >
            <Select
              placeholder="Choose Department"
              optionFilterProp="children"
              labelInValue={true}
              // labelInValue
              allowClear
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={masterDetail?.departments}
              // options={masterDetail?.departments?.map((item:any) => ({
              //   value: item.value,
              //   label: item.name,
              // }))}
              onSelect={(e: any) => {
                setDepartmentId(e.value)
                form.resetFields(['course', 'schema']);
              }}
              onClear={() => {
                form.resetFields();
                setDepartmentId("string")
                setCourseId("string")
                
              }}
            />
          </Form.Item>
          <Form.Item
            label="Course"
            name="course"
            rules={[{ required: true, message: "Please select Course!" }]}
          >
            <Select
              placeholder="Choose Course"
              optionFilterProp="children"
              labelInValue={true}
              allowClear
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>  
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={masterDetail?.courses}
              // options={masterDetail?.courses?.map((item:any) => ({
              //   value: item.value,
              //   label: item.name,
              // }))}
              onSelect={(e: any) => {
                setCourseId(e.value)
                // setSessionId(e.value);
              }}
              onClear={() => {
                setCourseId("string")
              }}
            />
          </Form.Item>
          
          <Form.Item
            label="Schema"
            name="schema"
            rules={[{ required: true, message: "Please select Semester!" }]}
          >
            <Select
              placeholder="Choose Schema"
              optionFilterProp="children"
              labelInValue={true}
              // disabled={true}
              allowClear
              popupMatchSelectWidth={true}
              showSearch
              filterOption={(input, option) =>
                (option?.label?.toString() ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={masterDetail?.schemas}
              
              // defaultValue={currentSchema}
            />
          </Form.Item>
          <Form.Item
            label="Batch Name"
            name="name"
            rules={[{ required: true, message: "Please enter Batch Name!" }]}
          >
            <Input
              placeholder="Enter Batch Name"
              size="middle"
              onInput={(e: any) =>
                (e.target.value =
                  e.target.value.length > 1
                    ? e.target.value
                    : e.target.value.toUpperCase())
              }
            />
          </Form.Item>
          <Form.Item
            label="Batch Code"
            name="code"
            rules={[{ required: true, message: "Please enter Batch Code!" }]}
          >
            <Input
              placeholder="Enter Batch Code"
              size="middle"
              onInput={(e: any) =>
                (e.target.value =
                  e.target.value.length > 1
                    ? e.target.value
                    : e.target.value.toUpperCase())
              }
            />
          </Form.Item>
         
          <Form.Item
            label="In Time"
            name="inTime"
            rules={[{ required: true, message: "Please select time!" }]}
          >
            <TimePicker format="h:mm:A" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Out Time"
            name="outTime"
            rules={[{ required: true, message: "Please select time!" }]}
          >
            <TimePicker format="h:mm:A" style={{ width: "100%" }} />
          </Form.Item>
{/*
          <Form.Item
            label="Department"
            name="department"
            // wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "Department is required!" }]}
          >
            <Select
              placeholder="Select Department"
              labelInValue
              onSelect={(val: any) => {
                setDepartmentId(val?.value)
                getCourses(val?.value)
              }}
              // filterOption={(value: any) => {
              //   // console.log("value = ", value)
              //   let r: any = [];
              //   if (value) {
              //     clearTimeout(timeout);
              //     timeout = setTimeout(async () => {
              //       r = await handleSearch(value, MasterTypes.Department);
              //     }, 350);
              //     // console.log("r =  ", r)
              //     return r;
              //   }
              // }}
              // showSearch
              // allowClear
              // onClear={async () => {
              //   await getDepartments();
              // }}
              options={departmentData}
            />
          </Form.Item>
          <Form.Item
            label="Course"
            name="course"
            // wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "Course is required!" }]}
          >
            <Select
              placeholder="Select Course"
              labelInValue
              onSelect={(val: any) => {
                if (departmentId)
                  getCourseSchema(departmentId, val?.value)
              }}
              // filterOption={(value: any) => {
              //   // console.log("value = ", value)
              //   let r: any = [];
              //   if (value) {
              //     clearTimeout(timeout);
              //     timeout = setTimeout(async () => {
              //       r = await handleSearch(value, MasterTypes.Course);
              //     }, 350);
              //     // console.log("r =  ", r)
              //     return r;
              //   }
              // }}
              // showSearch
              // allowClear
              // onClear={async () => {
              //   await getCourses();
              // }}
              options={courseData}
            />
          </Form.Item>
          <Form.Item
            label="Schema"
            name="currentSchema"
            // wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "Schema is required!" }]}
          >
            <Input placeholder="Schema" readOnly />
          </Form.Item> */}

          <Row className="mb-3">
            <Col className="fw500">Batch Days</Col>
            <Col span={24} className="mt-1">
              <Table
                bordered={true}
                columns={columnsNonBatch}
                pagination={{ hideOnSinglePage: true }}
                dataSource={batchDaysData}
                size={"small"}
                scroll={{ x: 400 }}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </Drawer>
  );
};
export default AddEditBatch;




import { DatePicker, Form, FormInstance, Input, Select } from "antd";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import { MasterTypes } from "../../../Constant/Enums";
const { RangePicker } = DatePicker;
interface IAddEditSchema {
  data: any;
  form: FormInstance<any>;
}
const AddEditSchema = ({ data, form }: IAddEditSchema) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  useEffect(() => {
    if (!!data) form.setFieldsValue({ ...data });
  }, []);
  return (
    <Form
      form={form}
      name="department-form"
      className="DrawerPadding"
      colon={false}
      autoComplete="off"
      style={{ padding: 16 }}
      labelAlign="left"
      requiredMark={false}
      {...formItemLayout}
    >
      <Form.Item
        label="Department"
        name="department"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Department"} disabled />
      </Form.Item>
      <Form.Item
        label="Course"
        name="course"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Course"} disabled />
      </Form.Item>
      <Form.Item
        label="Duration"
        name="duration"
        rules={[{ required: true, message: "field is required" }]}
      >
        <RangePicker
          onChange={(val: any) => {
            console.log(val);
            
            if (val) {
              // form.setFieldValue(
              //   "name",
              //   `Schema ${val[0]?.format("YYYY")}-${val[1]?.format("YYYY")}`
              // );
            } else {
              // form.setFieldValue("name", "");
            }
          }}
          size="middle"
          style={{ width: "100%" }}
          format="YYYY-MM-DD"
        />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Input
          autoFocus
          placeholder="Select course duration"
          size="middle"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.length > 1
                ? e.target.value
                : e.target.value.toUpperCase())
          }
        />
      </Form.Item>
      <Form.Item
        label="CGPA"
        name="cgpa"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Input
          autoFocus
          placeholder="Enter CGPA"
          size="middle"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.length > 1
                ? e.target.value
                : e.target.value.toUpperCase())
          }
        />
      </Form.Item>
      <Form.Item
        label="Credit"
        name="credit"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Input
          autoFocus
          placeholder="Enter Credit"
          size="middle"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.length > 1
                ? e.target.value
                : e.target.value.toUpperCase())
          }
        />
      </Form.Item>
    </Form>
  );
};

export default AddEditSchema;

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table } from "antd";
import SubjectAllocationAdd from "./SubjectAllocationAdd";
import { ColumnsType } from "antd/es/table";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { pageSizeOption } from "../../../component/Users/UserList";
import Search from "antd/es/input/Search";


const SubjectAllocationList: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [subjectAllocation, setSubjectAllocation] = useState<any>("");
  const [sendRecord, setSendRecord] = useState<any>({});
  const [trigger, setTrigger] = useState<boolean>(false);
  const [pagingItems, setPagingItems] = useState<{
          totalRecords: number;
          currentPage: number;
          pageSize: number;
  }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [loading, setLoading] = useState<boolean>(false);
  const [searchVal,setSearchVal]=useState<string>("");
  const [listParams,setListParams]=useState({
    start:0,
    length:15,
    search:"",
  })
  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "0.6%",
      key: "sno",
      render: (text: any, record: any, index: any) => <p>{(pagingItems.currentPage - 1) * pagingItems.pageSize + index + 1}</p>,
    },
    {
      title: "Departments",
      dataIndex: "department",
      key: "department",
      width: "2%",
      render: (department: any, record: any) => <>{department?.name}</>,
    },
    {
      title: "Teachers",
      dataIndex: "teacher",
      key: "teacher",
      width: "2%",
      render: (teacher: any, record: any) => <>{teacher?.name}</>,
    },
    {
      title: "Course",
      dataIndex: "course",
      key: "course",
      width: "2%",
      render: (course: any, record: any) => <>{course?.name}</>,
    },
    {
      title: "Semesters",
      dataIndex: "semester",
      key: "semester",
      width: "2%",
      render: (semester: any, record: any) => <>{semester?.name}</>,
    },
    {
      title: "Sections",
      dataIndex: "section",
      key: "section",
      width: "2%",
      render: (section: any, record: any) => <>{section?.name}</>,
    },
    {
      title: "Subjects",
      dataIndex: "subjects",
      key: "subjects",
      width: "2%",
      ellipsis: true,
      render: (subjects: any, record: any) => <>{subjects?.name}</>,
    },
    {
      title: "Edit",
      dataIndex: "",
      key: "",
      width: "0.5%",
      render: (_: any, record: any) => (
        <>
          <FormOutlined
            onClick={() => {
              setSendRecord(record);
              setOpenDrawer(true);
            }}
            style={{ cursor: "pointer" }}
          />
        </>
      ),
    },
  ];
  const changeListParams = (key: string, value: any) => {
    setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
    setListParams((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const getSubAllocationList = async () => {
    setLoading(true);
    try {
      const response = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.SUBJECT_ALLOCATION_GETLIST + "?",
        {
          start:(pagingItems?.currentPage-1)*(pagingItems?.pageSize),
          length:pagingItems?.pageSize,
          search:searchVal
        }
      );
      if (response?.result) {
        let datasource: any = [];

        response?.result?.items?.map((item: any) =>
          item?.details?.map((innerItem: any) => {
            datasource.push({ ...item, ...innerItem });
          })
        );
        setSubjectAllocation(datasource);
        setPagingItems((prev) => ({
          ...prev,
          totalRecords: response?.totalRecords || datasource.length, 
        }));
      }
    } catch (error) {
      console.error("Failed to fetch leave types:", error);
    } finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubAllocationList();
  }, [trigger,pagingItems.currentPage, pagingItems.pageSize,searchVal]);

  return (
    <>
      <Card>
        <Row>
          <Col span={21}>
            <Button
              type="primary"
              style={{ fontWeight: 500 }}
              onClick={() => {
                setSendRecord({});
                setOpenDrawer(true);
              }}
            >
              <PlusOutlined /> Subject Allocation
            </Button>
          </Col>
          <Col span={3}>
            <Search placeholder="Search..."
             onSearch={(val: string) => {
                  setSearchVal(val);
                }}  allowClear style={{width:"100%"}} />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col span={24}>
            <Table
              loading={loading}
              columns={columns}
              dataSource={subjectAllocation}
              pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                selectPrefixCls: "custom-table-select",
                current: pagingItems.currentPage,
                pageSize: pagingItems.pageSize,
                showSizeChanger: true,
                total: pagingItems.totalRecords,
                pageSizeOptions: pageSizeOption,
              }}
              onChange={(paging, filter, sort: any) => {
                sort?.order &&
                  setListParams({
                    ...listParams,
                    // sortDir: sort?.order,
                    // sortCol: sort?.columnKey,
                  });
                  setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
                  setListParams((prevState) => ({
                    ...prevState,
                    // [key]: value,
                  }
                ))
                  ;
                paging &&
                  setPagingItems({
                    ...pagingItems,
                    currentPage: paging?.current ?? 1,
                    pageSize: paging?.pageSize ?? 15,
                  });
              }}
            ></Table>
          </Col>
        </Row>
      </Card>

      {openDrawer && (
        <>
          <SubjectAllocationAdd
            sendRecord={sendRecord}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            setTrigger={setTrigger}
          />
        </>
      )}
    </>
  );
};

export default SubjectAllocationList;

import { BellOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Form, Input, message, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { IApiResponse } from "../../Utilities/ApiUtility.axios";

interface IDrawerPorps {
  open: boolean;
  setOpen: any;
  setTrigger: any;
  recordData: any;
  trigger: any;
}
const AddAppVersion = ({
  open,
  setOpen,
  setTrigger,
  trigger,
  recordData,
}: IDrawerPorps) => {
  const [form] = useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const onsubmit = async () => {
    form.validateFields().then(async (values: any) => {
      setLoading(true);
      let appVersions = {
        androidVersion: values?.androidVersion,
        iosVersion: values?.iosVersion,
      };
      
      HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.SAVE_APP_VERSION, {}, appVersions).then(
        (res: IApiResponse) => {
          if (res?.status) {
            setOpen(false);
            form.resetFields();
            message.success("Updated successfully");
            setTrigger((prev: boolean) => !prev);
          } else {
            message.warning(res?.message);
          }
          setLoading(false);
        }
      );
    });
  };
  useEffect(() => {
    if (recordData?.id) {
      form.setFieldsValue({
        androidVersion:recordData?.androidVersion,
        iosVersion:recordData?.iosVersion
      });
    }
  }, [open, recordData, form]);
  return (
    <>
      <Drawer
        open={open}
        onClose={() => !open}
        title={
          <>
            {recordData?.id ? "Edit Version" : "Add Version"} <BellOutlined />{" "}
          </>
        }
        footer={
          <Row gutter={12} justify={"end"}>
            <Col>
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => onsubmit()}
                loading={loading}
              >
                Save
              </Button>
            </Col>
          </Row>
        }
      >
        <Form form={form} layout="vertical">
          <Row className="p-4">
            <Col span={24}>
              <Form.Item
                name={"androidVersion"}
                label="Android Version"
                rules={[{ required: true, message: "Please input Android Version" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name={"iosVersion"}
                label="Ios Version"
                rules={[{ required: true, message: "Please input Ios Version" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AddAppVersion;

import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
  TabsProps,
} from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType, TableProps } from "antd/es/table";
import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { ActionBioTypeEnum, BioDeviceOptions } from "../../../Constant/Enums";
import { TableRowSelection } from "antd/es/table/interface";

export const pageSizeOption: number[] = [10, 15, 30, 50, 100, 200];

interface IUserTable {
  machineTitle: string;
  machineNo: string;
}

export const ManageAssignedStudents = (props: { machineCode: string, deviceRecord:any }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modal, contextHolder] = Modal.useModal();
  const [refrest,setRefrest]=useState(false);
  const [search,setSearch]=useState<any>("");
  const [changeOption,setChangeOption]=useState<any>(0);
  const navigate = useNavigate();
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    // console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;
  const AssignDevice = async (actionType:ActionBioTypeEnum) => {
    setLoading(true);
    try {
      const selectedStudentIds= selectedRowKeys.map((key:any)=>({id:key}));
      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.SAVE_BIOMETRIC_CHANGE,
        {},
        {
          machineTitle: props?.deviceRecord?.machineTitle,
          machineCode: props?.machineCode,
          studentIds: selectedStudentIds,
          actionBioType: actionType,
        }
      );
      if (res?.result) {
        setRefrest((pre)=>!pre)
        message.success("Device assigned successfully.");
      } else {
        message.error("Failed to assign device. Please try again.");
      }
    } catch (error) {
      console.error("Error assigning device:", error);
      message.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false)
    }
  };
  const columns: ColumnsType<IUserTable> = [
    {
      title: "Student ID",
      dataIndex: "studentId",
      key: "studentId",
      //   width: "4%",
      render: (text, record) => <>{text}</>,
    },
    {
      title: "Name",
      //   width: "14%",
      dataIndex: "fullName",
      key: "fullName",
      ellipsis: true,
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      ellipsis: true,
      render: (text, record: any) => <p>{record?.batch?.name}</p>,
    },
    {
      title: "Profile Sync",
      dataIndex: "batch",
      key: "batch",
      // width:"8%",
      ellipsis: true,
      render: (text, record: any) => <><Checkbox checked disabled/></>,
    },
    {
      title: "Att. Sync",
      dataIndex: "isAttSync",
      key: "isAttSync",
      // width:"8%",
      ellipsis: true,
      render: (text, record: any, index: number) => {
        const matchMachineCode = record?.ecard?.bioMachines?.find((innerItem: any) => props?.machineCode === innerItem?.machineNo);
        return <Checkbox checked={matchMachineCode?.isAttSync !== undefined ? true : false} disabled></Checkbox>;
      },
    }
  ];
  useEffect(() => {
    getList();
  }, [refrest,changeOption]);

  const getList = async () => {
    // setIsTable(true)
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.STUDENT_BIO_DEVICE_ASSIGN + "?",
        {
           machineCode: props?.machineCode,search,
           bioDeviceOptions:changeOption
        }
      );
      if (res?.result) {
        setDataSource(res?.result);
      }
    } catch (error) {
      console.log("errror", error);
    } finally {
      setLoading(false);
    }
  };
  const confirm = (actionType: ActionBioTypeEnum) => {
    modal.confirm({
      title: "Confirmation",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to Assign access only for selected students?",
      okText: "Yes",
      cancelText: "No",
      onOk:()=> AssignDevice(actionType)
    });
  };

  const handleSearch=(e:any)=>{
    setSearch(e)
    setRefrest((pre:any)=>!pre)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row gutter={18}>
            <Col lg={18}></Col>
            <Col lg={3}><Select style={{width:"100%"}}  optionFilterProp="label" value={changeOption}  options={BioDeviceOptions} onChange={(val:any)=>{setChangeOption(val)}}/></Col>
            <Col lg={3}>
              <Search
                onSearch={handleSearch}
                placeholder="Search..."
                allowClear
                className="att-search-input mb-1"

                // onSearch={(val: string) => changeListParams("nameSearch", val)}
                // onChange={(e: any) =>
                //   e.target.value === "" ? setListParamsAndRefresh("") : null
                // }
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row>

           <Col span={24} style={{ paddingTop: "12px" }}>
             <Table
               rowKey={(record) => record?.id}
               rowSelection={rowSelection}
               size="small"
               dataSource={dataSource}
               loading={loading}
               columns={columns}
               scroll={{ x: 1100, y: "65vh" }}
               pagination={false}
              />
           </Col>
              </Row>
          <Row justify={"end"} className="pt-3" gutter={15}>
            <Col>
              {hasSelected
                ? `Selected ${selectedRowKeys.length} students`
                : null}
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => confirm(ActionBioTypeEnum.RemoveAccess)}
                disabled={!hasSelected}
              >
                Remove Access
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => confirm(ActionBioTypeEnum.EnableAttSync)}
                disabled={!hasSelected}
              >
                Enable Attendance sync
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => confirm(ActionBioTypeEnum.DisableAttSync)}
                disabled={!hasSelected}
              >
                Disable Attendance sync
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default ManageAssignedStudents;

import * as React from "react";
import { useContext, useState, useEffect } from "react";
import MainLayout from "./Common/MainLayout";
import ApplicationState from "./Context/ApplicationState";
import TestLayout from "./Common/TestLayout";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import CallDetails from "./component/Call/CallDetails";
import { AccessCodes, IAccessCodes } from "./Common/Sidemenus";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userData as UD } from "./Store/Slice/userSlice";

export const queryClient = new QueryClient({
  defaultOptions:{
    queries:{
      staleTime:1000*6*1
    }
  }
});
const App = () => {
  // const userData = useSelector(UD)
  // const [rights, setRights] = useState<any>(null);
  // const navigate = useNavigate();  
  // const location = useLocation();  

  // useEffect(() => {
  //   if (userData?.rights) {
  //     setRights(userData?.rights);
  //   } else {
  //     setRights(null)
  //   }
  // }, [userData,userData?.rights]); 

  return (
    <QueryClientProvider client={queryClient}>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      {/* {rights?.enquiries === AccessCodes.enquiries &&<IncomingCall/>} */}
      {/* {rights?.enquiries === AccessCodes.enquiries &&<CallDetails/>} */}
      <MainLayout />
    </QueryClientProvider>
    // <React.Fragment>
    /* <TestLayout /> */
    /* </React.Fragment> */
  );
};

export default App;

import { ArrowLeftOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Row, Space, Table } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import HTTPSCalls from "../../../Services/HTTPCalls";

export const pageSizeOption: number[] = [10, 15, 30, 50, 100, 200];

interface IUserTable {
  machineTitle: string;
  machineNo: string;
}

export const BioMetricDeviceList = () => {
  const [dataSource, setDataSource] = useState<IUserTable[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const columns: ColumnsType<IUserTable> = [
    {
      title: "Machine Title",
      dataIndex: "machineTitle",
      key: "machineTitle",
        width: "8%",
      render: (text, record) => (
        <span
          onClick={() =>
            navigate("/settings/biometric-device/" + record?.machineNo,{
              state:record
            })
          }
          className="hoverUnderline"
          style={{ cursor: "pointer", color: "#4096FF" }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Machine No",
        width: "8%",
      dataIndex: "machineNo",
      key: "machineNo",
      ellipsis: true,
      render: (text) => <p>{text}</p>,
    },
    {
      title: " ",
        width: "5%",
      dataIndex: "edit",
      align: "right",
      render: (_, record) => (
        <>  
        <Button size="small" >
          Resync
        </Button>
        </>
      ),
    },
  ];
  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    setLoading(true);
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_BIOMETRIC_DEVICE_LIST + "?",
        {}
      );
      if(res?.result){
        setDataSource(res?.result);
       }
    } catch (error) {
      console.log("errror", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Row
        justify={"space-between"}
        align="middle"
        style={{ marginBottom: "6px" }}
      >
        <ArrowLeftOutlined
          style={{ color: "#1677ff" }}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Biometric Devices",
            },
          ]}
        ></Breadcrumb>
      </Row>

      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row>
            <Col lg={21}></Col>
            <Col lg={3}>
              <Search
                size="middle"
                placeholder="Search Name..."
                allowClear
                className="att-search-input mb-1"
                // onSearch={(val: string) => changeListParams("nameSearch", val)}
                // onChange={(e: any) =>
                //   e.target.value === "" ? setListParamsAndRefresh("") : null
                // }
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Col span={24} style={{ paddingTop: "12px" }}>
            <Table
              rowKey={(record) => record.machineNo}
              size="small"
              dataSource={dataSource}
              loading={loading}
              columns={columns}
              scroll={{ x: 1100, y: "65vh" }}
              pagination={false}
            />
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default BioMetricDeviceList;

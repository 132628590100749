import * as React from 'react';
import { useEffect, useState } from 'react';
import { Row, Button, Form, Select, Input, Spin, Card, Col, Checkbox, Tooltip } from 'antd';
import Modal from 'antd/es/modal/Modal';

import { CheckOutlined, CloseOutlined, DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import HTTPSCalls from '../../Services/HTTPCalls';
import { IdName } from '../../Types';
import { adminContext } from '../../Common/PageRoute';
const { Option } = Select;

const CardModel: React.FC<{  student:IdName|undefined, modalOpen: boolean, setModalOpen: Function,setShouldRefresh?:Function }> = (props) => {

    interface IBioMachines {
        machineTitle: string
        machineNo: string
    }
      const { openNotification } = React.useContext(adminContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [bioMachines, setBioMachines] = useState<IBioMachines[]>([]);
    const [selectedItems, setSelectedItems] = useState<string[]>([]);

    useEffect(() => {
        if (props?.modalOpen) {
            setLoading(true);
            Promise.all([
                getDeviceList(),
            ]).then(() => setLoading(false));
        }
    }, [props.modalOpen]);


    const getDeviceList = async () => {
        await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_BIOMATRIC_DEVICE_LIST + "?",
          {}
        ).then((val) => {
            setBioMachines(val?.result);
        });
      };
      useEffect(() => {
        getDeviceList();
        
      }, []);
      
    const saveCard = () => {
        form.validateFields().then(async (values:any) => {
            await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.ENQUIRY_ASSIGNCARD+"?", {studentId: props?.student?.id}, {...values}).then((res) => {
                if (res.status) {
                    onClose();
                    props?.setShouldRefresh && props?.setShouldRefresh((pre:any)=>!pre);
                    openNotification("success","Card Assign SuccessFully")
                }
                else {
                    openNotification("error",res?.message)
                }
            })
        }).catch(() => { return false })
    };

    const onClose = () => {
        form.resetFields();
        props?.setModalOpen(false);
        setSelectedItems([]);
    };

    return (

        <Modal
            title={"Add Card"}
            width="600px"
            destroyOnClose
            onCancel={onClose}
            cancelText="Cancel"
            open={props?.modalOpen}
            footer={
                <Row justify="end">
                    <Button onClick={onClose} className="ttp-btn-light btn-sm">Cancel</Button>
                    <Button type="primary" className="btn-sm" onClick={saveCard}>Save</Button>
                </Row>
            }
        >
            <Form
                form={form}
                name="id-card-form"
                labelCol={{ xs: 10, sm: 9 }}
                wrapperCol={{ xs: 14, sm: 15 }}
                colon={false}
                autoComplete="off"
                requiredMark={false}
            >
                <Spin spinning={loading}>
                    <Card style={{ borderRadius: 4 }}>

                        <Form.Item label="Card No." name="cardNo" labelAlign="left" className="form-item-input" 
                       rules ={[{ required: true, message: "This Field is required" },{
                            pattern: new RegExp(/^[0-9]/i),
                            message: "Invalid number!",
                          },]} >
                            <Input placeholder="Enter Card No."  />
                        </Form.Item>

                        <Form.List name="bioMachines" initialValue={[{ machineNo: undefined, isAttSync: true }]}>
                            {(fields, { add, remove }) => {
                                return (
                                    <>
                                        {fields.map((field, index) => (
                                            <Row key={index}>
                                                <Col span={9}>
                                                    {index === 0 ? "Biometric Machines" : " "}
                                                </Col>
                                                <Col span={15}>
                                                    <Row justify="space-between">
                                                        <Col span={12} className="pe-2">
                                                            <Form.Item
                                                                labelAlign="left"
                                                                className="form-item-input"
                                                                wrapperCol={{ span: 24 }}
                                                                name={[field.name, 'machineNo']}
                                                                rules={[{ required: true, message: 'This field is required' }]}
                                                            >
                                                                <Select
                                                                    placeholder="Select Machine"
                                                                    allowClear={false}
                                                                    onChange={() =>
                                                                        setSelectedItems([...form.getFieldValue("bioMachines")?.map((itm: any) => itm?.machineNo)])
                                                                    }
                                                                >
                                                                    {bioMachines?.map((item, index) =>
                                                                        <Option
                                                                            value={item?.machineNo}
                                                                            key={index}
                                                                            disabled={selectedItems?.find((itm: string) => itm === item?.machineNo)}
                                                                        >
                                                                            {item?.machineTitle}
                                                                        </Option>
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={10}>
                                                            <Form.Item
                                                                labelAlign="left"
                                                                wrapperCol={{ span: 24 }}
                                                                className="form-item-input"
                                                                name={[field.name, 'isAttSync']}
                                                                initialValue={true}
                                                                valuePropName="checked"
                                                                rules={[{ required: true, message: 'This field is required' }]}
                                                            >
                                                                <Checkbox>Is Att. Sync
                                                                    <Tooltip color="#e6f4ff" placement="topLeft" overlayInnerStyle={{ fontSize: 12, color: "black" }}
                                                                        title={
                                                                            <Row align="middle">
                                                                                <Col span={24} className="fs12"> <CheckOutlined style={{ fontSize: 13, color: "green" }} className="me-2" />Tick to record attendance</Col>
                                                                                <Col span={24} className="fs12"> <CloseOutlined style={{ fontSize: 13, color: "red" }} className="me-2" />Untick to access control only</Col>
                                                                            </Row>
                                                                        }
                                                                    >
                                                                        <InfoCircleOutlined style={{ color: "#a5abdd" }} className="cursor ms-1" />
                                                                    </Tooltip>
                                                                </Checkbox>

                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={2}>
                                                            {fields?.length > 1 &&
                                                                <DeleteOutlined
                                                                    className="ttp-delete-btn m-0"
                                                                    title="Delete"
                                                                    style={{ color: 'red' }}
                                                                    onClick={() => {
                                                                    remove(field.name);
                                                                    setSelectedItems([...form.getFieldValue("bioMachines")?.map((itm: any) => itm?.machineNo)])
                                                                }}
                                                                />
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Form.Item label=" " labelAlign="left" className="form-item-input">
                                            <Button style={{ borderRadius: 5, color: '#1890FF', fontSize: 13 }} size='small' title="Add More Machine" onClick={() => add()}> + Machine </Button>
                                        </Form.Item>
                                    </>
                                );
                            }}
                        </Form.List>

                        <Form.Item label="Status" name="isCardDeActive" labelAlign="left" initialValue={false} rules={[{ required: true, message: "This Field is required" }]}>
                            <Select
                                placeholder="Select Status"
                            >
                                <Option value={false}>Active</Option>
                                <Option value={true}>Deactive</Option>
                            </Select>
                        </Form.Item>

                    </Card>
                </Spin>
            </Form>
        </Modal>

    );

}
export default CardModel;
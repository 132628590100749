import * as React from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Table,
  message,
  InputNumber,
} from "antd";
import type { TableColumnsType } from "antd";
import { MasterTypes } from "../../Constant/Enums";
import HTTPSCalls from "../../Services/HTTPCalls";

const CredentialsTable: React.FC<{
  type: number;
  apiData: any;
  onSubmit: (rec: boolean) => void;
  credentialRefresh: boolean;
}> = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  // const [attachmentList, setAttachmentList] = React.useState<any[]>([
  //   {
  //     fileName: {
  //       id: "",
  //       name: "",
  //       contentType: "",
  //       length: 0,
  //       path: "",
  //     },
  //   },
  // ]);

  // const getBase64 = (file: RcFile): Promise<string> =>
  //   new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result as string);
  //     reader.onerror = (error) => reject(error);
  //   });

  // const handelFile = async (file: any, index: any) => {
  //   // let prevAtt = [...attachmentList];

  //   let currentFileBase64 = await getBase64(file as RcFile);

  //   let fileInfo = {
  //     id: "",
  //     name: file?.name,
  //     contentType: file?.type,
  //     length: file?.size,
  //     path: currentFileBase64,
  //   };

  //   // prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

  //   setAttachmentList([fileInfo]);
  // };
  // const [refresh, setRefresh] = useState(false);
  const [credentialsType, setCredentialsType] = useState<any[]>([]);
  // const [listParams, setListParams] = useState<any>({
  //   filterId: "",
  //   nameSearch: "",
  //   batchSearch: "",
  //   sortCol: "",
  //   sortDir: "",
  //   userRoleId: "",
  //   start: 0,
  //   length: 10,
  // });
  // const changeListParams = (key: string, value: any) => {
  //   setListParams((p: any) => {
  //     p[key] = value;
  //     return p;
  //   });
  // };
  // const THeader = ({
  //   title,
  //   inputName,
  //   setShouldRefresh,
  //   changeListParams,
  //   ifSelect,
  //   listParams,
  //   setListParams,
  //   option,
  //   defaultValue,
  // }: any) => {
  //   return (
  //     <>
  //       <ConfigProvider
  //         theme={{
  //           components: {
  //             Input: {
  //               colorTextPlaceholder: "#1B1A1A",
  //               colorFillAlter: "transparent",
  //             },
  //             Select: {
  //               colorTextPlaceholder: "#1B1A1A",
  //             },
  //           },
  //         }}
  //       >
  //         <div
  //           className="searchDivStyle"
  //           onClick={(e) => {
  //             e.stopPropagation();
  //           }}
  //         >
  //           {ifSelect ? (
  //             <Select
  //               allowClear
  //               style={{ width: "100%" }}
  //               onSelect={(value) => {
  //                 changeListParams(inputName, value);
  //                 setListParams({ ...listParams, start: 0 });
  //                 setShouldRefresh((x: boolean) => !x);
  //               }}
  //               onClear={() => {
  //                 changeListParams(inputName);
  //                 setListParams({ ...listParams, start: 0 });
  //                 setShouldRefresh((x: boolean) => !x);
  //               }}
  //               popupMatchSelectWidth={false}
  //               variant="borderless"
  //               size="small"
  //               className="invSelectVendor"
  //               placeholder={title}
  //               options={option}
  //               // defaultValue={defaultValue}
  //             />
  //           ) : (
  //             <Search
  //               placeholder={title}
  //               className="CitSearchInput"
  //               allowClear
  //               onChange={(e) => changeListParams(inputName, e.target?.value)}
  //               onSearch={(val) => {
  //                 setListParams({ ...listParams, start: 0 });
  //                 setShouldRefresh((x: boolean) => !x);
  //               }}
  //               size="small"
  //               variant="borderless"
  //             />
  //           )}
  //         </div>
  //       </ConfigProvider>
  //     </>
  //   );
  // };

  // const items: MenuProps["items"] = [
  //   {
  //     label: "Download",
  //     key: "Download",
  //     icon: <DownloadOutlined />,
  //   },
  // ];
  // const handleMenuClick: MenuProps["onClick"] = (e) => {
  //   message.info("Click on menu item.");
  //   console.log("click", e);
  // };
  // const menuProps: any = {
  //   items,
  //   onClick: handleMenuClick,
  // };

  const columns: TableColumnsType<any> = [
    {
      title: "Credential",
      // <THeader
      //   title={"Credential"}
      //   changeListParams={changeListParams}
      //   // setShouldRefresh={setRefresh}
      //   listParams={listParams}
      //   setListParams={setListParams}
      //   ifSelect={true}
      //   option={credentialsType}
      // />
      dataIndex: "credentialName",
      key: "credentialName",
      width: "15%",
      render: (credentialName: any) => {
        return <>{credentialName?.name}</>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      render: (email: any) => {
        return <>{email}</>;
      },
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      width: "15%",
      render: (password: any) => {
        return (
          <>
            <Input.Password value={password} />
          </>
        );
      },
    },
    // {
    //   title: (
    //     <THeader
    //       title={"Upload By"}
    //       inputName={"enqId"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "uploadedBy",
    //   key: "",
    //   width: "15%",
    // },
    // {
    //   title: (
    //     <THeader
    //       title={"Assign To"}
    //       inputName={"enqId"}
    //       changeListParams={changeListParams}
    //       setShouldRefresh={setRefresh}
    //       listParams={listParams}
    //       setListParams={setListParams}
    //       ifSelect={false}
    //     />
    //   ),
    //   dataIndex: "",
    //   key: "",
    //   width: "20%",
    // },
    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
      width: "20%",
      render: (details: any) => {
        return <>{details}</>;
      },
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "20%",
      render: (number: any) => {
        return <>{number}</>;
      },
    },
    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "",
    //   width: "10%",
    //   render: (_: any, att: any) => {
    //     return (
    //       <>
    //         <Dropdown.Button
    //           size="small"
    //           type="link"
    //           icon={<DownOutlined />}
    //           trigger={["click"]}
    //           menu={menuProps}
    //           onClick={() => {
    //             window.open(att?.fileName?.path, "_blank");
    //           }}
    //         >
    //           Preview
    //         </Dropdown.Button>
    //       </>
    //     );
    //   },
    // },
  ];

  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });
      setCredentialsType(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Credential
        )?.[0]?.items
      );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };
  //console.log(counselorData, "testcou");

  React.useEffect(() => {
    getMaster();
  }, []);

  // useEffect(() => {
  //   console.log("credentials = ",props?.apiData?.cre)
  // }, [props?.credentialRefresh, props?.apiData])

  //   const onSubmit = () => {
  //     form
  //       .validateFields()
  //       .then(async (val: any) => {
  //         let formValues = {
  //           ...val,
  //         };
  //         setLoading(true);
  //         let response = await HTTPSCalls.POST(
  //           HTTPSCalls.ENDPOINTS.ENQUIRY_UPDATE_LIST + "?",
  //           {
  //             id: props?.apiData?.id,
  //             fieldName: "Attachments",
  //             valueType: "",
  //           },
  //           [formValues]
  //         );
  //         setLoading(false);
  //         if (response?.status) {
  //           message.success("Attachment Submitted");
  //           form.resetFields();
  //         } else {
  //           message.error(response?.message);
  //         }
  //       })
  //       .catch((err: any) => {
  //         console.log(err, "error");
  //       });
  //   };
  const onSubmit = () => {
    form.validateFields().then(async (val: any) => {
      let formVal = [
        {
          ...val,
          credentialName: {
            id: val?.credentialName?.value,
            name: val?.credentialName?.label,
          },
          email: val?.email,
          password: val?.password,
          number: val?.number,
          detail: val?.detail,
        },
      ];
      console.log("data = ", formVal);
      setLoading(true);
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.ENQUIRY_ADD_CREDENTIALS + "?",
        { id: props?.apiData?.id },
        formVal
      );
      setLoading(false);
      if (response?.status) {
        message.success("Attachment Submitted");
        form.resetFields();
        props?.onSubmit(true);
      } else {
        message.error(response?.message);
      }
    });
  };
  useEffect(() => {
    setTableData(
      props?.apiData?.credentials?.map((itm: any) => ({
        ...itm,
      }))
    );
    console.log("credentialData = ", props?.apiData?.credentials);
  }, [props?.apiData, props?.credentialRefresh]);
  return (
    <>
      <Form form={form} requiredMark={false}>
        <Row gutter={[5, 4]}>
          <Col lg={4} xl={4}>
            <Form.Item
              name={"credentialName"}
              rules={[
                {
                  required: true,
                  message: "Select Doc Type",
                },
              ]}
            >
              <Select
                placeholder="Select Doc Type"
                options={credentialsType}
                labelInValue
                allowClear
                popupMatchSelectWidth={true}
                filterOption={(input, option) =>
                  (option?.label?.toString() ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              />
            </Form.Item>
          </Col>
          <Col lg={6} xl={6}>
            <Form.Item
              name={"email"}
              rules={[
                {
                  type: "email",
                  message: "Please input valid email!",
                },
                {
                  validator: (_, value) => {
                    // Get values of the other fields
                    const password = form.getFieldValue("password");
                    const number = form.getFieldValue("number");
                    const detail = form.getFieldValue("detail");

                    // Check if at least one field is filled
                    if (value || password || number || detail) {
                      return Promise.resolve(); // Validation passed
                    }
                    return Promise.reject(
                      new Error(
                        "At least one field (Email, Password, Number, Detail) is required"
                      )
                    );
                  },
                },
                // {
                //   required: true,
                //   message: "this field is required",
                // },
              ]}
              //   {...restField}
              // rules={[

              // ]}
            >
              <Input placeholder="Enter Email" />
            </Form.Item>
          </Col>

          <Col lg={4} xl={4}>
            <Form.Item
              name={"password"}
              // rules={[
              //   {
              //     required: true,
              //     message: "field is required",
              //   },
              // ]}
            >
              <Input placeholder="Enter Password" />
            </Form.Item>
          </Col>
          <Col lg={4} xl={4}>
            <Form.Item
              name={"detail"}
              // rules={[
              //   {
              //     required: true,
              //     message: "field is required",
              //   },
              // ]}
            >
              <Input placeholder="Enter Detail" />
            </Form.Item>
          </Col>
          <Col lg={3} xl={3}>
            <Form.Item
              name={"number"}
              // rules={[
              //   {
              //     required: true,
              //     message: "field is required",
              //   },
              // ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                placeholder="Enter Number"
              />
            </Form.Item>
          </Col>
          <Col lg={2} xl={2}>
            <Button type="primary" onClick={onSubmit} loading={loading}>
              Add
            </Button>
          </Col>
        </Row>
      </Form>

      <Row style={{ paddingTop: "5px" }}>
        <Col lg={24}>
          <Table columns={columns} dataSource={tableData} />
        </Col>
      </Row>
    </>
  );
};

export default CredentialsTable;

import { EditOutlined, PlusOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Card, Col, Input, Row, Typography } from "antd";
// import Title from "antd/es/skeleton/Title";
import { useEffect, useState } from "react";
import CardModel from "../../pages/Students/CardModel";
interface courseDataInterface {
  apiData: any;
}

const { Title, Text } = Typography;

const EnquirySettings = (props: courseDataInterface) => {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  useEffect(() => {}, [props?.apiData]);

  return (
    <>
      <Row gutter={[16, 16]} justify="start">
      {/* Card 1 */}
      <Col xs={24} xl={6}>
        <Card
          title={<Title level={5}>Card</Title>}
          bordered={false}
          className="roundedCornerMedium shadowLight"
          style={{border:"1px solid rgb(238, 238, 238)"}}
        >
          <Row justify="center" align="middle" style={{ minHeight: 100 }}>
            <Button type="primary" icon={<PlusOutlined />} size="small" onClick={()=>setModalOpen(true)}>
              Card
            </Button>
          </Row>
        </Card>
      </Col>


      {/* <Col xs={24} xl={6}>
        <Card
        style={{border:"1px solid rgb(238, 238, 238)"}}
          title={<Title level={5}>User Settings</Title>}
          extra={
            <Button type="default" icon={<UndoOutlined />} size="small">
              Reset Password
            </Button>
          }
          bordered={false}
          className="roundedCornerMedium shadowLight"
        >
        </Card>
      </Col> */}


      {/* <Col xs={24} xl={6}>
        <Card
          title={<Title level={5}>Other Settings</Title>}
          bordered={false}
          className="roundedCornerMedium shadowLight"
          style={{border:"1px solid rgb(238, 238, 238)"}}
        >
          
        </Card>
      </Col> */}
    </Row>
    {modalOpen && <CardModel student={{}} modalOpen={modalOpen} setModalOpen={setModalOpen}/> }
    </>
  );
};

export default EnquirySettings;

import * as React from "react";
import { useState, useEffect } from "react";
import {
  ArrowLeftOutlined,
  ContactsOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MailOutlined,
  PhoneOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  ConfigProvider,
  Divider,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Steps,
  Typography,
} from "antd";
import ".././component/EnquiryLeadModel/EnquiryLead.css";
import NoteModel from "../component/EnquiryLeadModel/NoteModel";
import CallLogModel from "../component/EnquiryLeadModel/CallLogModel";
import MailModel from "../component/EnquiryLeadModel/MailModel";
import MeetingModel from "../component/EnquiryLeadModel/MeetingModel";
import TaskModel from "../component/EnquiryLeadModel/TaskModel";
import EmailEnquiry from "../component/EnquiryLeadModel/EmailEnquiry";
import RegistrationFees from "../component/EnquiryLeadModel/RegistrationFees";
import { Tabs } from "antd";
import {
  CandidateRequestType,
  CounselingSubSteps,
  DateType,
  DocumentsSubSteps,
  EmailInvoiceSubSteps,
  LsbuSubSteps,
  NewSubSteps,
  ProcessStepsEnum,
  RegistrationSubSteps,
  VerificationSubSteps,
} from "../Constant/Enums";
import Rejection from "../component/EnquiryLeadModel/Rejection ";
import { EnquiryEndPoint } from "../ApiUrls/URLS";
import { ApiCalls } from "../ApisCaller/ApiCall";
import HTTPSCalls from "../Services/HTTPCalls";
import AcknowledgementAndVerify from "../component/EnquiryLeadModel/AcknowledgementAndVerify";
import {
  getCounselingInnerStepDisable,
  getCounselingInnerStepStatus,
  getDocumentsInnerStepDisable,
  getDocumentsInnerStepStatus,
  getEmailInvoiceInnerStepDisable,
  getEmailInvoiceInnerStepStatus,
  getLSBUInnerStepDisable,
  getLSBUInnerStepStatus,
  getNewInnerStepDisable,
  getNewInnerStepStatus,
  getRegistrationInnerStepDisable,
  getRegistrationInnerStepStatus,
  getVerificationInnerStepDisable,
  getVerificationInnerStepStatus,
} from "./ProcessStepUtil";
import NoteTable from "../component/EnquiryCommunicationTable/NoteTable";
import CallLogTable from "../component/EnquiryCommunicationTable/CallLogTable";
import MailTable from "../component/EnquiryCommunicationTable/MailTable";
import MeetingTable from "../component/EnquiryCommunicationTable/MeetingTable";
import TaskTable from "../component/EnquiryCommunicationTable/TaskTable";
import AttachmentTable from "../component/EnquiryCommunicationTable/AttachmentTable";
import LSBUSteps from "../component/EnquiryLeadModel/LSBUSteps";
import AddCredentialsModel from "../component/EnquiryLeadModel/AddCredentialsModel";
import CredentialsTable from "../component/EnquiryCommunicationTable/CredentialsTable";
import AllActivityLog from "../component/EnquiryCommunicationTable/AllActivityLog";
import EnquiryFormStudents from "./EnquiryFormStudents";
const api = new ApiCalls(EnquiryEndPoint, "Enquiry/");

const { Text, Title } = Typography;

export enum IEnquiryModelType {
  noOpenModel = 0,
  note = 1,
  callLog = 2,
  mail = 3,
  meeting = 4,
  task = 5,
  email = 6,
  registrationFees = 7,
  rejection = 8,
  acknowledgement = 9,
  verifyDocument = 10,
  lsbuAddCV = 11,
  lsbuAddReceivedStudyContact = 12,
  lsbuReceivedCredentials = 13,
  credentials = 14,
}

// const description = "";

// const onChange = (key: string) => { };
interface IEnquiryLead {
  editRecord: any;
  onDismiss: any;
}
const EnquiryLead = ({ editRecord, onDismiss }: IEnquiryLead) => {
  const [openModel, setOpenModel] = React.useState<{
    processStep?: ProcessStepsEnum;
    id: string;
    type: IEnquiryModelType;
    data?: any;
  }>({
    processStep: ProcessStepsEnum.New,
    id: "",
    type: IEnquiryModelType.noOpenModel,
    data: {},
  });
  const [apiData, setApiData] = React.useState<any>(undefined);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [show, setShow] = React.useState("Admission/Enquiry");
  // const [studentData, setStudentData] = React.useState<any>({});
  // const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [shouldRefresh, setShouldRefresh] = React.useState(false);
  const [meetingContentRefresh, setMeetingContentRefresh] =
    React.useState(false);
  const [noteContentRefresh, setNoteContentRefresh] =
    React.useState<boolean>(false);
  const [callContentRefresh, setCallContentRefresh] =
    React.useState<boolean>(false);
  const [mailContentRefresh, setMailContentRefresh] =
    React.useState<boolean>(false);
  const [taskContentRefresh, setTaskContentRefresh] =
    React.useState<boolean>(false);
  const [credentialRefresh, setCredentialContentRefresh] =
    React.useState<boolean>(false);
  const [attachmentContentRefresh, setAttachmentContentRefresh] =
    React.useState<boolean>(false);
  const [outerSteps, setOuterSteps] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [innerSteps, setInnerSteps] = useState<{
    new: any[];
    counseling: any[];
    registration: any[];
    documents: any[];
    sendEmail: any[];
    verification: any[];
    lsbu: any[];
    enroll: any[];
  }>({
    new: [],
    counseling: [],
    registration: [],
    documents: [],
    sendEmail: [],
    verification: [],
    lsbu: [],
    enroll: [],
  });
  // const [current, setCurrent] = useState(0);
  // const [refreshStates, setRefreshStates] = useState({
  //   meetingContent: false,
  //   noteContent: false,
  //   anotherState1: false,
  //   anotherState2: false,
  //   anotherState3: false,
  //   anotherState4: false,
  // });
  // const updateRefreshState = (key: any, value: any) => {
  //   setRefreshStates((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };
  // const onChange = (value: number) => {
  //   setCurrent(value);
  // };
  // const [pagingItems, setPagingItems] = React.useState<{
  //   totalRecords: number;
  //   currentPage: number;
  //   pageSize: number;
  // }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  // const [listParams, setListParams] = React.useState({
  //   filterId: "",
  //   nameSearch: "",
  //   batchSearch: "",
  //   sortCol: "",
  //   sortDir: "",
  //   userRoleId: "",
  // });
  let userType: any = JSON.parse(localStorage.getItem("tp-user") + "")?.role;
  // const changeListParams = (key: string, value: any) => {
  //   setPagingItems({ ...pagingItems, currentPage: 1, pageSize: 15 });
  //   setListParams((prevState) => ({
  //     ...prevState,
  //     [key]: value,
  //   }));
  // };
  // const onOpen = () => {
  //   setIsOpen(true);
  // };
  const onClose = () => {
    setIsOpen(false);
  };

  // const content = (
  //   <div>
  //     <p style={{ cursor: "pointer" }}>Edit</p>
  //     <p style={{ cursor: "pointer" }}>Change Assignee</p>
  //     <p style={{ cursor: "pointer" }}>Convert to Student</p>
  //     <p style={{ cursor: "pointer" }}>Delete</p>
  //   </div>
  // );

  const setItemState = (data: any) => {
    setItems([
      {
        key: "1",
        label: "Logs",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>100</div>
        //   </Avatar>
        // ),
        children: (
          <AllActivityLog apiData={data} shouldRefresh={shouldRefresh} />
        ),
      },
      {
        key: "2",
        label: "Notes",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>80</div>
        //   </Avatar>
        // ),
        children: (
          <NoteTable
            type={IEnquiryModelType.note}
            apiData={data}
            noteContentRefresh={noteContentRefresh}
          />
        ),
      },
      {
        key: "3",
        label: "Call Logs",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>60</div>
        //   </Avatar>
        // ),
        children: (
          <CallLogTable
            callContentRefresh={callContentRefresh}
            type={IEnquiryModelType.callLog}
            apiData={data}
          />
        ),
      },
      {
        key: "4",
        label: "Mails",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>85</div>
        //   </Avatar>
        // ),
        children: (
          <MailTable
            mailContentRefresh={mailContentRefresh}
            type={IEnquiryModelType.mail}
            apiData={data}
          />
        ),
      },
      {
        key: "5",
        label: "Meetings",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>100</div>
        //   </Avatar>
        // ),
        children: (
          <MeetingTable
            type={IEnquiryModelType.meeting}
            apiData={data}
            meetingContentRefresh={meetingContentRefresh}
          />
        ),
      },
      {
        key: "6",
        label: "Task",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>33</div>
        //   </Avatar>
        // ),
        children: (
          <TaskTable
            taskContentRefresh={taskContentRefresh}
            type={IEnquiryModelType.task}
            apiData={data}
          />
        ),
      },
      {
        key: "7",
        label: "Attachments",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>45</div>
        //   </Avatar>
        // ),
        children: (
        <>
      
          <AttachmentTable
            type={IEnquiryModelType.acknowledgement}
            apiData={data}
            attachmentContentRefresh={attachmentContentRefresh}
            setReload={shouldRefresh}
            onSubmit={(rec: boolean) => {
              if (rec) {
                setAttachmentContentRefresh(true);
                setShouldRefresh(!shouldRefresh);
              }
            }}
          />
        </>
        ),
      },
      {
        key: "8",
        label: "Credentials",
        // icon: (
        //   <Avatar style={{ borderRadius: "10px", height: "20px" }}>
        //     <div style={{ fontSize: "10px" }}>95</div>
        //   </Avatar>
        // ),
        children: (
          <CredentialsTable
            type={IEnquiryModelType.credentials}
            apiData={data}
            credentialRefresh={credentialRefresh}
            onSubmit={(rec: boolean) => {
              if (rec) {
                setCredentialContentRefresh(true);
                setShouldRefresh(!shouldRefresh);
              }
            }}
          />
        ),
      },
    ]);
  };
  //--------Get by Id-------//
  const getById = async (id: any) => {
    setLoading(true);
    let res = await api.GET(EnquiryEndPoint.getById + "?", { id });
    if (res?.result?.userId) {
      delete res.result.userId;
  }
  
  setApiData(res?.result);
    // console.log(res, "resDataRecord");
    let r: any = res?.result;
    let apiProcessStep = r?.processStep?.processStep;
    let apiSubSteps = r?.processStep?.subSteps;
    setOuterSteps([
      {
        title: "New",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.New,
        status:
          r?.reject && r?.processStep?.processStep === ProcessStepsEnum.New
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.New
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.New
                ? "wait"
                : "process",
      },
      {
        title: "Counseling",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.Counseling,
        status:
          r?.reject &&
            r?.processStep?.processStep === ProcessStepsEnum.Counseling
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.Counseling
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.Counseling
                ? "wait"
                : "process",
      },
      {
        title: "Registration",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.Registration,
        status:
          r?.reject &&
            r?.processStep?.processStep === ProcessStepsEnum.Registration
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.Registration
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.Registration
                ? "wait"
                : "process",
      },
      // {
      //   title: "Documents",
      //   disabled: r?.processStep?.processStep !== ProcessStepsEnum.Documents,
      //   status:
      //     r?.reject &&
      //       r?.processStep?.processStep === ProcessStepsEnum.Documents
      //       ? "error"
      //       : r?.processStep?.processStep > ProcessStepsEnum.Documents
      //         ? "finish"
      //         : r?.processStep?.processStep < ProcessStepsEnum.Documents
      //           ? "wait"
      //           : "process",
      // },
      {
        title: "Email Invoice",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.EmailInvoice,
        status:
          r?.reject &&
            r?.processStep?.processStep === ProcessStepsEnum.EmailInvoice
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.EmailInvoice
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.EmailInvoice
                ? "wait"
                : "process",
      },
      {
        title: "Verification",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.Verification,
        status:
          r?.reject &&
            r?.processStep?.processStep === ProcessStepsEnum.Verification
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.Verification
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.Verification
                ? "wait"
                : "process",
      },
      {
        title: "CIT",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.LSBU,
        status:
          r?.reject && r?.processStep?.processStep === ProcessStepsEnum.LSBU
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.LSBU
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.LSBU
                ? "wait"
                : "process",
      },
      {
        title: "Enroll",
        disabled: r?.processStep?.processStep !== ProcessStepsEnum.Enroll,
        status:
          r?.reject && r?.processStep?.processStep === ProcessStepsEnum.Enroll
            ? "error"
            : r?.processStep?.processStep > ProcessStepsEnum.Enroll
              ? "finish"
              : r?.processStep?.processStep < ProcessStepsEnum.Enroll
                ? "wait"
                : "process",
      },
    ]);
    setInnerSteps({
      new: [
        {
          title: "Waiting",
          disabled: getNewInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            NewSubSteps.Waiting,
            r?.reject
          ),
          status: getNewInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            NewSubSteps.Waiting,
            r?.reject
          ),
        },
        {
          title: (
            <>
              <Popconfirm
                placement="right"
                title="Confirm the step"
                description="Are you sure to confirm this step?"
                okText="Yes"
                disabled={!!r?.reject}
                cancelText="No"
                onConfirm={async () => {
                  let val = {
                    processStep: ProcessStepsEnum.Counseling,
                    hasSubSteps: true,
                    subSteps: CounselingSubSteps.Waiting,
                    id: r?.id,
                  };
                  await changeStep(val);
                  // setCurrent(current + 1);
                }}
                icon={<InfoCircleOutlined style={{ color: "#1677FF" }} />}
              >
                Confirm
              </Popconfirm>
            </>
          ),
          disabled: getNewInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            NewSubSteps.Confirm,
            r?.reject
          ),
          status: getNewInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            NewSubSteps.Confirm,
            r?.reject
          ),
        },
        {
          title: "Reject",
          status: getNewInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            NewSubSteps.Reject,
            r?.reject
          ),
          disabled: getNewInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            NewSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      counseling: [
        {
          title: "Waiting",
          disabled: getCounselingInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            CounselingSubSteps.Waiting,
            r?.reject
          ),
          status: getCounselingInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            CounselingSubSteps.Waiting,
            r?.reject
          ),
        },
        {
          title: (
            <>
              <Popconfirm
                placement="right"
                title="Confirm the step"
                description="Are you sure to confirm this step?"
                okText="Yes"
                disabled={!!r?.reject}
                cancelText="No"
                onConfirm={async () => {
                  let val = {
                    processStep: ProcessStepsEnum.Registration,
                    hasSubSteps: true,
                    subSteps: RegistrationSubSteps.Waiting,
                    id: r?.id,
                  };
                  await changeStep(val);
                }}
                icon={<InfoCircleOutlined style={{ color: "#1677FF" }} />}
              >
                Confirm
              </Popconfirm>
            </>
          ),
          disabled: getCounselingInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            CounselingSubSteps.Confirm,
            r?.reject
          ),
          status: getCounselingInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            CounselingSubSteps.Confirm,
            r?.reject
          ),
        },
        {
          title: "Reject",
          status: getCounselingInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            CounselingSubSteps.Reject,
            r?.reject
          ),
          disabled: getCounselingInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            CounselingSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      registration: [
        {
          title: "Waiting",
          disabled: getRegistrationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            RegistrationSubSteps.Waiting,
            r?.reject
          ),
          status: getRegistrationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            RegistrationSubSteps.Waiting,
            r?.reject
          ),
        },
        {
          title: "Proceed",
          disabled: getRegistrationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            RegistrationSubSteps.Proceed,
            r?.reject
          ),
          status: getRegistrationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            RegistrationSubSteps.Proceed,
            r?.reject
          ),
        },
        {
          title: "Reject",
          status: getRegistrationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            RegistrationSubSteps.Reject,
            r?.reject
          ),
          disabled: getRegistrationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            RegistrationSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      documents: [
        {
          title: "Submission",
          disabled: getDocumentsInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.Submission,
            r?.reject
          ),
          status: getDocumentsInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.Submission,
            r?.reject
          ),
        },
        {
          title: "Acknowledgment",
          disabled: getDocumentsInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.Acknowledgement,
            r?.reject
          ),
          status: getDocumentsInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.Acknowledgement,
            r?.reject
          ),
        },
        {
          title: "Verify Documents",
          status: getDocumentsInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.VerifyDocuments,
            r?.reject
          ),
          disabled: getDocumentsInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.VerifyDocuments,
            r?.reject
          ),
        },
        {
          title: "Reject",
          status: getDocumentsInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.Reject,
            r?.reject
          ),
          disabled: getDocumentsInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            DocumentsSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      sendEmail: [
        {
          title: "Waiting",
          disabled: getEmailInvoiceInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            EmailInvoiceSubSteps.Waiting,
            r?.reject
          ),
          status: getEmailInvoiceInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            EmailInvoiceSubSteps.Waiting,
            r?.reject
          ),
        },
        {
          title: "Send Invoice",
          disabled: getEmailInvoiceInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            EmailInvoiceSubSteps.SendInvoice,
            r?.reject
          ),
          status: getEmailInvoiceInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            EmailInvoiceSubSteps.SendInvoice,
            r?.reject
          ),
        },
        {
          title: "Reject",
          status: getEmailInvoiceInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            EmailInvoiceSubSteps.Reject,
            r?.reject
          ),
          disabled: getEmailInvoiceInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            EmailInvoiceSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      verification: [
        {
          title: "Waiting",
          disabled: getVerificationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.Waiting,
            r?.reject
          ),
          status: getVerificationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.Waiting,
            r?.reject
          ),
        },
        {
          title: "Acknowledgment",
          disabled: getVerificationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.Acknowledgement,
            r?.reject
          ),
          status: getVerificationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.Acknowledgement,
            r?.reject
          ),
        },
        {
          title: "Verify Documents",
          disabled: getVerificationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.VerifyDocuments,
            r?.reject
          ),
          status: getVerificationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.VerifyDocuments,
            r?.reject
          ),
        },
        {
          title: "Reject",  
          status: getVerificationInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.Reject,
            r?.reject
          ),
          disabled: getVerificationInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            VerificationSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      lsbu: [
        {
          title: "Waiting",
          disabled: getLSBUInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.Waiting,
            r?.reject
          ),
          status: getLSBUInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.Waiting,
            r?.reject
          ),
        },
        {
          title: "Share CV Docs",
          disabled: getLSBUInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.ShareCVDocs,
            r?.reject
          ),
          status: getLSBUInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.ShareCVDocs,
            r?.reject
          ),
        },
        {
          title: "Received Study Contact",
          disabled: getLSBUInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.ReceivedStudyContact,
            r?.reject
          ),
          status: getLSBUInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.ReceivedStudyContact,
            r?.reject
          ),
        },
        {
          title: "Received Credentials",
          disabled: getLSBUInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.ReceivedCredentials,
            r?.reject
          ),
          status: getLSBUInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.ReceivedCredentials,
            r?.reject
          ),
        },
        // {
        //   title: "Credentials",
        //   disabled: getLSBUInnerStepDisable(
        //     apiProcessStep,
        //     apiSubSteps,
        //     LsbuSubSteps.ReceivedCredentials,
        //     r?.reject
        //   ),
        //   status: getLSBUInnerStepStatus(
        //     apiProcessStep,
        //     apiSubSteps,
        //     LsbuSubSteps.ReceivedCredentials,
        //     r?.reject
        //   ),
        // },
        {
          title: "Reject",
          disabled: getLSBUInnerStepDisable(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.Reject,
            r?.reject
          ),
          status: getLSBUInnerStepStatus(
            apiProcessStep,
            apiSubSteps,
            LsbuSubSteps.Reject,
            r?.reject
          ),
        },
      ],
      enroll: [],
    });

    if (credentialRefresh || attachmentContentRefresh) {
      setItemState(r);
      setCredentialContentRefresh(false);
      setAttachmentContentRefresh(false);
    } else {
      setItemState(r);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getById(editRecord?.id);
  }, [shouldRefresh]);

  const getSubStep = (step: number) => {
    if (step === ProcessStepsEnum.New) {
      return (
        <>
          <Steps
            //
            size="small"
            onChange={(val: any) => {
              if (val === NewSubSteps.Confirm) {
                // setCurrent(current + 1);
              } else if (val === NewSubSteps.Reject) {
                setOpenModel({
                  id: apiData?.id,
                  processStep: ProcessStepsEnum.New,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                });
              }
            }}
            direction="vertical"
            items={innerSteps?.new}
          />
        </>
      );
    }
    if (step === ProcessStepsEnum.Counseling) {
      return (
        <>
          <Steps
            //
            size="small"
            onChange={(val: any) => {
              if (val === 1) {
                // setCurrent(current + 1);
              } else if (val === CounselingSubSteps.Reject) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                  processStep: ProcessStepsEnum.Counseling,
                });
              }
            }}
            direction="vertical"
            items={innerSteps.counseling}
          />
        </>
      );
    }
    if (step === ProcessStepsEnum.Registration) {
      return (
        <>
          <Steps
            size="small"
            onChange={(val: any) => {
              if (val === RegistrationSubSteps.Proceed) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.registrationFees,
                  data: undefined,
                });
                // setCurrent(val);
              } else if (val === RegistrationSubSteps.Reject) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                  processStep: ProcessStepsEnum.Registration,
                });
              }
            }}
            direction="vertical"
            items={innerSteps.registration}
          />
        </>
      );
    }
    // if (step === ProcessStepsEnum.Documents) {
    //   return (
    //     <>
    //       <Steps
    //         size="small"
    //         onChange={(val: any) => {
    //           if (val + 2 === DocumentsSubSteps.Acknowledgement) {
    //             setOpenModel({
    //               processStep: ProcessStepsEnum.Documents,
    //               id: apiData?.id,
    //               type: IEnquiryModelType.acknowledgement,
    //               data: undefined,
    //             });
    //             // setCurrent(val);
    //           } else if (val - 1 === DocumentsSubSteps.VerifyDocuments) {
    //             setOpenModel({
    //               processStep: ProcessStepsEnum.Documents,
    //               id: apiData?.id,
    //               type: IEnquiryModelType.verifyDocument,
    //               data: undefined,
    //             });
    //           } else if (val - 1 === DocumentsSubSteps.Reject) {
    //             setOpenModel({
    //               id: apiData?.id,
    //               type: IEnquiryModelType.rejection,
    //               data: undefined,
    //             });
    //           }
    //         }}
    //         direction="vertical"
    //         items={innerSteps.documents}
    //       />
    //     </>
    //   );
    // }
    if (step === ProcessStepsEnum.EmailInvoice) {
      return (
        <>
          <Steps
            size="small"
            onChange={(val: any) => {
              if (val === EmailInvoiceSubSteps.SendInvoice) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.email,
                  data: undefined,
                });
                // setCurrent(val);
              }
              if (val === EmailInvoiceSubSteps.Reject) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                  processStep: ProcessStepsEnum.EmailInvoice,
                });
                // setCurrent(val);
              }
            }}
            direction="vertical"
            items={innerSteps.sendEmail}
          />
        </>
      );
    }
    if (step === ProcessStepsEnum.Verification) {
      return (
        <>
          <Steps
            size="small"
            onChange={(val: any) => {
              if (val + 2 === VerificationSubSteps.Acknowledgement) {
                setOpenModel({
                  processStep: ProcessStepsEnum.Verification,
                  id: apiData?.id,
                  type: IEnquiryModelType.acknowledgement,
                  data: undefined,
                });
                // setCurrent(val);
              } else if (val - 1 === VerificationSubSteps.VerifyDocuments) {
                setOpenModel({
                  processStep: ProcessStepsEnum.Verification,
                  id: apiData?.id,
                  type: IEnquiryModelType.verifyDocument,
                  data: undefined,
                });
              } else if (val - 1 === VerificationSubSteps.Reject) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                  processStep: ProcessStepsEnum.Verification,
                });
              }
            }}
            direction="vertical"
            items={innerSteps.verification}
          />
        </>
      );
    }
    if (step === ProcessStepsEnum.LSBU) {
      return (
        <>
          <Steps
            size="small"
            onChange={(val: any) => {
              if (val === 1) {
                setOpenModel({
                  processStep: ProcessStepsEnum.LSBU,
                  id: apiData?.id,
                  type: IEnquiryModelType.lsbuAddCV,
                  data: undefined,
                });
                // setCurrent(val);
              }
              if (val === 2) {
                setOpenModel({
                  processStep: ProcessStepsEnum.LSBU,
                  id: apiData?.id,
                  type: IEnquiryModelType.lsbuAddReceivedStudyContact,
                  data: undefined,
                });
                // setCurrent(val);
              }
              if (val === 3) {
                setOpenModel({
                  processStep: ProcessStepsEnum.LSBU,
                  id: apiData?.id,
                  type: IEnquiryModelType.lsbuReceivedCredentials,
                  data: undefined,
                });
                // setCurrent(val);
              } else if (val === 4) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                  processStep: ProcessStepsEnum.LSBU,
                });
              }
            }}
            direction="vertical"
            items={innerSteps?.lsbu}
          />
        </>
      );
    }
    if (step === ProcessStepsEnum.Enroll) {
      return (
        <>
          <Steps
            size="small"
            onChange={(val: any) => {
              if (val === 1) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.registrationFees,
                  data: undefined,
                });
                // setCurrent(val);
              } else if (val === 2) {
                setOpenModel({
                  id: apiData?.id,
                  type: IEnquiryModelType.rejection,
                  data: undefined,
                  processStep: ProcessStepsEnum.Enroll,
                });
              }
            }}
            direction="vertical"
          />
        </>
      );
    }
  };

  const changeStep = async (val: any, dateType?: DateType, paramDate?: any, withoutFees?: boolean) => {
    let data = {
      ...val,
    };
    if (dateType === DateType.EnrollDate) {
      data = {
        ...val,
        dateType: DateType.EnrollDate,
        date: paramDate,
      };
    }
    if (withoutFees) {
      data = {
        ...val,
        isEnrolled: true
      }
    }
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.ENQUIRY_SET_STEPS,
      {},
      data
    );
    if (res?.result) {
      setShouldRefresh(!shouldRefresh);
    }
  };

  // console.log("shouldRefresh  = ", shouldRefresh);
  useEffect(() => {
    if (apiData) {
      setItemState(apiData);
      // console.log("item is render...");
    }
  }, [
    noteContentRefresh,
    meetingContentRefresh,
    taskContentRefresh,
    mailContentRefresh,
    callContentRefresh,
    shouldRefresh
  ]);
  // console.log("Enq credentialData = ", apiData?.credentials);
  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        {show === "EditEnquiry" ? (
          <EnquiryFormStudents
            userType={userType}
            isOpen={isOpen}
            type={CandidateRequestType.Enquire}
            // setIsLoading={setIsLoading}
            setShouldRefresh={setShouldRefresh}
            shouldRefresh={shouldRefresh}
            apiData={apiData}
            onDismiss={(rec: boolean) => {
              setIsOpen(false);
              setShow("Admission/Enquiry");
              onClose();
              if (rec) {
                setShouldRefresh(!shouldRefresh);
              }
            }}
          />
        ) : (
          <>
            <Row
              gutter={[8, 8]}
              style={{ paddingTop: "5px", paddingBottom: "6px" }}
            >
              <Col span={15}>
                <Button
                  type="link"
                  icon={<ArrowLeftOutlined />}
                  onClick={() => {
                    onDismiss(false);
                  }}
                />
                <Typography>
                  <Text type="secondary">Enquiry /</Text>
                  <Text> Candidate</Text>
                </Typography>
                <Title level={5}>{editRecord?.fullName}</Title>
              </Col>
              <Col span={9}>
              <Row gutter={8}justify={'end'}>

  
              <Col>
                <Button
                  icon={<FileDoneOutlined />}
                  type="default"
                  onClick={() => {
                    setOpenModel({ id: "", type: IEnquiryModelType.note });
                  }}
                >
                  Note
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  onClick={() => {
                    setOpenModel({ id: "", type: IEnquiryModelType.callLog });
                  }}
                  icon={<PhoneOutlined rotate={100} />}
                >
                  Call Log
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  icon={<MailOutlined />}
                  onClick={() => {
                    setOpenModel({ id: "", type: IEnquiryModelType.mail });
                  }}
                >
                  Mail
                </Button>
              </Col>
              <Col>
                <Button
                  icon={<ContactsOutlined />}
                  type="default"
                  onClick={() => {
                    setOpenModel({ id: "", type: IEnquiryModelType.meeting });
                  }}
                >
                  Meeting
                </Button>
              </Col>
              <Col>
                <Button
                  type="default"
                  icon={<ScheduleOutlined />}
                  onClick={() => {
                    setOpenModel({ id: "", type: IEnquiryModelType.task });
                  }}
                >
                  Task
                </Button>
              </Col>

              {/* <Col>
                <Popover placement="bottom" content={content}>
                  <Button type="default" icon={<MoreOutlined />}></Button>
                </Popover>
              </Col> */}
            
            </Row>
              </Col>
            </Row>

            <div
              className="roundedCornerSmall bg-white"
              style={{ padding: "15px" }}
            >
              <Row justify={"space-between"}>
                <Text strong>Basic Information</Text>
                <Button
                  type="default"
                  onClick={() => {
                    setShow("EditEnquiry");
                  }}
                >
                  Edit
                </Button>
              </Row>
              <Row gutter={[30, 40]}>
                <Col lg={7} md={12}>
                  <Row>
                    <Col
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text type="secondary">Lead Name</Text>
                    </Col>
                    <Col
                      lg={16}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text>{apiData?.fullName}</Text>
                    </Col>
                    <Col
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text type="secondary">Phone No.</Text>
                    </Col>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text>{apiData?.contactDetail?.mobileNumber}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col lg={7} md={12}>
                  <Row>
                    <Col
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text type="secondary">Email</Text>
                    </Col>
                    <Col
                      lg={14}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text>{apiData?.contactDetail?.email}</Text>
                    </Col>
                    <Col
                      lg={8}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text type="secondary">Source</Text>
                    </Col>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text>{apiData?.sources?.name}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={12}>
                  <Row>
                    <Col
                      lg={10}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text type="secondary">Reference</Text>
                    </Col>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      style={{ padding: "4px 0px 4px 0px" }}
                    >
                      <Text>{apiData?.referralSource}</Text>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>

            <div
              className="roundedCornerSmall bg-white"
              style={{ padding: "15px", marginTop: "10px" }}
            >
              <Row gutter={[24, 20]}>
                <Col lg={24}>
                  <>
                    <Steps
                      //
                      size="small"
                      current={apiData?.processStep?.processStep - 2}
                      // onChange={onChange}
                      items={outerSteps}
                    />

                    <Divider />
                    <Row gutter={[10, 16]}>
                      {apiData?.processStep?.hasSubSteps && (
                        <>
                          <Col span={4}>
                            <ConfigProvider
                              theme={{
                                components: {
                                  Steps: {
                                    // fontSize: 13,
                                    fontWeightStrong: 500,
                                  },
                                },
                              }}
                            >
                              {getSubStep(apiData?.processStep?.processStep)}
                            </ConfigProvider>
                          </Col>
                          <Col lg={1}>
                            <Divider
                              type="vertical"
                              style={{ height: "100%" }}
                            />
                          </Col>
                        </>
                      )}

                      <Col lg={apiData?.processStep?.hasSubSteps ? 19 : 24}>
                        <Tabs
                          defaultActiveKey="1"
                          items={items}
                        // onChange={onChange}
                        />
                      </Col>
                    </Row>
                  </>
                </Col>
              </Row>
              {/* <Divider className="marginDivider" /> */}
            </div>
            <Modal open={false}></Modal>
            {openModel.type === IEnquiryModelType.note && (
              <NoteModel
                open={true}
                id={openModel.id}
                type={openModel?.type}
                apiData={apiData}
                onCancel={(rec: boolean, isUploadAtt: boolean) => {
                  if (isUploadAtt) {
                    setAttachmentContentRefresh(true);
                    setShouldRefresh(!shouldRefresh)
                  }
                  if (rec) {
                    setNoteContentRefresh(!noteContentRefresh);
                  }
                  setOpenModel({ type: IEnquiryModelType.noOpenModel, id: "" });
                }}
              />
            )}
            {openModel.type === IEnquiryModelType.callLog && (
              <CallLogModel
                open={true}
                id={openModel.id}
                type={openModel?.type}
                apiData={apiData}
                onCancel={(res: boolean, isUploadAtt: boolean) => {
                  if (isUploadAtt) {
                    setAttachmentContentRefresh(true);
                    setShouldRefresh(!shouldRefresh)
                  }
                  if (res) {
                    setCallContentRefresh(!callContentRefresh);
                  }
                  setOpenModel({ type: IEnquiryModelType.noOpenModel, id: "" });
                }}
              />
            )}
            {openModel.type === IEnquiryModelType.mail && (
              <MailModel
                open={true}
                id={openModel.id}
                type={openModel.type}
                apiData={apiData}
                onCancel={(rec: boolean, isUploadAtt: boolean) => {
                  if (isUploadAtt) {
                    setAttachmentContentRefresh(true);
                    setShouldRefresh(!shouldRefresh)
                  }
                  if (rec) {
                    setMailContentRefresh(!mailContentRefresh);
                  }
                  setOpenModel({ type: IEnquiryModelType.noOpenModel, id: "" });
                }}
              />
            )}
            {openModel.type === IEnquiryModelType.meeting && (
              <MeetingModel
                open={true}
                id={openModel.id}
                type={openModel.type}
                apiData={apiData}
                onCancel={(rec: boolean, isUploadAtt: boolean) => {
                  if (isUploadAtt) {
                    setAttachmentContentRefresh(true);
                    setShouldRefresh(!shouldRefresh)
                  }
                  if (rec) {
                    setMeetingContentRefresh(!meetingContentRefresh);
                  }
                  setOpenModel({ type: IEnquiryModelType.noOpenModel, id: "" });
                }}
              />
            )}
            {openModel.type === IEnquiryModelType.task && (
              <TaskModel
                open={true}
                id={openModel.id}
                type={openModel.type}
                apiData={apiData}
                onCancel={(rec: boolean, isUploadAtt: boolean) => {
                  if (isUploadAtt) {
                    setAttachmentContentRefresh(true);
                    setShouldRefresh(!shouldRefresh)
                  }
                  if (rec) {
                    setTaskContentRefresh(!taskContentRefresh);
                  }
                  setOpenModel({ type: IEnquiryModelType.noOpenModel, id: "" });
                }}
              />
            )}
            {openModel.type === IEnquiryModelType.email && (
              <EmailEnquiry
                open={true}
                id={openModel.id}
                onCancel={(rec: boolean) => {
                  if (rec) {
                    setAttachmentContentRefresh(true);
                    setShouldRefresh(!shouldRefresh)
                  }
                  setOpenModel({ type: IEnquiryModelType.noOpenModel, id: "" });
                }}
                editRecord={apiData}
              />
            )}

            {/* Models for Steps */}
            {openModel.type === IEnquiryModelType.registrationFees && (
              <RegistrationFees
                open={true}
                id={apiData?.id}
                data={apiData}
                onCancel={async (rec: boolean, feesData: any, withoutFees: boolean) => {
                  if (rec) {
                    let val = {
                      processStep: ProcessStepsEnum.EmailInvoice,
                      hasSubSteps: true,
                      subSteps: EmailInvoiceSubSteps.Waiting,
                      id: apiData?.id,
                    };
                    if (withoutFees) {
                      await changeStep({ ...val, ...feesData }, undefined, undefined, withoutFees)
                    }
                    else {
                      await changeStep(val, DateType.EnrollDate, feesData?.date, withoutFees);
                    }
                  }
                  setOpenModel({
                    type: IEnquiryModelType.noOpenModel,
                    id: "",
                    data: {},
                  });
                }}
              />
            )}
            {openModel.type === IEnquiryModelType.rejection && (
              <Rejection
                processStep={openModel?.processStep}
                open={true}
                id={openModel.id}
                onCancel={(rec: boolean) => {
                  if (rec) {
                    setShouldRefresh(!shouldRefresh);
                  }
                  setOpenModel({
                    type: IEnquiryModelType.noOpenModel,
                    id: "",
                    processStep: undefined,
                  });
                }}
              />
            )}

            {/* Use for Both Document and Verify */}
            {openModel?.processStep === ProcessStepsEnum.Documents && (
              <AcknowledgementAndVerify
                outerStep={ProcessStepsEnum.Documents}
                open={true}
                id={openModel.id}
                type={openModel.type}
                onAdd={async (
                  rec: boolean,
                  isAcknowledgement: boolean,
                  isVerifyDocument: boolean
                ) => {
                  if (rec) {
                    let val = {
                      processStep: isAcknowledgement
                        ? ProcessStepsEnum.Documents
                        : isVerifyDocument
                          ? ProcessStepsEnum.EmailInvoice
                          : ProcessStepsEnum.Documents,
                      hasSubSteps: true,
                      subSteps: isAcknowledgement
                        ? DocumentsSubSteps.VerifyDocuments
                        : isVerifyDocument
                          ? EmailInvoiceSubSteps.Waiting
                          : DocumentsSubSteps.Acknowledgement,
                      id: apiData?.id,
                    };
                    await changeStep(val);
                    setAttachmentContentRefresh(true);
                    setOpenModel({
                      id: "",
                      type: IEnquiryModelType.noOpenModel,
                      data: undefined,
                    });
                  }
                }}
                onCancel={() =>
                  setOpenModel({
                    type: IEnquiryModelType.noOpenModel,
                    id: "",
                  })
                }
                apiData={apiData}
              />
            )}

            {/* Use for Both Verification and Verify */}
            {openModel?.processStep === ProcessStepsEnum.Verification && openModel.type !== IEnquiryModelType.rejection && (
              <AcknowledgementAndVerify
                outerStep={ProcessStepsEnum.Verification}
                open={true}
                id={openModel.id}
                type={openModel.type}
                onAdd={async (
                  rec: boolean,
                  isAcknowledgement: boolean,
                  isVerifyDocument: boolean
                ) => {
                  if (rec) {
                    let val = {
                      processStep: isAcknowledgement
                        ? ProcessStepsEnum.Verification
                        : isVerifyDocument
                          ? ProcessStepsEnum.LSBU
                          : ProcessStepsEnum.Verification,
                      hasSubSteps: true,
                      subSteps: isAcknowledgement
                        ? VerificationSubSteps.VerifyDocuments
                        : isVerifyDocument
                          ? LsbuSubSteps.Waiting
                          : VerificationSubSteps.Acknowledgement,
                      id: apiData?.id,
                    };
                    await changeStep(val);
                    setAttachmentContentRefresh(true);
                    setOpenModel({
                      id: "",
                      type: IEnquiryModelType.noOpenModel,
                      data: undefined,
                    });

                  }
                }}
                onCancel={() =>
                  setOpenModel({
                    type: IEnquiryModelType.noOpenModel,
                    id: "",
                  })
                }
                apiData={apiData}
              />
            )}

            {/* Use for LSBU */}
            {openModel?.processStep === ProcessStepsEnum.LSBU && openModel.type !== IEnquiryModelType.rejection &&
              !(
                openModel?.type === IEnquiryModelType.lsbuReceivedCredentials
              ) && (
                <LSBUSteps
                  outerStep={ProcessStepsEnum.LSBU}
                  open={true}
                  id={openModel.id}
                  type={openModel.type}
                  onAdd={async (
                    rec: boolean,
                    isCV: boolean,
                    isReceivedStudyContact: boolean
                  ) => {
                    if (rec) {
                      let val = {
                        processStep: ProcessStepsEnum.LSBU,
                        hasSubSteps: true,
                        subSteps: isCV
                          ? LsbuSubSteps.ReceivedStudyContact
                          : isReceivedStudyContact
                            ? LsbuSubSteps.ReceivedCredentials
                            : LsbuSubSteps.ReceivedStudyContact,
                        id: apiData?.id,
                      };
                      await changeStep(val);
                      setAttachmentContentRefresh(true);
                      setOpenModel({
                        id: "",
                        type: IEnquiryModelType.noOpenModel,
                        data: undefined,
                      });
                    }
                  }}
                  onCancel={() =>
                    setOpenModel({
                      type: IEnquiryModelType.noOpenModel,
                      id: "",
                    })
                  }
                  apiData={apiData}
                />
              )}

            {/* Use for LSBU  lsbuReceivedCredentials */}
            {openModel?.processStep === ProcessStepsEnum.LSBU &&
              openModel?.type === IEnquiryModelType.lsbuReceivedCredentials && (
                <AddCredentialsModel
                  open={true}
                  id={openModel.id}
                  onAdd={async (rec: boolean) => {
                    if (rec) {
                      let val = {
                        processStep: ProcessStepsEnum.Enroll,
                        hasSubSteps: false,
                        subSteps: LsbuSubSteps.ReceivedCredentials,
                        id: apiData?.id,
                      };
                      await changeStep(val);
                      setCredentialContentRefresh(true);
                      setOpenModel({
                        id: "",
                        type: IEnquiryModelType.noOpenModel,
                        data: undefined,
                      });
                    }
                  }}
                  onCancel={() =>
                    setOpenModel({
                      type: IEnquiryModelType.noOpenModel,
                      id: "",
                    })
                  }
                  apiData={apiData}
                />
              )}
          </>
        )}
      </Spin>
    </>
  );
};

export default EnquiryLead;

import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, notification, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useEffect, useState } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../Store/Slice/batchSlice";
import dayjs from "dayjs";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { saveAs } from "file-saver";
import { adminContext } from "../../Common/PageRoute";

const Reports = () => {
  const { RangePicker } = DatePicker;
  const [dataSource, setDataSource] = useState<any>([]);
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<any>([]);
  const [searchVal, setSearchVal] = useState<any>("");
  const batchState = useSelector(selectBatchState);
  const { openNotification } = useContext(adminContext);
  // const[listParams,setListParams]=useState<any>("");
  const getReports = async () => {
    setLoading(true);
    try {
      if (batchState) {
        const response = await HTTPSCalls.GET(
          HTTPSCalls.ENDPOINTS.GET_STUDENT_REPORTS + "?",
          {
            batchId: batchState,
            startTime: selectedDate[0],
            endTime: selectedDate[1],
            searchTerm: searchVal,
          }
        );
        if (response?.result) {
          setDataSource(response?.result);
        }
      }
      else{
        setDataSource([]);
      }
    } catch (error) {
      console.log(error, "Failed to fetch Data", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getReports();
  }, [selectedDate, batchState, searchVal]);

  useEffect(() => {
    // message.info("Please select a batch to see reports");
    setSelectedDate([dayjs.utc().startOf("month"), dayjs.utc()]);
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      width: "0.4%",
      key: "sno",
      render: (text: any, record: any, index: any) => (
        <>
          {index + 1}
          {/* <p>{(pagingItems.currentPage - 1) * pagingItems.pageSize + index + 1}</p> */}
        </>
      ),
    },
    {
      title: "Student ID",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any) => <span>{record?.studentId}</span>,
      width: "0.7%",
      ellipsis: true,
    },
    {
      title: "Student Name",
      dataIndex: "batch",
      key: "batch",
      render: (text: any, record: any) => (
        <span>{record?.studentIdName?.name}</span>
      ),
      width: "1.5%",
    },
    {
      title: "Working Days",
      dataIndex: "totalWorkingDays",
      key: "totalWorkingDays",
      align: "end",
      width: "0.7%",
    },
    {
      title: "Presence Days",
      dataIndex: "totalPresentDays",
      key: "totalPresentDays",
      width: "0.7%",
      align: "end",
    },
    {
      title: "Absent Days",
      dataIndex: "totalAbsentDays",
      //   key: "totalAbsentDays",
      width: "0.7%",
      align: "end",
    },
    {
      title: "Leaves",
      dataIndex: "totalLeaves",
      key: "totalLeaves",
      width: "0.7%",
      align: "end",
    },

    {
      title: "Holidays",
      dataIndex: "totalHolidays",
      width: "0.7%",
      align: "end",
    },
    {
      title: "WeekOffs",
      dataIndex: "totalWeekOffs",
      key: "totalWeekOffs",
      width: "0.7%",
      align: "end",
    },
    {
      title: "Late Checkin Days",
      dataIndex: "lateCheckinDays",
      key: "lateCheckinDays",
      width: "0.8%",
      align: "end",
    },
    {
      title: "Early CheckOut Days",
      dataIndex: "earlyCheckoutDays",
      key: "earlyCheckoutDays",
      align: "end",
      width: "1%",
    },
  ];
  const getFileName = (contentDispositionValue: string) => {
    var filename: string | undefined = undefined;
    if (
      contentDispositionValue &&
      contentDispositionValue.indexOf("attachment") !== -1
    ) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDispositionValue);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  };

  const handleDownloadReportCsv = async () => {
    try {
      if (batchState) {
        setCsvLoading(true);
        let response = await ApiUtility.downloadFile(
          HTTPSCalls.ENDPOINTS.DOWNLOAD_STUDENT_REPORT_CSV + "?",
          {
            batchId: batchState,
            startTime: selectedDate[0].$d,
            endTime: selectedDate[1].$d,
          },
        );
        console.log("Date",selectedDate[0].$d)

      }
      else{
        openNotification("error","Please select batch")
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCsvLoading(false);
    }
  };

  return (
    <>
      <Row
        className="roundedCornerSmall bg-white"
        style={{ padding: "16px 16px 16px 16px" }}
      >
        <Col span={17}>
          <Button
            type="default"
            loading={csvLoading}
            onClick={handleDownloadReportCsv}
            icon={<DownloadOutlined />}
          >
            CSV
          </Button>
        </Col>
        <Col span={7}>
          <Space size={15} align="center">
            <RangePicker
              format={"DD-MM-YYYY"}
              value={selectedDate}
              onChange={(dates: any, dateStrings: [string, string]) => {
                setSelectedDate([dayjs.utc(dates[0]), dayjs.utc(dates[1])]);
              }}
            />
            <Input.Search
              placeholder="Search..."
              allowClear
              onSearch={(searchVal) => {
                setSearchVal(searchVal);
              }}
            />
          </Space>
        </Col>
        <Row className="pt-3">
          <Col span={24}>
            <Table
              rowKey={(record) => record.studentIdName?.id}
              size="small"
              columns={columns}
              dataSource={dataSource}
              loading={loading}
              pagination={false}
              scroll={{ y: 600 }}
            />

            {/* <Pagination
              style={{ float: "right", marginTop: "10px" }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              current={pagingItems.currentPage}
              pageSize={pagingItems.pageSize}
              showSizeChanger
              total={pagingItems.totalRecords}
              pageSizeOptions={["15", "25", "50", "100", "200"]}
              onChange={(current, pageSize) => {
                setPagingItems({
                  ...pagingItems,
                  currentPage: current,
                  pageSize: pageSize,
                });
              }}
            /> */}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Reports;

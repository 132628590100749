import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import MarkSubWiseAttendancebulk from "./MarkSubWiseAttendancebulk";
import { ColumnsType } from "antd/es/table/InternalTable";
import dayjs, { Dayjs } from "dayjs";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { useSelector } from "react-redux";
import { selectBatchState } from "../../../Store/Slice/batchSlice";

const SubjectWiseAttendance = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [monthDates, setMonthDates] = useState<any>([]);
  const [monthDays, setMonthDays] = useState<any>([]);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [attendanceCount, setAttendanceCount] = useState<any>({});
  const [holidays, setHolidays] = useState<any>([]);
  const [selectedMonth, setSelectedMonth] = useState<Dayjs>(dayjs());
  const [pageLoader, setPageLoader] = useState(false);
  const [subjects, setSubjects] = useState<any>([]);
  const [toatalStudentCount, setToatalStudentCount] = useState<number>(0);
  // const [pagingItems, setPagingItems] = useState<{ totalRecords: number, currentPage: number, pageSize: number }>({ totalRecords: 0, currentPage: 1, pageSize: 15 });
  const [subjectsId, setSubjectsId] = useState<any>("");
  const batchState = useSelector(selectBatchState);

  const drawerClose = () => {
    setOpenDrawer(false);
  };

  const calDate = (startDate: any, endDate: any) => {
    let dates = [];
    let days = [];
    for (
      let date = startDate;
      date.isSameOrBefore(endDate);
      date = date.add(1, "day")
    ) {
      dates.push(dayjs(date));
      days.push(dayjs(date).format("ddd"));
    }
    setMonthDates(dates);
    setMonthDays(days);
  };
  const getColor = (
    attStatus: string,
    isLeave: boolean,
    isHoliday: boolean,
    day: any
  ) => {
    if (attStatus === "P") {
      return "green";
    } else if (isHoliday || day === "Sun" || day === "Sat") {
      return "red";
    } else if (isLeave) {
      return "#b029bc";
    } else {
      return "red";
    }
  };

  // const getMaster = async () => {
  //   let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?", {});
  //   if (res?.result?.itmes?.length >= 0) {
  //     // const result = res?.result?.items;
  //     // const transformedItems = result.map((item: any) => {
  //     //   return {
  //     //     masterType: item.masterType,
  //     //     items: item.items.map((innerItem: any) => {
  //     //       return {
  //     //         value: innerItem.id,
  //     //         label: innerItem.name
  //     //       };
  //     //     })
  //     //   }
  //     // });
  //     // let obj: {
  //     //   subjects: any
  //     // } = {
  //     //   subjects: transformedItems?.filter((x: any) => x?.masterType === MasterTypes.Subjects)?.[0]?.items,
  //     // }

  //     // console.log(obj,"obbbb")

  //   }
  // }

  useEffect(() => {
    // getMaster()
    calDate(dayjs().startOf("month"), dayjs().endOf("month"));
  }, []);

  const getSheet = async (month: Dayjs) => {
    try {
      setPageLoader(true);
      const res: any = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_SUBJECTWISE_ATTENDANCE_SHEET + "?",
        {
          month: month.format("MM"),
          year: month.format("YYYY"),
          batchId: batchState,
          subjectId: subjectsId,
          start: 0,
          length: 15,
        }
      );
      if (res) {
        // console.log(res, "res000");
        setDataSource(
          res?.students?.map((item: any, index: number) => ({
            key: index,
            sno: 0 + index + 1,
            name: item?.name,
            fullName: item?.name,
            enrDate: item?.enrolledDate,
            records: item?.record,
            ...(item?.leaveArr && { leave: item?.leaveArr }),
          })) || []
        );

        const newAttendanceCount: Record<string, number> = {};
        res?.students?.forEach((student: any) => {
          student.record.forEach((record: any) => {
            if (record.isPresent === "P") {
              const dateKey = record.day;
              if (!newAttendanceCount[dateKey]) {
                newAttendanceCount[dateKey] = 0;
              }
              newAttendanceCount[dateKey]++;
            }
          });
          // let holidays = res?.items[0].holidaysArr;
          // res.holidays = holidays;
        });
        setHolidays(res?.holidaysArr);
        setAttendanceCount(newAttendanceCount);
        setToatalStudentCount(res?.students?.length || 0);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setPageLoader(false);
    }
  };

  useEffect(() => {
    if (batchState && selectedMonth && subjectsId) {
      getSheet(selectedMonth);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth, batchState, subjectsId, trigger]);

  const getSubjects = async (batchId: string) => {
    try {
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_BATCH_WISE_SUBJECTS + "?",
        {
          batchId: batchState,
        }
      );
      if (res?.result) {
        // console.log(res,"thisisresssss")
        setSubjects(
          res?.result?.subjects?.map((item: any, index: number) => ({
            label: item?.name,
            value: item?.id,
          }))
        );
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  useEffect(() => {
    getSubjects(batchState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [batchState]);

  const renderItem = (date1: Dayjs, record: any) => {
    const date = date1.format("D");
    const day = date1.format("ddd");
    let attStatus =
      record?.records?.find((x: any) => x?.day === date)?.isPresent || "A";
    const cellDateFormatted = dayjs(
      dayjs(
        `${dayjs(selectedMonth).year()}-${
          dayjs(selectedMonth).month() + 1
        }-${Number(date)}`
      ).format("DD/MM/YYYY"),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");
    const enrollDateFormatted = dayjs(
      dayjs(record?.enrDate).format("DD/MM/YYYY"),
      "DD/MM/YYYY"
    ).format("YYYY-MM-DD");

    let isEnrolled =
      dayjs(cellDateFormatted).isSameOrAfter(enrollDateFormatted);
    let isFuture = dayjs(cellDateFormatted).isSameOrAfter(dayjs());
    let isHoliday =
      holidays?.some((h: any) => dayjs(h.date).isSame(date1, "day")) ?? false;
    // console.log("isHoliday",date,isHoliday);

    const isLeave = record?.leave?.some((leave: any) => {
      const leaveFromDate = dayjs(leave.date.from).format("YYYY-MM-DD");
      const leaveToDate = dayjs(leave.date.to).format("YYYY-MM-DD");

      return (
        (dayjs(leaveFromDate).isSameOrBefore(cellDateFormatted) &&
          dayjs(leaveToDate).isSameOrAfter(cellDateFormatted)) ||
        (dayjs(leaveFromDate).isSameOrAfter(dayjs().format("YYYY-MM-DD")) &&
          dayjs(cellDateFormatted).isSameOrAfter(leaveFromDate))
      );
    });

    return (
      <p style={{ textAlign: "center" }}>
        {!isFuture && isEnrolled ? (
          <span
            style={{
              fontWeight: day === "Sun" || day === "Sat" ? "bolder" : "",
              padding: "8px 6px",
              color: getColor(attStatus, isLeave, isHoliday, day),
            }}
          >
            {attStatus === "P"
              ? "P"
              : day === "Sun" || day === "Sat" || isHoliday
              ? "H"
              : isLeave
              ? "FL"
              : attStatus === "A"
              ? "A"
              : "-"}
          </span>
        ) : isLeave || day === "Sun" || day === "Sat" ? (
          <span
            style={{
              fontWeight: day === "Sun" || day === "Sat" ? "bolder" : "",
              padding: "8px 6px",
              color: getColor(attStatus, isLeave, isHoliday, day),
            }}
          >
            {attStatus === "P"
              ? "P"
              : day === "Sun" || day === "Sat" || isHoliday
              ? "H"
              : isLeave
              ? "FL"
              : attStatus === "A"
              ? "A"
              : "-"}
          </span>
        ) : (
          "-"
        )}
      </p>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      width: 60,
      align: "start",
      fixed: "left",
      render: (text: any, record: any, index: any) => {
        return <p style={{ padding: "8px 6px" }}>{record?.sno}</p>;
      },
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "name",
      width: 280,
      fixed: "left",
      ellipsis: true,
      render: (text: any, record: any) => (
        <p style={{ padding: "8px 6px" }} title={record?.name}>
          {record?.fullName}
        </p>
      ),
    },
    ...monthDates.map((date: any, dateIndex: any) => {
      // Calculate the total present and absent for this date
      const totalPresent = attendanceCount[date.format("D")] || 0;
      const totalAbsent = toatalStudentCount - totalPresent;

      return {
        title: (
          <Popover
            content={
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Total Present:</span>
                  <span style={{ color: "green" }}>{totalPresent}</span>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Total Absent:</span>
                  <span style={{ color: "red" }}>{totalAbsent}</span>
                </div>
                <hr />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span>Total:</span>
                  <span>{toatalStudentCount}</span>
                </div>
              </div>
            }
            title="Attendance Count"
          >
            <div style={{ padding: "8px", textAlign: "center" }}>
              {date.format("D")}
            </div>
          </Popover>
        ),
        dataIndex: "",
        children: [
          {
            title: monthDays[dateIndex],
            render: (text: any, record: any) => renderItem(date, record),
          },
        ],
      };
    }),
  ];

  return (
    <>
      {openDrawer && (
        <MarkSubWiseAttendancebulk
          openDrawer={openDrawer}
          drawerClose={drawerClose}
          setTrigger={setTrigger}
        />
      )}
      <Card>
        <Spin
          spinning={pageLoader}
          indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        >
          <Row gutter={17}>
            <Col span={18}>
              <Space.Compact>
                <Button
                  className="cit-add-btn mb-1"
                  onClick={() => setOpenDrawer(true)}
                  style={{ marginBottom: "20px" }}
                  type="primary"
                >
                  <PlusOutlined /> Attendance
                </Button>
              </Space.Compact>
            </Col>
            <Col span={3}>
              <Select
                onChange={(e: any) => setSubjectsId(e)}
                placeholder="select subject"
                options={subjects}
                style={{ width: "100%" }}
              />
            </Col>
            <Col span={3}>
              <DatePicker
                style={{ width: "100%" }}
                allowClear={false}
                className="me-2"
                value={selectedMonth}
                picker="month"
                format={"MMM-YYYY"}
                disabledDate={(current: any) => {
                  return current && current > dayjs().endOf("month");
                }}
                onChange={(val: any) => {
                  calDate(
                    dayjs(val).startOf("month"),
                    dayjs(val).endOf("month")
                  );
                  setSelectedMonth(val);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Table
              className="cit-att-sheet"
              // loading={{ spinning: tableLoader, indicator: <Spin indicator={<LoadingOutlined />} /> }}
              columns={columns}
              dataSource={dataSource}
              bordered
              size="small"
              scroll={{ x: 1800, y: 550 }}
              style={{ minHeight: 700 }}
              pagination={false}
              // pagination={{
              //     showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`, selectPrefixCls: "custom-table-select",
              //     current: pagingItems.currentPage,
              //     pageSize: pagingItems.pageSize,
              //     showSizeChanger: true,
              //     total: pagingItems.totalRecords,
              //     pageSizeOptions: [15, 30, 50, 100],
              //     position: ["bottomRight"],
              // }}
              // onChange={(paging) => {
              //     paging && setPagingItems({ ...pagingItems, currentPage: paging?.current ?? 1, pageSize: paging?.pageSize ?? 15 })
              // }}
            />
          </Row>
        </Spin>
      </Card>
    </>
  );
};

export default SubjectWiseAttendance;

import React, { useEffect, useState } from "react";
import { Button, Col, message, Row, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormOutlined, PlusOutlined } from "@ant-design/icons";
import AddAppVersion from "./AddAppVersion";
import HTTPSCalls from "../../Services/HTTPCalls";

const AppVersionsList: React.FC = () => {
  const [dataSource, setDataSource] = useState<any>([]);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [recordData, setRecordData] = useState<any>({});

  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "2%",
      render: (index: any) => <p> {1}</p>,
    },
    {
      title: "Android",
      dataIndex: "androidVersion",
      key: "androidVersion",
      width: "5%",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Ios",
      dataIndex: "iosVersion",
      key: "iosVersion",
      width: "30%",
      ellipsis: true,
      showSorterTooltip: true,
      render: (text) => <p className="cursor">{text}</p>,
    },
    {
      title: "Action",
      dataIndex: "",
      key: "",
      width: "3%",
      ellipsis: true,
      align: "center",
      render: (text, record) => (
        <Space size={20}>
          <FormOutlined
            className="cursor"
            onClick={() => {
              setOpen(true);
              setRecordData(record);
            }}
          />
        </Space>
      ),
    },
  ];
  const getAppVersion = async () => {
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_APP_VERSION + "?",
        {}
      );
      if (res?.status) {
        // console.log(listParams.search, "gety");
        setDataSource([res?.result]);
      } else {
        message.warning(res?.message);
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAppVersion();
  }, [trigger]);

  const [open, setOpen] = useState(false);

  return (
    <>
      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        <Col span={24}>
          <Row gutter={14}>
            <Col lg={18}>
              {dataSource.length === 0 && (
                <Button
                  type="primary"
                  onClick={() => {
                    setOpen(true);
                    setRecordData([]);
                  }}
                >
                  <PlusOutlined />
                  App Version
                </Button>
              )}
            </Col>
            <Col lg={3}></Col>
            <Col lg={3}></Col>
          </Row>
          <Col span={24}>
            <Table
              rowKey={(record: any) => record?.id}
              className="pt-2"
              columns={columns}
              loading={loading}
              dataSource={dataSource}
            ></Table>
          </Col>
        </Col>
      </Row>
      {open && (
        <AddAppVersion
          open={open}
          setOpen={setOpen}
          setTrigger={setTrigger}
          trigger={trigger}
          recordData={recordData}
        />
      )}
    </>
  );
};

export default AppVersionsList;

import {
  Col,
  Row,
  Tabs,
} from "antd";
import {
  CalendarOutlined,
  CheckOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import AttendanceRequest from "./AttendanceRequest";
import SupportRequest from "./SupportRequest";
import LeaveRequestList from "./LeaveRequestList";

const RequestsTab = () => {
  return (
    <>
      <Row
        className="roundedCornerSmall bg-white"
        style={{ padding: "2px 16px 16px 16px" }}
      >
        <Col span={24}>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: "1",
                label: (
                  <span>
                    <CalendarOutlined /> Leave Application
                  </span>
                ),
                children: <LeaveRequestList />,
              },
              {
                key: "2",
                label: (
                  <span>
                    <CheckOutlined /> Attendance Request
                  </span>
                ),
                children: (
                  <>
                    <AttendanceRequest />
                  </>
                ),
              },
              {
                key: "3",
                label: (
                  <span>
                    <ContainerOutlined /> Support Request
                  </span>
                ),
                children: (
                  <>
                    <SupportRequest />
                  </>
                ),
              },
            ]}
            destroyInactiveTabPane
          />
        </Col>
      </Row>
    </>
  );
};

export default RequestsTab;

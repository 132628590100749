import * as React from "react";
import { useState, useEffect } from "react";
import {
  Layout,
  Row,
  Avatar,
  Popover,
  Button,
  Typography,
  Col,
  // Form,
  theme,
  Space,
  Select,
} from "antd";
import CitLogo from "../images/citlogo.svg";
import ArrowRight from "../images/ArrowRight.svg";
import "./MainLayout.css";
import Sidemenus from "./Sidemenus";
import { LogoutOutlined, UserOutlined, SyncOutlined } from "@ant-design/icons";
import { AuthService } from "../Services/AuthService";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../Context/UserContext";
import UnAuthRoute from "./UnAuthRoute";
import PageRoute from "./PageRoute";
import { MasterTypes } from "../Constant/Enums";
import { useGetMaster } from "../Utilities/tanStackQueries";
import { showBatchFilter } from "../Utilities/Utils";
import { useDispatch, useSelector } from "react-redux";
import { resetBatch, setBatch } from "../Store/Slice/batchSlice";
import { resetUserData } from "../Store/Slice/userSlice";
import { ResetStudentAtt } from "../Store/Slice/studentAttSlice";
import ChangePasswordModal from "./ChangePasswordModal";
import { userData as UD } from "../Store/Slice/userSlice";

const { Header, Sider, Content } = Layout;

const MainLayout: React.FC = () => {
  const {
    isAuthenticated,
    currentUser,
    // getEmployeeId,
  } = useUser();
  // const { getStudentOptions } = React.useContext(ApplicationContext);
  const userName: any = currentUser?.name.get();
  // const userId: any = currentUser?.id.get();
  // const employeeId: any = getEmployeeId();
  // const [imagePath, setImagePath] = useState(null);
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [selectedBatch, setSelectedBatch] = useState<{
    label: string;
    value: string;
  }>({ label: "All", value: "" });
  const { data: batchMaster, isLoading: isMasterLoading } = useGetMaster({
    start: 0,
    length: 999,
    MasterType: MasterTypes.Batch,
  });
  const dispatch = useDispatch();
  const showModal = () => {
    setOpenModel(true);
  };
  const userData = useSelector(UD);

  const contentUser = (
    <div style={{ width: 295 }}>
      <Row justify="center" className="mt-3 mb-2">
        {userData?.userImage?.path ? (
          <Avatar
            src={userData?.userImage?.path}
            className="avatar-user cursor"
            onClick={() => console.log("")}
          />
        ) : (
          <Avatar
            // icon={<UserOutlined />}
            style={{
              // marginLeft: collapsed ? "18px" : "15px",
              backgroundColor: generateColor(userName),
              color: "#fff",
              cursor: "pointer",
              border: "2px solid #eaf2ff",
            }}
            className="avatar-user cursor"
          >
            {(() => {
              const [firstName = "", lastName = ""] = (userName || "").split(
                " "
              );
              return `${firstName[0] || ""}${lastName[0] || ""}`.toUpperCase();
            })()}
          </Avatar>
        )}
      </Row>
      <Row justify="center">
        <Typography.Title level={5} className="mb-2" style={{ margin: 0 }}>
          {userName}
        </Typography.Title>
      </Row>
      <Row justify="center" className="mb-2">
        {currentUser?.email.get()}
      </Row>

      <Row
        className="py-2"
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align="middle"
      >
        <Col className="cursor">
          <Button
            type="text"
            size="small"
            className="linkcolor"
            icon={<SyncOutlined />}
            onClick={showModal}
          >
            Change Password
          </Button>
        </Col>
      </Row>

      <Row
        className="py-2"
        style={{ paddingLeft: 9, paddingRight: 9 }}
        align="middle"
      >
        <Col className="cursor">
          <Button
            type="text"
            size="small"
            className="linkcolor"
            icon={<LogoutOutlined rotate={-90} />}
            onClick={() => {
              UserLogOut();
            }}
          >
            Logout
          </Button>
        </Col>
      </Row>
    </div>
  );

  const navigate = useNavigate();
  const location = useLocation();
  // const [form] = Form.useForm();
  const UserLogOut = () => {
    setOpenPopover(false);
    dispatch(resetUserData());
    dispatch(resetBatch());
    dispatch(ResetStudentAtt());
    AuthService.logout().then((user) => {
      navigate("/login");
      // setImagePath(null);
    });
  };
  const [collapsed, setCollapsed] = useState(false);
  // const style = {
  //   position: "absolute",
  //   top: window.innerWidth <= 1250 ? "40px" : "65px",
  //   width: "255px",
  //   height: "100%",
  //   zIndex: "1",
  // };
  const [width, setWidth] = useState(window.innerWidth);
  const [slide, setSlide] = useState(false);
  const [serachInput, setSearchInput] = useState(false);
  // const [openChangePassword, setOpenChangePassword] = useState(false);
  // const [btnLoading, setBtnLoading] = useState(false);
  const [batchOptions, setBatchOptions] = useState([
    { label: "All", value: "" },
  ]);

  // const onCollapse = () => {
  //   if (window.innerWidth >= 1250) {
  //     setCollapsed((x) => !x);
  //   }
  //   if (window.innerWidth <= 1250) {
  //     setSlide((x) => !x);
  //   }
  // };

  const handleResize = () => {
    setWidth(window.innerWidth);
    if (window.innerWidth < 1250) {
      setSlide(false);
    }
    if (window.innerWidth > 1250) {
      setSearchInput(false);
    }
  };
  // const {
  //   token: { colorBgContainer },
  // } = theme.useToken();
  const [openPopover, setOpenPopover] = useState(false);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    } else {
      // getStudentOptions();
    }
    window.addEventListener("resize", handleResize, false);
  }, [navigate]);

  const logoStyle = {
    display: "flex",
    justifyContent: collapsed ? "center" : "start",
    paddingLeft: collapsed ? "0px" : "20px",
    alignItems: "center",
    height: "64px",
    marginBottom: "24px",
  };

  const getBatchOptions = () => {
    const newBatchOptions =
      batchMaster?.result?.items.map((b: any) => ({
        label: b?.name,
        value: b?.id,
      })) || [];

    setBatchOptions((prev) => {
      const uniqueOptionsMap = new Map();

      prev.forEach((option) => uniqueOptionsMap.set(option.value, option));

      newBatchOptions.forEach((option: any) =>
        uniqueOptionsMap.set(option.value, option)
      );

      return Array.from(uniqueOptionsMap.values());
    });
  };
  useEffect(() => {
    getBatchOptions();
  }, [batchMaster]);

  const [rights, setRights] = useState<any>(null);
  // const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    if (userData?.rights) {
      setRights(userData?.rights);
    } else {
      setRights(null);
    }
  }, [userData, userData?.rights]);

  function generateColor(name: any) {
    const colors = ["#F56A00", "#7265E6", "#00A2AE", "#FFBF00", "#87D068"];
    if (!name) return "#ccc";
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  }
  return (
    <React.Fragment>
      <ChangePasswordModal openModel={openModel} setOpenModel={setOpenModel} />
      {isAuthenticated() ? (
        <Layout hasSider>
          {width >= 1250 && (
            <Sider
              className="sidebar-transition"
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{
                height: "100vh",
                position: "fixed",
                // backgroundColor: '#F9F9FB',
                backgroundColor: "white",
                //  borderRight: '1px solid #D8D9E0',
                // overflowY:"auto"
              }}
            >
              <Space align="center" style={{ padding: collapsed ? 20 : 4 }}>
                <img
                  src={
                    !collapsed
                      ? "https://cit.capsitech.com/images/Citlogo.svg"
                      : CitLogo
                  }
                  alt="no img"
                />
              </Space>
              {/* <div>
                <div style={{ position: 'fixed', zIndex: 1 }}>
                  <img
                    src={CitLogo}
                    // onClick={() => setCollapsed(!collapsed)}
                    style={{
                      padding: '22px 0px 0px 28px',
                      transitionDuration: '0.18s',
                      cursor: 'pointer'
                    }}
                    alt='img'
                  />
                </div>

                <img 
                  src={notificationImg}
                  alt='notification'
                  style={{ padding: '28px 0px 0px 189px',cursor:"pointer" }}
                />
              </div> */}
              <Button
                type="text"
                shape="circle"
                icon={
                  collapsed ? (
                    <img src={ArrowRight} alt="no img" />
                  ) : (
                    <img src={ArrowRight} alt="no img" />
                  )
                }
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  position: collapsed ? "fixed" : "fixed",
                  width: 34,
                  height: 34,
                  left: collapsed ? 72 : 228,
                  top: 15,
                  transform: collapsed ? "rotate(180deg)" : "unset",
                }}
              />
              {/* <div
                style={{
                  marginTop: '20px',
                  padding: '7px 15px 0px 15px',
                  overflowY: 'auto',
                }}
              >
                {collapsed ? (
                  <>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                      <img src={notificationImg} className='iconHover' />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '12px',
                        cursor:'pointer',
                      }}
                    >
                      <SearchOutlined
                        style={{ marginTop: '5px', marginBottom: '-10px' }}
                      />
                    </div>
                    <Divider />
                  </>
                ) : (
                  <Input
                    placeholder='Search'
                    prefix={<SearchOutlined />}
                  // style={{ marginBottom: '7px' }}
                  />
                )}
              </div> */}
              <div
                style={{
                  padding: collapsed ? "0px 4px 0px 4px" : "0px 15px 0px 15px",
                  marginTop: collapsed ? "-38px" : "",
                }}
              >
                <Sidemenus
                  isCollapse={collapsed}
                  onsetSlide={setSlide}
                  styles={undefined}
                  isSlide={undefined}
                />
              </div>

              <div>
                <div
                  style={{
                    bottom: 0,
                    position: "fixed",
                    width: collapsed ? "90px" : "242px",
                    transition: "width 0.35s ease",
                    padding: 10,
                  }}
                ></div>
              </div>
            </Sider>
          )}
          <Layout
            style={
              collapsed
                ? {
                    transition: "margin-left 0.6s ease",
                    marginLeft: "90px",
                    height: "100vh",
                  }
                : {
                    height: "100vh",
                    transition: "margin-left 0.6s ease",
                    marginLeft: "242px",
                  }
            }
          >
            <Header
              //  className="ttp-layout"
              style={{
                // padding: 0,
                padding: "15px 20px",
                // borderBottom: '1px solid #D8D9E0',
                backgroundColor: "white",
                position: "sticky",
                // position:'fixed',
                display: "flex",
                justifyContent: "space-between",
                height: "64px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "10px",
                  paddingLeft: "18px",
                }}
              >
                <Typography.Title
                  style={{
                    color: "#7B8CAB",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  Welcome {userName}
                </Typography.Title>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {showBatchFilter(location.pathname) && (
                  <Select
                    className="custom-select"
                    loading={isMasterLoading}
                    style={{ width: "206px" }}
                    placeholder="Select Batch"
                    filterOption={(input, option: any) =>
                      (option?.label ?? "")
                        ?.toLowerCase()
                        .includes(input?.trim().toLowerCase())
                    }
                    options={batchOptions}
                    value={selectedBatch}
                    showSearch
                    onSelect={(val: any) => {
                      dispatch(setBatch(val));
                      setSelectedBatch(val);
                    }}
                  />
                )}
                <Popover
                  placement="bottomRight"
                  content={contentUser}
                  trigger="click"
                  open={openPopover}
                  onOpenChange={(val: boolean) => {
                    setOpenPopover(val);
                  }}
                >
                  {/* <Avatar
                      src={<UserOutlined />}
                      icon={<UserOutlined />}
                      className="avatar-user"
                      style={{ marginLeft: collapsed ? "18px" : "15px" }}
                      onClick={() => {
                        setOpenPopover(true);
                      }}
                    /> */}
                  <Avatar
                    className="avatar-user"
                    style={{
                      marginLeft: collapsed ? "18px" : "15px",
                      // backgroundColor: generateColor(userName),
                      color: "#fff",
                      cursor: "pointer",
                      border: "2px solid #eaf2ff",
                    }}
                    onClick={() => {
                      setOpenPopover(!openPopover);
                    }}
                    // src={userData?.userImage?.path}
                    icon={<UserOutlined />}
                  >
                    {/* {(() => {
                      const [firstName = "", lastName = ""] = (
                        userName || ""
                      ).split(" ");
                      return `${firstName[0] || ""}${
                        lastName[0] || ""
                      }`.toUpperCase();
                    })()} */}
                  </Avatar>
                </Popover>
              </div>

              {/* <img src={ArrowRight} alt='arrow' style={{paddingTop:"5px"}}/> */}
            </Header>
            {/* <Content
            style={{
              marginTop:"75px",
              height: `calc(100vh-75px)`,
              padding: 24,
              backgroundColor:"white",
              // background: colorBgContainer 
              // borderRadius: borderRadiusLG,
            }}
          >
            {<PageRoute />}
          </Content> */}
            {/* <Content
        style={{height: `calc(100vh-75px)`,backgroundColor:"white"}}>
       lorem500
        </Content> */}
            <Content
              className="content-transition"
              style={{
                // height: `(100%-64px)`,
                paddingTop: "15px",
                overflowY: "auto",
                backgroundColor: "#f3f4fa",
                padding: 18,
                paddingBottom: "0px",
              }}
            >
              {<PageRoute />}
              {/* {rights?.enquiries === AccessCodes.enquiries &&<CallDetails/>} */}
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Layout className="site-layout">
          <Content>
            <UnAuthRoute />
          </Content>
        </Layout>
      )}
    </React.Fragment>
  );
};
export default MainLayout;

import {
  Button,
  Card,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Row,
  Select,
} from "antd";
import * as React from "react";
import { useEffect } from "react";
import { MasterTypes } from "../../../Constant/Enums";
import { CloseOutlined, MinusOutlined } from "@ant-design/icons";
import HTTPSCalls from "../../../Services/HTTPCalls";

interface IAddEditSubject {
  data: any;
  form: FormInstance<any>;
}
const AddEditSubject = ({ data, form }: IAddEditSubject) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      lg: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
      lg: { span: 24 },
    },
  };
  useEffect(() => {
    if (!!data) form.setFieldsValue({ ...data });
  }, []);
  
  const [subjectTypeOpt, setSubjectTypeOpt] = React.useState<any[]>([]);
  // const [subTypeId, setSubTypeId] = React.useState<string>("");
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_MASTER_TYPE + "?", {
      masterType: MasterTypes.SubjectTypes,
    });
    if (res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      setSubjectTypeOpt(() =>
        result?.map((item: any) => {
          return {
            value: item?.id,
            label: item?.name,
          };
        })
     
      );
    } else {
      message.error(res?.message);
    }
  };
  useEffect(() => {
    getMaster();
  }, []);
  return (
    // <Form
    //         form={form}
    //         name="department-form"
    //         className="DrawerPadding"
    //         colon={false}
    //         autoComplete="off"
    //         size="small"
    //         style={{ padding: 16 }}
    //         labelAlign="left"
    //         requiredMark={false}
    //         {...formItemLayout}
    //       >
    //         <Form.Item
    //           label="Name"
    //           name="name"
    //           rules={[
    //             { required: true, message: "Please enter department name!" },
    //           ]}
    //         >
    //           <Input
    //             autoFocus
    //             placeholder="Enter Department Name"
    //             size="middle"
    //             onInput={(e: any) =>
    //             (e.target.value =
    //               e.target.value.length > 1
    //                 ? e.target.value
    //                 : e.target.value.toUpperCase())
    //             }
    //           />
    //         </Form.Item>
    //         <Form.Item
    //           label="Code"
    //           name="code"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please enter department code name!",
    //             },
    //           ]}
    //         >
    //           <Input
    //             autoFocus
    //             placeholder="Enter Department Code Name"
    //             size="middle"
    //             onInput={(e: any) =>
    //             (e.target.value =
    //               e.target.value.length > 1
    //                 ? e.target.value
    //                 : e.target.value.toUpperCase())
    //             }
    //           />
    //         </Form.Item>
    //       </Form>

    <Form
      form={form}
      name="Subjects-form"
      className="DrawerPadding"
      colon={false}
      autoComplete="off"
      {...formItemLayout}
      labelAlign="left"
      labelCol={{ span: 4 }}
      requiredMark={false}
    >
      <Form.Item
        label="Department"
        name="department"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Department"} disabled />
      </Form.Item>
      <Form.Item
        label="Course"
        name="course"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Course"} disabled />
      </Form.Item>
      <Form.Item
        label="Schema"
        name="schema"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Schema"} disabled />
      </Form.Item>
      <Form.Item
        label="Semester"
        name="semester"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Select placeholder={"Semester"} disabled />
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "field is required" }]}
      >
        <Input
          autoFocus
          placeholder="Enter Name"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.length > 1
                ? e.target.value
                : e.target.value.toUpperCase())
          }
        />
      </Form.Item>
      <Form.Item
        label="Module Code"
        name="code"
        // rules={[{ required: true, message: "field is required" }]}
      >
        <Input
          autoFocus
          placeholder="Enter Code"
          onInput={(e: any) =>
            (e.target.value =
              e.target.value.length > 1
                ? e.target.value
                : e.target.value.toUpperCase())
          }
        />
      </Form.Item>
      <Form.Item
        label="Subject Types"
        name="subjectTypes"
        // rules={[{ required: true, message: "field is required" }]}
      >
        <Select
          labelInValue
          options={subjectTypeOpt}
          
        />
      </Form.Item>
      <Form.List name="units">
        {(fields, { add, remove }, index) => (
          <>
            <Button
              type="primary"
              ghost
              onClick={() => add()}
              style={{ marginBottom: "10px" }}
            >
              + Add Item
            </Button>
            <Row gutter={[10, 10]} key={`${index}`}>
              {fields.map((field) => (
                <Col span={12} key={field.key}>
                  <Card
                    style={{ width: "100%" }}
                    size="small"
                    title={`UNIT ${field.name + 1}`}
                    extra={
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    }
                  >
                    <Form.Item
                      name={[field.name, "chapter"]}
                      rules={[{ required: true, message: "field is required" }]}
                    >
                      <Input
                        placeholder="Chapter Name"
                        variant={"borderless"}
                        style={{
                          borderBottom: "1px solid #4096FF",
                          fontWeight: "600",
                          // borderRadius: "0px",
                        }}
                      />
                    </Form.Item>
                    {/* Nest Form.List */}
                    <Form.Item>
                      <Form.List name={[field.name, "topics"]}>
                        {(subFields, subOpt) => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: 16,
                            }}
                          >
                            {subFields.map((subField) => (
                              <Row key={subField.key} gutter={[4, 0]}>
                                <Col span={22}>
                                  <Form.Item
                                    style={{ margin: 0 }}
                                    name={[subField.name, "topic"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "field is required",
                                      },
                                    ]}
                                  >
                                    <Input
                                      prefix={
                                        <span style={{ marginRight: "10px" }}>
                                          {field.name + 1}.{subField.name + 1}
                                        </span>
                                      }
                                      placeholder="Topics"
                                      variant={"borderless"}
                                      style={{
                                        borderBottom: "1px solid grey",
                                        borderBottomStyle: "dotted",
                                        borderRadius: "0px",
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <MinusOutlined
                                    onClick={() => {
                                      subOpt.remove(subField.name);
                                    }}
                                  />
                                </Col>
                              </Row>
                            ))}

                            <Button
                              type="dashed"
                              onClick={() => subOpt.add()}
                              block
                            >
                              + Topics
                            </Button>
                          </div>
                        )}
                      </Form.List>
                    </Form.Item>
                  </Card>
                </Col>
              ))}
            </Row>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default AddEditSubject;

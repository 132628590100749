import { Button, Col, message, Modal, Row, Table } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType, TableProps } from "antd/es/table";
import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ActionBioTypeEnum } from "../../../Constant/Enums";

export const pageSizeOption: number[] = [10, 15, 30, 50, 100, 200];

interface IUserTable {
  machineTitle: string;
  machineNo: string;
}

type TableRowSelection<T extends object = object> = TableProps<T>['rowSelection'];

export const AssignDeviceStudents = (props: { machineCode: string , deviceRecord:any }) => {
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [modal, contextHolder] = Modal.useModal();
  const [search,setSearch]=useState<any>("");
  const [refrest,setRefrest]=useState(false);
  const [studentIds,setStudentIds]=useState<any>([]);
  const [actionBioType, setActionBioType] = useState<ActionBioTypeEnum>(ActionBioTypeEnum.Undefined);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const columns: ColumnsType<any> = [
    {
      title:"Student ID",
      dataIndex:"studentId",
      key: "studentId",
      render: (text, record) => <>{text}</>,
    },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Batch",
      dataIndex: "batch",
      key: "batch",
      ellipsis: true,
      render: (text, record: any) => <p>{record?.batch?.name}</p>,
    },

  ];
  useEffect(() => {
    getList();
  }, [refrest]);

  const getList = async () => {
    // setIsTable(true)
    try {
      setLoading(true);
      let res = await HTTPSCalls.GET(
        HTTPSCalls.ENDPOINTS.GET_STUDENT_WITHOUT_BIO_DEVICE_ASSIGN + "?",
        { machineCode: props?.machineCode, search}
      );
      if (res?.result) {
        setDataSource(res?.result);
        setStudentIds(res?.result?.map((student:any)=>({id:student?.id})))
      }
    } catch (error) {
      console.log("errror", error);
    } finally {
      setLoading(false);
    }
  };
  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;
  const AssignDevice = async (actionType:ActionBioTypeEnum) => {
    setLoading(true);
    try {
      const selectedStudentIds= selectedRowKeys.map((key:any)=>({id:key}));
      let res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.SAVE_BIOMETRIC_CHANGE,
        {},
        {
          machineTitle: props?.deviceRecord?.machineTitle,
          machineCode: props?.machineCode,
          studentIds: selectedStudentIds,
          actionBioType: actionType,
        }
      );
      if (res?.result) {
        setRefrest((pre)=>!pre);
        message.success("Device assigned successfully.");
      } else {
        message.error("Failed to assign device. Please try again.");
      }
    } catch (error) {
      console.error("Error assigning device:", error);
      message.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false)
    }
  };
  
  const confirm=(actionType:ActionBioTypeEnum)=>{
    modal.confirm({
      title:"Confirmation",
      icon:<ExclamationCircleOutlined/>,
      content:'Are you sure to Assign access only for selected students?',
      okText:"Yes",
      cancelText:'No',
      onOk:()=> AssignDevice(actionType) 
    })
  }

  const handleSearch=(e:any)=>{
    setSearch(e)
    setRefrest((pre:any)=>!pre)
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col lg={21}></Col>
            <Col lg={3}>
              <Search
              onSearch={(e:any)=>handleSearch(e)}
                // onPressEnter={(e:any)=>handleSearch(e)}
                placeholder="Search Name..."
                allowClear
                className="att-search-input mb-1"
                // onSearch={(val: string) => changeListParams("nameSearch", val)}
                // onChange={(e: any) =>
                //   e.target.value === "" ? setListParamsAndRefresh("") : null
                // }
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Col span={24} style={{ paddingTop: "12px" }}>
            <Table 
              rowKey={(record) => record?.id}
              rowSelection={rowSelection}
              size="small"
              dataSource={dataSource}
              loading={loading}
              columns={columns}
              scroll={{ x: 1100, y: "65vh" }}
              pagination={false}
            />
          </Col>
          <Row justify={"end"} className="pt-3" gutter={15}>
            <Col>
               {hasSelected ? `Selected ${selectedRowKeys.length} students` : null}
            </Col>
            <Col>
              <Button  type="primary" onClick={()=>confirm(ActionBioTypeEnum.AssignAccessOnly)} disabled={!hasSelected} >
                Assign for access only
              </Button>
            </Col>
            <Col>
            <Button   type="primary" onClick={()=>confirm(ActionBioTypeEnum.AssignAccess)} disabled={!hasSelected}>
                Assign for access & attendance
             </Button>
             
            </Col>
          </Row>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default AssignDeviceStudents;

import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Drawer,
  Form,
  Row,
  Spin,
  Table,
  message,
} from "antd";
import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
// import "./../../Common/Common.css"
import HTTPSCalls from "../../../Services/HTTPCalls";
import { MasterTypes } from "../../../Constant/Enums";
import AddEditFaculty from "./AddEditFaculty";
import { adminContext } from "../../../Common/PageRoute";
import * as React from "react";
import { IApiResponse } from "../../../Utilities/ApiUtility.axios";

const FacultyMapWithDept = () => {
    const { openNotification } = React.useContext(adminContext);
    const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const [show, setShow] = useState("");
  const [editData, setEditData] = useState<any | undefined>(undefined)
  
  const [selectedRecord, setSelectedRecord] = useState<{
    department: any;
    teacher: any;
  }>({
    teacher: undefined,
    department: undefined,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const [details, setDetails] = useState<{
    departments: any;
    teachers: any;
  }>({
    teachers: [],
    departments: [],
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [apiDataLoading, setApiDataLoading] = useState<boolean>(false);
  const commonColumns: any = [
    {
      title: "CreateAt",
      dataIndex: "CreatedAt",
      render: (_: any, record: any) => (
        <div className="createUpdateDate">
          {
            record?.createdBy ? dayjs(record?.createdBy?.date)?.format("DD MMM YYYY HH:mm") : "-"
          }
        </div>
      ),
    },
    {
      title: "UpdatedAt",
      dataIndex: "updatedAt",
      render: (_: any, record: any) => (
        <div className="createUpdateDate">
          {
            record?.updatedBy ? dayjs(record?.updatedBy?.date)?.format("DD MMM YYYY HH:mm") : "-"
          }
        </div>
      ),
    }

  ]


  const departmentColumns: any = [
    {
      title: "Name",
      dataIndex: "name",
      render: (_: any, record: any) => (
        <div className={record?.id === selectedRecord?.department?.id ? "selectedRecord" : ""}>
          {record?.name}
        </div>
      ),
    },
    ...commonColumns,
  ];
  const facultyColumns: any = [
    {
      title: "Name",
      dataIndex: "teacher",
      render: (text: any, record: any) => (
        <div className={record?.id === selectedRecord?.teacher?.id ? "selectedRecord" : ""}>
            {console.log(record)}
          {text?.name}
        </div>
      ),
    },
    ...commonColumns,
  ];

  const getComponent = (): ReactNode => {
    if (show === "faculty")
      return (
    <AddEditFaculty
          data={{
            ...editData,
            department: {
              label: selectedRecord?.department?.name,
              value: selectedRecord?.department?.id,
            },
          }}
          form={form}
        />
      );
    // else 
    //   return (
    // <AddEditFaculty
    //       data={{
    //         ...editData,
    //         department: {
    //           label: selectedRecord?.department?.name,
    //           value: selectedRecord?.department?.id,
    //         },
    //       }}
    //       form={form}
    //     />
    //   );
  };

  const getTitle = (): string => {
    let str: string = "";
    if (show === "department") str = "Department";
    if (show === "faculty") str = "Faculty Map With Dept";

    return str;
  };

  // useEffect(()=>{
  //   console.log(selectedRecord,"selete");
  // },[selectedRecord])
  const saveUpdateFaculty = () => {
    form.validateFields().then(async (value: any) => {
    setBtnLoading(true);
    let facultyDetail = {
      masterType: MasterTypes.FacultyMapWithDept,
      ...(editData?.id && { id: editData?.id }),
      teacher:{
        id:value?.facultyName?.key,
        name:value?.facultyName?.label,
      }, 
      department: {
        id: value?.department?.value,
        name: value?.department?.label
      }
    };
    HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.POST_MASTER,
      {},
      {
        ...facultyDetail,
      }
    ).then((res: IApiResponse) => {
      console.log("Post res = ", res);
      if (res?.status) {
        // onClose();
        openNotification(
          "success",
          editData?.id
            ? "Facutly updated successfully"
            : "Facutly saved successfully"
        );
        setBtnLoading(false);
        closeDrawer(true);
        setRefresh(!refresh)
        // props.setIsGradeModel(false);
        // setCategoryId(null);
        form.resetFields();
        // refetch();
      } else {
        openNotification("error", res.message);
        setBtnLoading(false);
      }
    });
    console.log(value, "Grade values");
  })};


  const closeDrawer = (isChange: boolean) => {
    setShow("");
    form.resetFields();
    if (!isChange)
      setEditData(undefined)
  };

  const getDetails = async () => {
    setApiDataLoading(true);
    let res = await HTTPSCalls.POST(
      HTTPSCalls.ENDPOINTS.GET_FACULTY_DETAILS,
      {},
      {
        departmentId: selectedRecord?.department?.id,
        teacherId: selectedRecord?.teacher?.id,
      }
    );

    if (!res?.message) {
      let result = res?.result;
      setSelectedRecord((pre: any) => ({
        ...pre,
        department: Array.isArray(result?.departments)
          ? getSelectedId(result?.departments, selectedRecord?.department)
          : undefined,
        teacher: Array.isArray(result?.teacher)
          ? getSelectedId(result?.teacher, selectedRecord?.teacher)
          : undefined,
      }));
      setDetails({
        departments: result?.departments?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
        teachers: result?.teachers?.map((itm: any) => ({
          ...itm,
          key: itm?.id,
        })),
      });
      setApiDataLoading(false);
    } else {
      message.error(res?.message);
      setApiDataLoading(false);
    }
  };
  
  const getSelectedId = (a: any, b: any) => {
    let result: any = undefined
    if (editData && editData?.id === b?.id) {
      result = a?.find((x: any) => x?.id === editData?.id)
    }
    else
      result = b ? b : a[0];
    return result
  };

  useEffect(() => {
    getDetails();
  }, [refresh]);

  const departmentRowSelection: any = useMemo(() => {
    return {
      type: "radio",
      defaultSelectedRowKeys: [selectedRecord?.department?.id],
      onChange: (keys: any, record: any) => {
        setSelectedRecord({
          department: record[0],
          teacher: undefined,
        });
        setRefresh(!refresh);
      },
    };
  }, [apiDataLoading]);

//   const facultyRowSelection: any = useMemo(() => {
//     return {
//       type: "radio",
//       defaultSelectedRowKeys: [selectedRecord?.teacher?.id],
//       onChange: (keys: any, record: any) => {
//         setSelectedRecord({
//           ...selectedRecord,
//           teacher: record[0]
//         });
//         setShow("faculty");
//         // setRefresh(!refresh);
//       },
//     };
//   }, [apiDataLoading]);



  const isEmpty = useCallback((source: any) => {
    if (Array.isArray(source)) return source.length <= 0
    return !source ? true : false
  }, [])

  return (
    <>
      <Spin
        spinning={apiDataLoading}
        indicator={<LoadingOutlined />}
        style={{ height: "100%" }}
      >
        <ConfigProvider theme={{ components: { Table: { borderRadius: 0, headerBorderRadius: 0, fontSize: 13 } } }}>
          <Row gutter={[5, 24]}>
            <Col span={8}>
              <Card
                title="Department"
                size="small"
                className="tableCard"
              >
                <Table
                  key={apiDataLoading ? "departmentLoading" : "Department"}
                  rowSelection={departmentRowSelection}
                  columns={departmentColumns}
                  dataSource={details?.departments}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
            <Col span={8}>
              <Card
                title="Faculty"
                size="small"
                className="tableCard"
                extra={
                    <Button
                      size="small"
                      icon={<PlusOutlined />}
                      type="primary"
                      onClick={() => {
                        setShow("faculty");
                      }}
                    >
                      Add
                    </Button>
                }
              >
                <Table
                  key={apiDataLoading ? "courseLoading" : "Course"}
                //   rowSelection={facultyRowSelection}
                  columns={facultyColumns}
                  dataSource={details?.teachers}
                  size="small"
                  pagination={false}
                />
              </Card>
            </Col>
          </Row>
        </ConfigProvider>

        <Drawer
          placement="right"
          
          width={getTitle() === "Subject" ? "55%" : 500}
          maskClosable={false}
          closeIcon={false}
          extra={
            <CloseOutlined
              style={{ fontSize: "16px" }}
              className=""
              onClick={() => closeDrawer(false)}
            />
          }
          footer={
            <Row justify="end">
              <Button
                onClick={() => closeDrawer(false)}
                disabled={btnLoading}
                className="ttp-btn-light btn-m-sm"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="btn-m-sm"
                loading={btnLoading}
                onClick={saveUpdateFaculty}
              >
                {editData?.id ? "Update" : "Save"}
              </Button>
            </Row>
          }
          title={getTitle()}
          onClose={() => {
            setShow("");
          }}
          open={!!show}
        >
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined />}
            style={{ height: "100%" }}
          >
            {getComponent()}
          </Spin>
        </Drawer>
      </Spin>
    </>
  );
};

export default FacultyMapWithDept;
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  TimePicker,
  UploadProps,
} from "antd";
import dayjs from "dayjs";
import * as React from "react";
import { useContext, useState, useEffect } from "react";
import HTTPSCalls from "../../Services/HTTPCalls";
import {
  AttachmentsCategory,
  CommunicationType,
  MasterTypes,
  meetingTypeOptions,
  OutcomeType,
  outcomeTypeOptions,
} from "../../Constant/Enums";
import DNCModel from "./DNCModel";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/es/upload";
import { UserContext } from "../../Context/UserContext";

const MeetingModel: React.FC<{
  open: boolean;
  id: string;
  type: number;
  onCancel: (rec: boolean, isUploadAtt: boolean) => void;
  apiData: any;
}> = (props) => {
  // const {} = useContext(UserContext)
  let user: any = JSON.parse(localStorage.getItem("tp-user") + "");
  const [form] = Form.useForm();
  const [createTask, setCreateTask] = React.useState<boolean>(false);
  const [textValue, setTextValue] = React.useState<string>("");
  const [buttonLoading, setButtonLoading] = React.useState<boolean>(false);
  const [platFormData, setPlatFormData] = React.useState<any[]>([]);
  const [reminderData, setReminderData] = React.useState<any[]>([]);
  const [dncModelOpen, setDncModelOpen] = React.useState<boolean>(false);
  const [attachmentList, setAttachmentList] = React.useState<any[]>([
    {
      fileType: "",
      fileName: {
        id: "",
        name: "",
        contentType: "",
        length: 0,
        path: "",
      },
      description: "",
    },
  ]);
  const [assignees, setAssignees] = useState([]);
  useEffect(() => {
    getList();
  }, []);
  const getList = async () => {
    let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.GET_USER_LIST, {});
    if (res?.result?.items?.length > 0) {
      setAssignees(
        res?.result?.items?.map((item: any) => ({
          value: item?.id,
          label: item?.name?.first,
          // role: item?.role,
        }))
      );
    }
  };
  const getMaster = async () => {
    let res = await HTTPSCalls.GET(
      HTTPSCalls.ENDPOINTS.GET_MASTER_ALL + "?",
      {}
    );
    if (/*res?.errors === null &&*/ res?.result?.items.length >= 0) {
      const result = res?.result?.items;
      const transformedItems = result.map((item: any) => {
        return {
          masterType: item.masterType,
          items: item.items.map((innerItem: any) => {
            return {
              value: innerItem.id,
              label: innerItem.name,
            };
          }),
        };
      });

      setPlatFormData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Platform
        )?.[0]?.items
      );
      // setCounselorData(
      //   transformedItems?.filter(
      //     (x: any) => x?.masterType === MasterTypes.Counselor
      //   )?.[0]?.items
      // );
      setReminderData(
        transformedItems?.filter(
          (x: any) => x?.masterType === MasterTypes.Reminder
        )?.[0]?.items
      );
      //console.log(transformedItems, "test");
    } else {
      message.error(res?.message);
    }
  };
  //console.log(counselorData, "testcou");

  React.useEffect(() => {
    getMaster();
  }, []);
  // console.log("counselorData = = ", counselorData)
  const onSubmit = () => {
    form.validateFields().then(async (values: any) => {
      console.log("validateValues = ", values);
      setButtonLoading(true);
      let formValue = {
        ...values,
        enquiryId: props?.apiData?.id,
        type: CommunicationType.Meeting,
        attachmentsCategory: AttachmentsCategory.Meeting,
        assignTo: values?.assignTo?.map((itm: any) => ({
          id: itm?.value,
          name: itm?.label,
        })),
        platform: {
          id: values?.platform?.value,
          name: values?.platform?.label,
        },
        reminder: {
          id: values?.reminder?.value,
          name: values?.reminder?.label,
        },
        attachments: values?.attachments
          ? await Promise.all(
            values?.attachments?.fileList?.map(
              async (itm: any, index: number) => {
                let currentFileBase64 = await getBase64(
                  itm?.originFileObj as RcFile
                );
                let fileInfo = {
                  fileName: {
                    id: "",
                    name: itm?.name,
                    contentType: itm?.type,
                    length: itm?.size,
                    path: currentFileBase64,
                  },
                  description:values?.note,
                  category: AttachmentsCategory.Meeting,
                };
                return fileInfo;
              }
            )
          )
          : values?.attachments,
      };
      console.log("values values values  = ", formValue);
      let response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.EnquiryCommunication,
        {},
        formValue
      );
      if (response.status) {
        form.resetFields();
        message.success("Meeting Submitted");
        setButtonLoading(true);
        props.onCancel(true, !!formValue?.attachments);
      } else {
        message.error(response.message);
        setButtonLoading(true);
      }
    });
  };
  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handelFile = async (file: any, fileList: any) => {
    fileList?.map(async (itm: any, index: any) => {
      let prevAtt = [...attachmentList];
      let currentFileBase64 = await getBase64(itm as RcFile);

      let fileInfo = {
        id: "",
        name: itm?.name,
        contentType: itm?.type,
        length: itm?.size,
        path: currentFileBase64,
      };

      prevAtt[index] = { ...prevAtt[index], fileName: fileInfo };

      setAttachmentList(prevAtt);
    });
  };
  const customRender = (
    originNode: React.ReactElement<any>,
    file: UploadFile,
    fileList: Array<UploadFile>
  ) => {
    return (
      <>
        {/* <div className="ant-upload-list-item">
                    {
                      file.name
                    }
  
                  </div> */}
        {originNode}
        {/* <h1>This is heading</h1> */}
      </>
    );
  };
  const uploadProps: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: {
      previewIcon: true,
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) { },
    beforeUpload(file, FileList) {
      handelFile(file, FileList);
      return false;
    },
    itemRender: customRender,
  };
  return (
    <>
      <Modal
        style={{ top: 20 }}
        title={`Add Meeting [${props?.apiData?.fullName}]`}
        open={props.open}
        maskClosable={false}
        onCancel={() => {
          props.onCancel(false, false);
        }}
        width={1080}
        footer={
          <Space
            style={{
              display: "flex",
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <Button
              onClick={() => {
                props.onCancel(false, false);
              }}
            >
              Cancel
            </Button>
            <Button onClick={onSubmit} type="primary" loading={buttonLoading}>
              {props?.id === "" ? "Save" : "Update"}
            </Button>
          </Space>
        }
      >
        {/* <Divider className="marginDivider" /> */}
        <div
          style={{
            maxHeight: "80vh",
            overflowY: "auto",
            width: "100%",
            maxWidth: "100%",
            padding: "7px",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            requiredMark={true}
            initialValues={{ name: props?.apiData?.fullName }}
          >
            <Row gutter={[10, 16]}>
              <Col span={6}>
                <Form.Item
                  name="title"
                  label="Title"
                  className="marginbtm"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please Add a Title Please",
                //   },
                // ]}
                >
                  <Input style={{ width: "100%" }} placeholder="Title" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="assignTo"
                  label="Assign To"
                  className="marginbtm"
                >
                  <Select
                    placeholder="Add Assignee"
                    optionFilterProp="children"
                    labelInValue
                    allowClear
                    popupMatchSelectWidth={true}
                    showSearch
                    mode="multiple"
                    maxTagCount={"responsive"}
                    filterOption={(input: any, option: any) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={assignees}
                    dropdownRender={(menu) => (
                      <>
                        <Card
                          size="small"
                          style={{ marginBottom: 10, padding: 2 }}
                        >
                          <Checkbox
                            style={{ width: "100%" }}
                            title="Assign To me"
                            onChange={(e) => {
                              console.log("eeeeeeeeeeeeee", e.target.checked);
                              if (e?.target?.checked) {
                                form.setFieldValue(
                                  "assignTo",
                                  assignees?.filter(
                                    (x: any) => x?.value === user?.id
                                  )
                                );
                              } else {
                                form.setFieldValue(
                                  "assignTo",
                                  form
                                    .getFieldValue("assignTo")
                                    ?.filter((x: any) => x?.value !== user?.id)
                                );
                              }
                            }}
                          >
                            Assign To me
                          </Checkbox>
                        </Card>
                        {menu}
                      </>
                    )}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="meetingType"
                  label="Meeting Type"
                  className="marginbtm"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Meeting Type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose Type"
                    options={meetingTypeOptions}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="platform"
                  label="Platform"
                  className="marginbtm"
                  rules={[
                    {
                      required: true,
                      message: "Please Select PlateForm Type",
                    },
                  ]}
                >
                  <Select
                    placeholder="Choose Platform"
                    optionFilterProp="children"
                    labelInValue
                    allowClear
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={platFormData}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 16]}>
              <Col span={6}>
                <Form.Item
                  name="reminder"
                  label="Reminder"
                  className="marginbtm"
                >
                  <Select
                    placeholder="Set Reminder"
                    optionFilterProp="children"
                    labelInValue
                    allowClear
                    popupMatchSelectWidth={true}
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label?.toString() ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={reminderData}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="startDate"
                  label="Start Date"
                  className="marginbtm"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    // defaultValue={dayjs()}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  label={"Start At"}
                  name="startTime"
                  className="marginbtm"
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format={"h:mm A"}
                    //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                    placeholder="Start Time"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={"End At"}
                  name="endTime"
                  className="marginbtm"
                >
                  <TimePicker
                    style={{ width: "100%" }}
                    format={"h:mm A"}
                    //defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                    placeholder="End Time"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[10, 16]}>
              <Col span={6}>
                <Form.Item
                  name="outcomeType"
                  label="OutCome"
                  className="marginbtm"
                >
                  <Select
                    placeholder="Choose Outcome"
                    options={outcomeTypeOptions}
                    onSelect={(e: any) => {
                      if (e === OutcomeType.DNC) {
                        setDncModelOpen(true);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="meetingAddress"
              label="Meeting Address"
              className="marginbtm"
              rules={[
                {
                  required: true,
                  message: "Please Add a Meeting Address",
                },
              ]}
            >
              <Input.TextArea
                showCount
                rows={3}
                placeholder="Address of the location to visit"
                maxLength={100}
                onChange={(e: any) => {
                  setTextValue(e.target.value);
                  //console.log(textValue, "textValue");
                }}
              />
              {/* <Input placeholder="Address of the location to visit" /> */}
            </Form.Item>

            <Form.Item name="note" label="Note" className="marginbtm">
              <Input.TextArea
                showCount
                rows={3}
                placeholder="Add Note Here....."
                maxLength={100}
                onChange={(e: any) => {
                  setTextValue(e.target.value);
                  //console.log(textValue, "textValue");
                }}
              />
            </Form.Item>
            <Form.Item
              name={"attachments"}
              label="Add Attachment(s)"
            // rules={[
            //   {
            //     required: true,
            //     message: "this field is required",
            //   },
            // ]}
            >
              <Dragger
                {...uploadProps}
              // onPreview={async (file: UploadFile) => {
              //     console.log("testtest = ", await getBase64(
              //       file.originFileObj as any
              //     ))
              // }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Dragger>
            </Form.Item>
            {/* <Form.Item
              name="followUpTask"
              valuePropName="checked"
              className="marginbtm"
            >
              <Checkbox
                onChange={(e) => {
                  setCreateTask(e.target.checked);
                }}
              >
                <span className="themeColor fw-500">Create follow up task</span>
              </Checkbox>
            </Form.Item> */}
            {createTask && (
              <>
                <Form.Item
                  name="date"
                  label="Choose Date"
                  className="marginbtm"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Date",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    allowClear={false}
                    format={"DD/MM/YYYY"}
                    // defaultValue={dayjs()}
                    disabledDate={(current) =>
                      current && current > dayjs().endOf("day")
                    }
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
      </Modal>
      {dncModelOpen && (
        <DNCModel
          open={dncModelOpen}
          text={"Are you sure you want to add this number in DNC ?"}
          onOk={() => setDncModelOpen(false)}
          onCancel={() => setDncModelOpen(false)}
        />
      )}
    </>
  );
};

export default MeetingModel;

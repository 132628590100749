import "../../Common/MainLayout.css";
import {
  ArrowRightOutlined,
  BellOutlined,
  BookOutlined,
  BranchesOutlined,
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  ContainerOutlined,
  ControlOutlined,
  CrownOutlined,
  DollarOutlined,
  FileDoneOutlined,
  GlobalOutlined,
  HourglassOutlined,
  IdcardOutlined,
  InfoOutlined,
  MailOutlined,
  ProfileOutlined,
  RadarChartOutlined,
  ScheduleOutlined,
  SolutionOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Button, Collapse, List, Row, Space } from "antd";
import "../../Custom.css";
import { useNavigate } from "react-router";
import type { CollapseProps } from "antd";

const Setting = () => {
  const navigate = useNavigate();
  const data = [
  {
      icon: <CrownOutlined style={{ fontSize: "19px" }} />,
      name: "Curriculum",
      onClick: () => {
        navigate("Curriculum");
      },
    },
    {
      icon: <ContainerOutlined style={{ fontSize: "19px" }} />,
      name: "Stream",
      onClick: () => {
        navigate("Stream");
      },
    },
    {
      icon: <GlobalOutlined style={{ fontSize: "19px" }} />,
      name: "Class",
      onClick: () => {
        navigate("Class");
      },
    },
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Subject Types",
      onClick: () => {
        navigate("subjectTypes");
      },
    },
    {
      icon: <DollarOutlined style={{ fontSize: "19px" }} />,
      name: "Payment",
      onClick: () => {
        navigate("Payment");
      },
    },
    {
      icon: <UserAddOutlined style={{ fontSize: "19px" }} />,
      name: "Credentials",
      onClick: () => {
        navigate("Credentials");
      },
    },
    {
      icon: <FileDoneOutlined style={{ fontSize: "19px" }} />,
      name: "Document",
      onClick: () => {
        navigate("Document");
      },
    },
    {
      icon: <FileDoneOutlined style={{ fontSize: "19px" }} />,
      name: "Mandatory Document Type",
      onClick: () => {
        navigate("Mandatory-document-type");
      },
    },
    {
      icon: <CalendarOutlined style={{ fontSize: "19px" }} />,
      name: "Holiday",
      onClick: () => {
        navigate("Holiday");
      },
    },
    {
      icon: <ControlOutlined style={{ fontSize: "19px" }} />,
      name: "Status",
      onClick: () => {
        navigate("Status");
      },
    },
    {
      icon: <HourglassOutlined style={{ fontSize: "19px" }} />,
      name: "Medium",
      onClick: () => {
        navigate("Medium");
      },
    },
    {
      icon: <UsergroupAddOutlined style={{ fontSize: "19px" }} />,
      name: "Role",
      onClick: () => {
        navigate("Role");
      },
    },
    {
      icon: <BranchesOutlined style={{ fontSize: "19px" }} />,
      name: "FeesType",
      onClick: () => {
        navigate("FeesType");
      },
    },
    {
      icon: <InfoOutlined style={{ fontSize: "19px" }} />,
      name: "Source",
      onClick: () => {
        navigate("Source");
      },
    },
    {
      icon: <SolutionOutlined style={{ fontSize: "19px" }} />,
      name: "Users",
      onClick: () => {
        navigate("User");
      },
    },
    {
      icon: <SolutionOutlined style={{ fontSize: "19px" }} />,
      name: "Reason",
      onClick: () => {
        navigate("Reason");
      },
    },
    {
      icon: <ProfileOutlined style={{ fontSize: "19px" }} />,
      name: "Process Steps For Website",
      onClick: () => {
        navigate("processStepsForWebsite");
      },
    },
    {
      icon: <ProfileOutlined style={{ fontSize: "19px" }} />,
      name: "Process Steps For Application",
      onClick: () => {
        navigate("processStepsForApplication");
      },
    },
    {
      icon: <VideoCameraOutlined style={{ fontSize: "19px" }} />,
      name: "PlatForm",
      onClick: () => {
        navigate("PlatForm");
      },
    },
    {
      icon: <ClockCircleOutlined style={{ fontSize: "19px" }} />,
      name: "Reminder",
      onClick: () => {
        navigate("Reminder");
      },
    },
    {
      icon: <CheckOutlined style={{ fontSize: "19px" }} />,
      name: "Task",
      onClick: () => {
        navigate("Task");
      },
    },
    {
      icon: <MailOutlined style={{ fontSize: "19px" }} />,
      name: "Mail Template",
      onClick: () => {
        navigate("MailTemplate");
      },
    },
    {
      icon: <RadarChartOutlined style={{ fontSize: "19px" }} />,
      name: "Room",
      onClick: () => {
        navigate("Room");
      },
    },
    {
      icon: <CalendarOutlined style={{ fontSize: "19px" }} />,
      name: "Leave Types",
      onClick: () => {
        navigate("leaveTypes");
      },
    },
    {
      icon: <BellOutlined style={{ fontSize: "19px" }} />,
      name: "Notice",
      onClick: () => {
        navigate("notice");
      },
    },
    {
      icon: <RadarChartOutlined style={{ fontSize: "19px" }} />,
      name: "Privacy Policy",
      onClick: () => {
        navigate("PrivacyPolicy");
      },
    },
    // {
    //   icon: <RadarChartOutlined style={{ fontSize: "19px" }} />,
    //   name: "App Versions",
    //   onClick: () => {
    //     navigate("AppVersionsList");
    //   },
    // },
  ];
  
  const studyPlan = [
    {
      icon: <BookOutlined style={{ fontSize: "19px" }} />,
      name: "Design Course",
      onClick: () => {
        navigate("designCourse");
      },
    },
    // {
    //   icon: <IdcardOutlined style={{ fontSize: "19px" }} />,
    //   name: "Batch",
    //   onClick: () => {
    //     navigate("Batch");
    //   },
    // },
    // {
    //   icon:<BookOutlined style={{fontSize:"19px"}}/>,
    //   name:"Sections",
    //   onClick:()=>{
    //     navigate("Sections")
    //   },
    // },
    {
      icon:<BookOutlined style={{fontSize:"19px"}}/>,
      name:"Faculty map with department",
      onClick:()=>{
        navigate("FacultyMapWithDept")
      },
    },
  ];
  // const eCard = [
  //   {
  //     icon: <AuditOutlined style={{ fontSize: "19px" }} />,
  //     name: "E-cards",
  //     onClick: () => {
  //       navigate("eCard");
  //     },
  //   }
  // ];

    const attendanceList = [
    {
      icon: <ScheduleOutlined style={{ fontSize: "19px" }} />,
      name: "Biometric Devices",
      onClick: () => {
        navigate("biometric-device");
      },
    }
  ];
  
  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <span style={{ fontWeight: 600 }}>Masters</span>,
      children: (
        <List
          style={{ width: "100%" }}
          dataSource={data}
          size="small"
          className="customStyleList"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
                <ArrowRightOutlined onClick={item?.onClick} />
              </span>
            </List.Item>
          )}
        />
      ),
    },
    {
      key: "2",
      label: <span style={{ fontWeight: 600 }}>Study Plan</span>,
      // children: [{...studyPlan}],
      children: (
        <List
          style={{ width: "100%" }}
          dataSource={studyPlan}
          size="small"
          className="customStyleList"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
                <ArrowRightOutlined onClick={item?.onClick} />
              </span>
            </List.Item>
          )}
        ></List>
      ),
    },
    // {
    //   key: "3",
    //   label: <span style={{ fontWeight: 600 }}>E-card</span>,
    //   // children: [{...studyPlan}],
    //   children: (
    //     <List
    //       style={{ width: "100%" }}
    //       dataSource={eCard}
    //       size="small"
    //       className="customStyleList"
    //       renderItem={(item) => (
    //         <List.Item onClick={item?.onClick}>
    //           <Space direction="horizontal">
    //             <Button type="link" icon={item?.icon}>
    //               {item?.name}
    //             </Button>
    //           </Space>
    //           <span>
    //             <ArrowRightOutlined onClick={item?.onClick} />
    //           </span>
    //         </List.Item>
    //       )}
    //     ></List>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: <span style={{ fontWeight: 600 }}>Attendance</span>,
    //   // children: [{...studyPlan}],
    //   children: (
    //     <List
    //       style={{ width: "100%" }}
    //       dataSource={attendanceList}
    //       size="small"
    //       className="customStyleList"
    //       renderItem={(item) => (
    //         <List.Item onClick={item?.onClick}>
    //           <Space direction="horizontal">
    //             <Button type="link" icon={item?.icon}>
    //               {item?.name}
    //             </Button>
    //           </Space>
    //           <span>
    //             <ArrowRightOutlined onClick={item?.onClick} />
    //           </span>
    //         </List.Item>
    //       )}
    //     ></List>
    //   ),
    // },
  ];
  return (
    <>
      {/* <p style={{ textAlign: "end", margin: "2px 2px" }}>Fees</p> */}
      <Row justify="end" align="middle" style={{ marginBottom: "6px" }}>
        {/* <Breadcrumb
          style={{ color: "#172B4D" }}
          items={[
            {
              title: "Settings",
            },
          ]}
        ></Breadcrumb> */}
      </Row>

      <Row className="roundedCornerSmall bg-white" style={{ padding: "16px" }}>
        {/* <List
          header={
            <span style={{ fontWeight: 550, backgroundColor: "#e0e4e938" }}>
              Study Plan
            </span>
          }
          style={{ width: "100%" }}
          bordered
          dataSource={studyPlan}
          size="small"
          renderItem={(item) => (
            <List.Item onClick={item?.onClick}>
              <Space direction="horizontal">
                <Button type="link" icon={item?.icon}>
                  {item?.name}
                </Button>
              </Space>
              <span>
                <ArrowRightOutlined onClick={item?.onClick} />
              </span>
            </List.Item>
          )}
        ></List> */}
        <Collapse
          items={items}
          style={{ width: "100%" }}
          className="customstyle"
          defaultActiveKey={["1"]}
        />
      </Row>
    </>
  );
};

export default Setting;
